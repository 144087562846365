<template>
  <div>
    <!-- 引入公共头部 -->
    <div>
      <ClassHead></ClassHead>
      <router-view></router-view>
    </div>
    <!-- 轮播部分 -->
    <div class="dalunbo">
      <div class="liebiao">
        <div class="nue">
          <router-link to="/Alliance/Home">
            <div class="wne">
              <span>首页</span>
            </div>
            </router-link>
          <router-link to="/Alliance/supermarket">
            <div class="wne">
              <span>课程超市</span>
            </div>
          </router-link>

          <router-link to="/Alliance/school">
            <div class="wne">
              <span>学校</span>
            </div>
          </router-link>
          <router-link to="/Alliance/SchoolCloud">
            <div class="wne">
              <span>学校云</span>
            </div>
          </router-link>
          <div class="wne">
            <span>公共服务平台</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 学校展示 -->
    <div class="ming">
      <div class="vouchers">
        <div class="glorious">
          <!-- 学校图片 -->
          <img src="@/assets/zn.png" alt />
        </div>
        <div class="glorious">
          <!-- 学校图片 -->
          <img src="@/assets/zn.png" alt />
        </div>
        <div class="glorious">
          <!-- 学校图片 -->
          <img src="@/assets/zn.png" alt />
        </div>
        <div class="glorious">
          <!-- 学校图片 -->
          <img src="@/assets/zn.png" alt />
        </div>
        <div class="glorious">
          <!-- 学校图片 -->
          <img src="@/assets/zn.png" alt />
        </div>
        <div class="glorious">
          <!-- 学校图片 -->
          <img src="@/assets/zn.png" alt />
        </div>
      </div>
    </div>
    <!-- 引入公共底部 -->
    <div style="background-color: rgb(239, 239, 239)">
      <Commonbottom></Commonbottom>
      <router-view></router-view>
    </div>
  </div>
</template>
<style  scoped>
.liebiao {
  background-color: skyblue;
  width: 100%;
}
.nue {
  margin: 0 auto;
  padding: 0.8rem;
}
.wne {
  display: inline-block;
  margin-right: 8rem;
  font-size: 0.9rem;
  color: #fff;
  font-size: 1rem;
  text-align: left;
  cursor: pointer;
}
.ming {
  width: 100%;
  background-color: #efefef;
  padding-bottom: 1.4rem;
}
.vouchers {
  width: 63%;
  display: inline-block;
  margin-bottom: 0.5rem;
  text-align: left;
  margin: 0 auto;
}
.glorious {
  width: 18%;
  display: inline-block;
  margin-top: 1.4rem;
  border: 0.1rem solid;
  text-align: left;
  margin-left: 1.4%;
}
.glorious img {
  width: 100%;
}
.gloriou {
  width: 28%;
  display: inline-block;
  margin-left: 1rem;
  margin-top: 1rem;
  border: 0.1rem solid;
  text-align: left;
}
.gloriou img {
  width: 100%;
}
@media screen and (max-width: 767px) and (min-width: 300px) {
  .vouchers {
    width: 100% !important;
  }
  .glorious {
    width: 30.5% !important;
  }
  .dalunbo {
    margin-top: 0.5rem !important;
  }
  .wne {
    margin-right: 0.9rem !important;
  }
}
</style>

<script>
import ClassHead from "@/components/ClassHead";
import Commonbottom from "@/components/Commonbottom";
export default {
  components: { ClassHead, Commonbottom },
  computed: {},
  data() {
    return {
      value: 5,
    };
  },
  methods: {},
};
</script>