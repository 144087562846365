<template>
  <div>
    <div class="topbiao">
      <div class="tupian">
        <img
          src="http://hnjpkc.hnscen.cn/portal/themes/%e6%b2%b3%e5%8d%97%e7%9c%81%e7%b2%be%e5%93%81%e8%af%be%e7%a8%8b%e7%bd%91/img/logo3.png"
          alt
        />
      </div>
      <div class="sou">
        <el-input v-model="input" placeholder="请输入内容"></el-input>
        <el-button type="primary" class="an">课程搜索</el-button>
      </div>
    </div>
    <!--导航-->
    <div>
      <div id="nav">
        <ul>
          <li>
            <router-link class="news" to="/Alliance/Index">首页</router-link>
          </li>
          <li>
            <router-link class="news" to="/Alliance/CourseSuper">课程超市</router-link>
          </li>
          <li>
            <router-link class="news" to="/Alliance/RankingList">排行榜</router-link>
          </li>
          <li>
            <router-link class="news" to>关于联盟</router-link>
          </li>
          <li>
            <router-link class="news" to="/">公共服务平台</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="kechengjieshao">
      <div class="pic">
        <img src="@/assets/111.jpg" alt />
      </div>
      <div class="jieshao">
        <h1 class="mingcheng">创意设计与设计思维</h1>
        <h1 class="fuze">负责人： 崔勇</h1>
        <div class="fuze">课程层次：专科起点本科</div>
        <div class="fuze">课程类型：理论课(含实践)</div>
        <div class="fuze">课程属性： 专业基础课</div>
        <div class="fuze">所属学科门类：</div>
        <div class="anniu">
          <el-button type="primary" @click="xinxi">课程信息</el-button>
          <el-button type="warning" @click="dagang">课程大纲</el-button>
          <el-button type="success">收藏课程</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.pic {
  width: 25%;
  display: inline-block;
}
.topbiao {
  width: 60%;
  margin: 0 auto;
}
.tupian img {
  height: 0.7rem;
}
.tupian {
  margin-top: 0.1rem;
  display: inline-block;
  float: left;
}
.sou {
  display: inline-block;
  margin-bottom: 0.1rem;
}
.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 60%;
}
.an {
  margin-left: 0.05rem;
}
.welcome {
  width: 90%;
  margin: 0 auto;
  font-size: 0.1rem;
  padding: 0.1rem;
  text-align: left;
}
.welcome .traffic {
  float: right;
}
.header {
  background-color: #fff;
}
.headerCon {
  width: 90%;
  margin: 0 auto;
  text-align: left;
}
.headerCon img {
  width: 60%;
}
#nav {
  margin: 0 auto;
  padding: 0;
  background-color: #1697eb;
}
#nav ul,
#nav li {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
#nav ul {
  width: 65%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}
#nav li {
  color: #fff;
  font-size: 0.15rem;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  flex: auto; /*这是关键*/
  cursor: pointer;
}
#nav li a {
  color: #fff;
  text-decoration: none;
}
.kechengjieshao {
  background: #fff;
  width: 56%;
  margin: 0 auto;
  margin-top: 0.2rem;
}
.pic img {
  width: 100%;
}
.jieshao {
  float: right;
  width: 70%;
  text-align: left;
  display: inline-block;
  margin-left: 0.3rem;
}
.mingcheng {
  font-size: 0.2rem;
  font-weight: 500;
  margin-bottom: 0.2rem;
}
.fuze {
  font-size: 0.12rem;
  font-weight: 500;
  margin-bottom: 0.05rem;
}
.anniu {
  display: flex;
  margin-top: 0.2rem;
}

.el-button + .el-button {
  margin-left: 0.4rem;
}
@media only screen and (max-width: 1100px) {
  .pic {
    width: 100% !important;
  }
  .jieshao {
    width: 100% !important;
    float: none !important;
  }
  .kechengjieshao {
    width: 85% !important;
  }
  .el-button + .el-button {
    margin-left: 0 !important;
  }
  .headerCon img {
    width: 100% !important;
  }
  #nav li {
    font-size: 0.1rem !important;
  }
  .el-input {
    width: 52% !important;
  }
  .tupian img {
    height: 0.55rem !important;
  }
}
</style>
<script>
export default {
  data() {
    return {
      input: ""
    };
  },
  methods: {
    xinxi() {
      this.$router.push({ path: "/Alliance/DetailsPage" });
    },
    dagang() {
      this.$router.push({ path: "/Alliance/CourseInformation" });
    }
  }
};
</script>