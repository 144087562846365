<template>
  <div class="big">
    <div class="top">
      <div style="display: inline-block;margin-right: 0.1rem;">
        <img
          src="../../src/assets/user.jpg"
          width="45px"
          height="45px"
          alt=" 河南开放大学"
          class="avatar-img"
        />
      </div>
      <div style="display: inline-block;font-size:0.1rem;">
        <el-dropdown style="margin-right: 0.1rem;">
          <div class="el-dropdown-link">
            河南开放大学
            <i class="el-icon-arrow-down el-icon--right"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-user">返回登录</el-dropdown-item>
            <el-dropdown-item icon="el-icon-switch-button">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="helper">
          <span>帮助文档</span>
        </div>
        <div class="helper">
          <span>返回登录页</span>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="main-left">
        <el-col>
          <h5
            class="header"
            style="background-color: rgb(84, 92, 100);font-size: 0.15rem;color: rgb(255, 255, 255);padding: 0.3rem;"
          >
            <i class="el-icon-location"></i> 新闻管理
          </h5>
          <el-menu
            default-active="/admin"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose1"
            background-color="#545c64"
            text-color="#fff"
            active-text-color="#ffd04b"
            router
          >
            <el-menu-item index="/news">
              <i class="el-icon-menu"></i>
              <span slot="title">板块管理</span>
            </el-menu-item>
            <el-menu-item index="/Articlemanagement">
              <i class="el-icon-setting"></i>
              <span slot="title">文章管理</span>
            </el-menu-item>
            <el-menu-item index="/admin">
              <i class="el-icon-user-solid"></i>
              <span slot="title">管理员</span>
            </el-menu-item>
          </el-menu>
        </el-col>
      </div>
      <div>
        <div class="main-right">
          <div class="dp">
            <i class="el-icon-set-up"></i>
            <span>管理员</span>
          </div>
        </div>
        <div class="sou">
          <div class="nei">
            <h2 style="display: inline-block;">姓名：</h2>
            <el-input v-model="input" placeholder="请输入内容"></el-input>
            <el-button type="primary" icon="el-icon-search">搜索</el-button>
          </div>
        </div>
        <div class="neirong">
          <template>
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column fixed prop="date" label="序号" width="100"></el-table-column>
              <el-table-column prop="name" label="名称" width="120"></el-table-column>
              <el-table-column prop="province" label="手机号" width="120"></el-table-column>
              <el-table-column prop="city" label="邮箱" width="120"></el-table-column>
              <el-table-column prop="address" label="邮箱" width="400"></el-table-column>
              <el-table-column prop="zip" label="性别" width="120"></el-table-column>
              <el-table-column prop="zip" label="单位" width="120"></el-table-column>
              <el-table-column prop="zip" label="状态" width="120"></el-table-column>
              <el-table-column fixed="right" label="操作" width="150">
                <template slot-scope="scope">
                  <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
                  <router-link to="/plate">
                    <el-button type="text" size="small">禁用</el-button>
                  </router-link>
                </template>
              </el-table-column>
            </el-table>
          </template>
          <el-dialog title="查看" :visible.sync="dialogFormVisible3">
            <el-form ref="form" :model="form">
              <el-form-item label="名称">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="手机号">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="邮箱">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="单位">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="状态">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="账号">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="性别">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="角色">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmit">保存</el-button>
                <el-button @click="dialogFormVisible3 = false">取消</el-button>
              </el-form-item>
            </el-form>
          </el-dialog>
        </div>
        <div class="fenye">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage3"
            :page-size="100"
            layout="prev, pager, next, jumper"
            :total="1000"
            small
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<style  scoped>
.big {
  font-size: 0.1rem;
}
.top {
  background-color: #153c75;
  width: calc(100% - 0.4rem);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.2rem;
}
.helper {
  font-size: 0.15rem;
  display: inline-block;
  color: #fff;
  margin-right: 0.1rem;
}
.main {
  width: 70%;
  margin: 0 auto;
  margin-top: 0.2rem;
}
[class*="el-col-"] {
  float: unset;
  box-sizing: border-box;
}
@media only screen and (max-width: 540px) {
  .main {
    width: 90%;
    margin: 0 auto;
    margin-top: 0.2rem;
  }
  .main-right {
    width: 97% !important;
  }
  .el-menu-item {
    font-size: 0.3rem !important;
  }
  .header {
    font-size: 0.3rem !important;
    padding: 0.4rem;
  }
  .dp[data-v-1aa8c6e6] {
    display: inline-block;
    font-size: 0.3rem;
    float: left;
    margin-top: 0.09rem;
  }
  .sou[data-v-1aa8c6e6] {
    width: 100%;
    /* display: inline-block; */
  }
  .neirong[data-v-1aa8c6e6] {
    width: 100% !important;
    display: inline-block !important;
  }
  .fenye {
    margin-left: 0rem !important;
  }
}
.main-left {
  width: 25%;
  display: inline-block;
  float: left;
}
.main-right {
  width: 70%;
  /* background-color: skyblue; */
  /* padding: 1rem; */
  display: inline-block;
  color: #444;
  background-color: #f5f5f5;
  border-color: #ddd;
  padding: 0.1rem;
  border: 0.01rem solid #ddd;
  border-bottom: 1px solid transparent;
  margin-bottom: 0.1rem;
}
.dp {
  display: inline-block;
  font-size: 0.15rem;
  float: left;
  margin-top: 0.04rem;
}
.ico {
  float: right;
}
.sou {
  width: 73%;
  display: inline-block;
}
.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 45%;
  margin-right: 0.1rem;
}
.nei {
  float: left;
}
.neirong {
  width: 70%;
  display: inline-block;
}
.fenye {
  margin-left: 1.9rem;
}
</style>

<script>
export default {
  data() {
    return {
      input: "",
      dialogVisible: false,
      dialogVisible1: false,
      dialogFormVisible3: false,
      currentPage4: 4,
      currentPage3: 2,
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: ""
      },
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1518 弄",
          zip: 200333
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1517 弄",
          zip: 200333
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1519 弄",
          zip: 200333
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1516 弄",
          zip: 200333
        }
      ],
      handleSizeChange(val) {
      },
      handleCurrentChange(val) {
      }
    };
  },
  methods: {
    handleOpen(key, keyPath) {
    },
    handleClose1(key, keyPath) {
    },
    onSubmit() {
      this.dialogVisible = false;
    },
    handleClick() {
      this.dialogFormVisible3 = true;
    }
  }
};
</script>


