<template>
  <div class="box">
    <!-- 头部导航栏 -->
    <div class="hk">
      <div class="left">
        <div class="lm">
          <span>好课联盟</span>
        </div>
        <div class="sp">
          <el-dropdown>
            <span class="el-dropdown-link">
              学习资讯
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>黄金糕</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="sp">
          <el-dropdown>
            <span class="el-dropdown-link">
              学习工具
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>黄金糕</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="sp">
          <el-dropdown>
            <span class="el-dropdown-link">
              服务中心
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>黄金糕</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="sp">
          <el-dropdown>
            <span class="el-dropdown-link">
              更多
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>黄金糕</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="right">
        <div class="sp1">
          <span class="login" @click="dialogVisible = true">登录/注册</span>
        </div>
        <el-dialog :visible.sync="dialogVisible" width="40%" :before-close="handleClose">
          <div class="abd">
            <div class="left1">
              <div class="zi">
                <span>登录</span>
              </div>
              <div class="geng">
                <div class="userbname">
                  <el-input placeholder="用户名/手机/邮箱" prefix-icon="el-icon-user" v-model="input2"></el-input>
                </div>
                <div class="userbname">
                  <el-input placeholder="请输入密码" prefix-icon="el-icon-unlock" v-model="input2"></el-input>
                </div>
              </div>
              <div class="cvg">
                <div class="zh">
                  <span>账号注册></span>
                </div>
                <div class="ma">
                  <span>忘记密码></span>
                </div>
              </div>
              <div class="loginer">一键登录</div>

              <p style="text-align:center;padding:0.6rem;font-size:0.2rem;color:#dadada">
                <el-checkbox v-model="checked">我已阅读并同意《用户协议》</el-checkbox>
              </p>
            </div>
            <div class="right1">
              <div class="zi1">
                <span>更多方式</span>
              </div>
              <div style="text-align:left;margin-top:1rem">
                <img src="@/assets/20200601112643.png" alt />
                <img src="@/assets/20200601112639.png" alt style="margin-left:1rem" />
                <img src="@/assets/20200601112633.png" alt style="margin-left:1rem" />
              </div>
            </div>
          </div>
        </el-dialog>
        <div class="sp1">
          <el-dropdown>
            <span class="el-dropdown-link">
              我的课程
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>黄金糕</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="sp1">
          <el-dropdown>
            <span class="el-dropdown-link">
              消息
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>黄金糕</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="liebiao">
      <div class="nue">
        <div class="wne1">
          <span>首页</span>
        </div>
        <router-link to="/Alliance/supermarket">
          <div class="wne">
            <span>课程超市</span>
          </div>
        </router-link>

        <router-link to="/Alliance/school">
          <div class="wne">
            <span>学校</span>
          </div>
        </router-link>
        <router-link to="/Alliance/SchoolCloud">
          <div class="wne">
            <span>学校云</span>
          </div>
        </router-link>
        <div class="wne">
          <span>公共服务平台</span>
        </div>
      </div>
    </div>
  </div>
</template>
<style  scoped>
.hk {
  width: 70%;
  margin: 0 auto;
  padding: 0.8rem;
}
.liebiao {
  background-color: skyblue;
  width: 100%;
  text-align: left;
}
.nue {
  margin: 0 auto;
  padding: 0.8rem;
}
.aa {
  display: inline-block;
  float: right;
}
.el-dropdown-link {
  cursor: pointer;
  color: #333;
}
.wne {
  display: inline-block;
  margin-left: 3.5rem;
  font-size: 0.9rem;
  color: #fff;
  font-size: 1rem;
  text-align: left;
  cursor: pointer;
}
.wne1 {
  display: inline-block;
  margin-left: 22.5rem;
  font-size: 0.9rem;
  color: #fff;
  font-size: 1rem;
  text-align: left;
  cursor: pointer;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.left {
  display: inline-block;
  width: 70%;
  text-align: left;
}
.loginer {
  padding: 0.8rem;
  background-color: #ededed;
  margin-top: 1.3rem;
  border-radius: 30px;
  font-size: 0.95rem;
  text-align: center;
}
.left1 {
  display: inline-block;
  text-align: left;
  width: 39%;
  margin-top: 1rem;
}
.right {
  display: inline-block;
}
.sp {
  display: inline-block;
  margin-right: 1rem;
  font-size: 0.9rem;
}
.sp1 {
  display: inline-block;
  margin-right: 1rem;
  font-size: 0.9rem;
}
.lm {
  display: inline-block;
  margin-right: 1rem;
  font-size: 0.9rem;
}
.quan {
  width: 55%;
  margin: 0 auto;
  padding: 1.5rem;
}
.tup {
  float: left;
}
.sou {
  width: 35%;
  display: inline-block;
}
.liu {
  display: inline-block;
  margin-right: 1rem;
}
.shou {
  display: inline-block;
}
.login {
  cursor: pointer;
}
.abd {
  padding: 3.6rem;
  background-image: url("../assets/bj.png");
  background-repeat: no-repeat, repeat-x, repeat-y;
}
.zi {
  font-size: 1.6rem;
  color: #00a1ff;
  text-align: center;
  margin-bottom: 2rem;
}
.userbname {
  margin-bottom: 1.5rem;
}
.zi1 {
  font-size: 1rem;
  font-weight: 800;
  color: #00a1ff;
  text-align: left;
}
.right1 {
  width: 44%;
  float: right;
  margin-top: 1rem;
}
.zh {
  display: inline-block;
}
.ma {
  display: inline-block;
  float: right;
}

@media screen and (min-width: 300px) and (max-width: 767px) {
  .hk {
    margin: 0 !important;
    padding: 0 !important;
  }
  .wne {
    margin-right: 0.5rem !important;
  }
  .wne1 {
    margin-left: 0rem !important;
  }
  .wne {
    margin-left: 0.5rem !important;
  }
  .liebiao {
    text-align: center !important;
  }
  .lm {
    display: inline-block !important;
    margin-right: 0rem !important;
    margin-left: 0rem !important;
    font-size: 0.9rem !important;
  }
  .sp {
    font-size: 0.9rem !important;
    margin-right: 0rem !important;
  }
  .right {
    float: none !important;
    width: 100% !important;
  }
  .left {
    width: 100%;
    text-align: center;
  }
  .el-icon--right {
    margin-left: 0px !important;
  }
  .hk {
    width: 100% !important;
    margin: 0 auto !important;
    /* padding: 0.5rem; */
  }
  .sp1 {
    font-size: 0.9rem !important;
    margin-right: 0.2rem !important;
    display: inline-block !important;
  }
  .quan {
    width: 100% !important;
    margin: 0 auto !important;
    padding: 0rem !important;
  }
  .tup {
    float: none !important;
  }
  .sou {
    margin-right: 0rem !important;
    width: 100%;
    margin-bottom: 0.3rem !important;
  }
  .aa {
    float: none !important;
    margin-bottom: 0.2rem !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .hk {
    width: 100% !important;
    padding: 0rem !important;
  }
  .wne {
    margin-right: 3rem !important;
  }
  .left {
    display: inline-block;
    width: 67% !important;
    text-align: none !important;
  }
  .box {
    width: 100% !important;
  }
  .quan {
    width: 100% !important;
    padding: 0rem !important;
    margin-top: 0.2rem !important;
    margin-bottom: 0.5rem !important;
  }
  .sou {
    width: 43% !important;
    display: inline-block !important;
    margin-right: 1rem !important;
  }
  .wne {
    margin-right: 3rem !important;
  }
  .wne1 {
    margin-left: 2.5rem !important;
  }
  .zhang {
    padding: 0.5rem !important;
  }
}
</style>
<style >
.el-dialog__body {
  padding: 20px 20px 10px;
}
.el-dialog__header {
  padding: 20px 20px 10px;
}
</style>
<script>
export default {
  data() {
    return {
      input: "",
      dialogVisible: false,
      input2: "",
      checked: true
    };
  },
  methods: {
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    }
  }
};
</script> 