<template>
  <div>
    <!--引入头部-->
    <div>
      <Header></Header>
      <router-view></router-view>
    </div>
    <!--内容-->
    <div class="contrain">
      <div class="topwrapper">
        <div class="twright">
          <el-carousel :interval="5000" arrow="always">
            <el-carousel-item v-for="item in 4" :key="item">
              <h3>{{ item }}</h3>
            </el-carousel-item>
          </el-carousel>
        </div>
        <!-- 项目介绍 -->
        <div class="twleft">
          <div class="btn">项目介绍</div>
          <h1>构建灵活开放的终身教育体系</h1>
          <p>随着《国家中长期教育改革和发展规划纲要（2010-2020年）》的出台，国家倡导“努力形成人人皆学，处处可学，时时能学的学习型社会”，备受关注的“构建灵活开放的终身教育体系”的话题走进了社会各界的视野。面对一个崭新的历史时期，高等学历继续教育如何以实际行动探索科学的发展模式，为构建学习型社会做出贡献，是继续教育工作者刻不容缓的任务。</p>
        </div>
        <!-- 研究动态 -->
        <div class="tcleft">
          <div class="title">
            <h2>
              <span>研究动态</span>
              <router-link to class="more" style="float:right;">More></router-link>
            </h2>
            <ul>
              <li>
                <router-link class="nower" to="/Educational/IntroductionPage">2019年12月职业与继续教育要闻简报</router-link>
              </li>
              <li>
                <router-link class="nower" to>2019年12月职业与继续教育要闻简报</router-link>
              </li>
              <li>
                <router-link class="nower" to>2019年12月职业与继续教育要闻简报</router-link>
              </li>
              <li>
                <router-link class="nower" to>2019年12月职业与继续教育要闻简报</router-link>
              </li>
              <li>
                <router-link class="nower" to>2019年12月职业与继续教育要闻简报</router-link>
              </li>
            </ul>
          </div>
        </div>
        <!-- 项目成果 -->
        <div class="tcright">
          <div class="title">
            <h2>
              <span>项目成果</span>
              <router-link to class="more" style="float:right;">More></router-link>
            </h2>
            <ul>
              <li>
                <router-link class="nower" to>2019年12月职业与继续教育要闻简报</router-link>
              </li>
              <li>
                <router-link class="nower" to>2019年12月职业与继续教育要闻简报</router-link>
              </li>
              <li>
                <router-link class="nower" to>2019年12月职业与继续教育要闻简报</router-link>
              </li>
              <li>
                <router-link class="nower" to>2019年12月职业与继续教育要闻简报</router-link>
              </li>
              <li>
                <router-link class="nower" to>2019年12月职业与继续教育要闻简报</router-link>
              </li>
            </ul>
          </div>
        </div>
        <!-- 项目应用 -->
        <div class="tcright">
          <div class="title">
            <h2>
              <span>项目应用</span>
              <router-link to class="more" style="float:right;">More></router-link>
            </h2>
            <ul>
              <li>
                <router-link class="nower" to>2019年12月职业与继续教育要闻简报</router-link>
              </li>
              <li>
                <router-link class="nower" to>2019年12月职业与继续教育要闻简报</router-link>
              </li>
              <li>
                <router-link class="nower" to>2019年12月职业与继续教育要闻简报</router-link>
              </li>
              <li>
                <router-link class="nower" to>2019年12月职业与继续教育要闻简报</router-link>
              </li>
              <li>
                <router-link class="nower" to>2019年12月职业与继续教育要闻简报</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div class="ProjectLeader">
            <div class="title">
              <h2>
                <span>项目负责人</span>
                <router-link to class="more" style="float:right;">More></router-link>
              </h2>
              <div class="pic">
                <img src="../../assets/000.jpeg" alt />
                <h1>
                  <router-link to>项目主持人：卢程程</router-link>
                </h1>
              </div>
              <div class="pic">
                <img src="../../assets/qqq.jpeg" alt />
                <h1>
                  <router-link to>项目主持人：匡苏薇</router-link>
                </h1>
              </div>
              <div class="pic">
                <img src="../../assets/999.jpg" alt />
                <h1>
                  <router-link to>项目组成员：侯晨雨</router-link>
                </h1>
              </div>
              <div class="pic">
                <img src="../../assets/222.jpg" alt />
                <h1>
                  <router-link to>项目组成员：王恩典</router-link>
                </h1>
              </div>
              <div class="pic">
                <img src="../../assets/444.jpg" alt />
                <h1>
                  <router-link to>项目组成员：夏开</router-link>
                </h1>
              </div>
              <div class="pic">
                <img src="../../assets/666.jpg" alt />
                <h1>
                  <router-link to>项目组成员：郭佳</router-link>
                </h1>
              </div>
            </div>
          </div>
          <div class="ccright">
            <div class="title">
              <h2>
                <span>项目资料</span>
                <router-link to class="more" style="float:right;">More></router-link>
              </h2>
              <ul>
                <li>
                  <router-link class="nower" to>项目主持人报告视频</router-link>
                </li>
                <li>
                  <router-link class="nower" to>CSSCI收录证明</router-link>
                </li>
                <li>
                  <router-link class="nower" to>教学成果研究报告</router-link>
                </li>
                <li>
                  <router-link class="nower" to>研究成果查新报告</router-link>
                </li>
                <li>
                  <router-link class="nower" to>学习型社会视阈下普通高校继续教育发展模式研究结项...</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="links">
          <ul>
            <li style="color:#edb302">友情链接：</li>
            <li>
              <router-link to>河南工业大学继续教育学院</router-link>
            </li>
            <li>
              <router-link to>河南工业大学</router-link>
            </li>
            <li>
              <router-link to>中国现代远程与继续教育网</router-link>
            </li>
            <li>
              <router-link to>中国终身学习网</router-link>
            </li>
            <li>
              <router-link to>中国成人教育协会</router-link>
            </li>
            <li>
              <router-link to>河南省继续教育公共服务平台</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div>
        <Footer></Footer>
        <router-view></router-view>
      </div>
  </div>
</template>
<style scoped>
.contrain {
  background-color: #efefef;
  display: flex;
}
.contrain .topwrapper {
  width: 90%;
  margin: 0 auto;
  margin-top: 0.2rem;
}
.twright {
  width: 55%;
  display: inline-block;
}
.twleft {
  width: 45%;
  background-color: #fff;
  display: inline-block;
  float: right;
}
.btn {
  width: 10%;
  background-color: #edb302;
  color: #fff;
  font-size: 0.19rem;
  padding: 0.1rem;
  margin-left: 2%;
}
.twleft h1 {
  font-size: 0.18rem;
  font-weight: 500;
  text-align: left;
  padding: 0.15rem;
}
.twleft p {
  font-size: 0.14rem;
  font-weight: 500;
  text-align: left;
  padding: 0.15rem;
  margin-top: 0.1rem;
  line-height: 27px;
}
.tcleft {
  width: 31%;
  background-color: #fff;
  display: inline-block;
}
.newspic-title {
  font-size: 0.17rem;
  text-align: left;
  padding: 0.15rem;
  background-color: #1697eb;
  color: #fff;
  width: 20%;
}
.tcright {
  width: 32%;
  background-color: #fff;
  margin-left: 0.2rem;
  display: inline-block;
}
.indexCenter {
  width: 30%;
  margin-left: 3%;
  display: inline-block;
}
.indexCenter ul {
  list-style: none;
}
.indexCenter ul li .nower {
  padding-left: 0.2rem;
}
.title h2 {
  border-bottom: 1px solid #1697eb;
  color: #505050;
  font-size: 0.1rem;
  line-height: 39px;
  text-align: left;
}
.title ul {
  list-style: none;
  padding: 0.1rem;
}
.title h2 span {
  color: #505050;
  font-size: 0.15rem;
  text-align: left;
  background-color: #1697eb;
  display: inline-block;
  color: #fff;
  padding: 0.08rem;
}
.title .more {
  text-decoration: none;
  font-size: 0.14rem;
  color: #999;
  margin-right: 0.1rem;
  margin-top: 0.1rem;
}
.title ul li {
  font-size: 0.3rem;
  text-align: left;
  line-height: 36px;
}
.title .nower {
  text-decoration: none;
  font-size: 0.14rem;
  color: #909090;
}
.title .dater {
  float: right;
  color: #909090;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
@media only screen and (max-width: 800px) {
  .twright {
    width: 100% !important;
  }
  .twleft {
    width: 100% !important;
    background-color: #fff !important;
  }
  .btn {
    width: 8% !important;
    margin-left: 2rem !important;
  }
  .tcleft {
    width: 100% !important;
    margin-top: 0.2rem !important;
  }
  .tcright {
    width: 100% !important;
    margin-left: 0rem !important;
    margin-top: 0.2rem !important;
  }
  .ProjectLeader {
    width: 100% !important;
  }
  .ccright {
    width: 100% !important;
  }
}
@media only screen and (max-width: 1100px) {
  .btn {
    width: 14% !important;
    margin-left: 2.5rem !important;
  }
  .tcleft {
    width: 100% !important;
  }
  .tcright {
    width: 100% !important;
    margin-left: 0rem !important;
    margin-top: 0.2rem !important;
  }
  .pic{
    width: 27% !important;
  }
  .ProjectLeader{
     width: 100% !important;
  }
  .ccright{
    width: 100% !important;
    float: none !important;
    margin-left: 0 !important;
  }
}
.ProjectLeader {
  width: 65%;
  background-color: #fff;
  margin-top: 0.2rem;
  display: inline-block;
}
.pic {
  width: 14%;
  padding: 0.1rem;
  display: inline-block;
}
.pic img {
  width: 100%;
}
.pic h1 {
  font-weight: 500;
  font-size: 0.15rem;
}
.pic h1 a {
  text-decoration: none;
  color: #2c3e50;
}
.ccright {
  width: 33%;
  background-color: #fff;
  display: inline-block;
  margin-top: 0.2rem;
  float: right;
  margin-left: 0.2rem;
}
.links {
  font-weight: 500;
  font-size: 0.15rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}
.links ul {
  list-style: none;
  display: inline-block;
}
.links ul li {
  float: left;
  margin-left: 0.5rem;
}
.links ul li a {
  color: #2c3e50;
  text-decoration: none;
}
.links ul li a:hover {
  color: #edb302;
}
</style>
<script>
// 引入头部
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  components: {
    Header,
    Footer
  }
};
</script>