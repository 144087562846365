<template>
  <div class="big">
    <continuehead></continuehead>
    <router-view></router-view>
    <div style="background-color: rgb(242, 236, 217);">
      <div class="main">
        <continueleft></continueleft>
        <router-view></router-view>
        <div class="main-right">
          <div style="text-align:left;margin-bottom:3%">
            <div class="dp">
              <i class="el-icon-set-up"></i>
              <span>文章管理</span>
            </div>
            <div style="display: inline-block;float: right;">
              <router-link to="/Addarticle" style="margin-right:0.6rem">
              <el-button type="success" style="padding: 8px 9px;">+添加</el-button>
              </router-link>
              <el-button type="success" style="padding: 8px 9px;">X删除</el-button>
              <el-button type="success" style="padding: 8px 9px;">发布</el-button>
              <el-button type="success" style="padding: 8px 9px;">取消发布</el-button>
              <el-button type="success" style="padding: 8px 9px;">置顶/取消</el-button>
            </div>
          </div>
          <div class="ico">
            <div style="display:inline-block;margin-bottom:0.8rem">
              <span>新闻名称：</span>
              <el-input placeholder="新闻名称" v-model="input3" class="input-with-select"></el-input>
            </div>
            <div class="ss" style="display:inline-block;margin-bottom:0.8rem;margin-left:0.5rem">
              <span>父级板块：</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div class="ss" style="display:inline-block;margin-bottom:0.8rem;margin-left:0.5rem">
              <span>子级板块：</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div style="display:inline-block;margin-bottom:0.8rem">
              <span>是否发布：</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <el-button type="success" style="margin-left:0.8rem">搜索</el-button>
          </div>
          <div style="margin-top:1rem">
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column type="selection" width="40"></el-table-column>
              <el-table-column prop="date" label="序号" width="50"></el-table-column>
              <el-table-column prop="name" label="名称" width="220"></el-table-column>
              <el-table-column prop="province" label="所属板块" width="120"></el-table-column>
              <el-table-column prop="city" label="是否发布" width="120"></el-table-column>
              <el-table-column prop="address" label="审核状态" width="120"></el-table-column>
              <el-table-column prop="zip" label="创建人" width="120"></el-table-column>
              <el-table-column prop="zip" label="创建时间" width="120"></el-table-column>
              <el-table-column prop="zip" label="发布时间" width="120"></el-table-column>
              <el-table-column prop="zip" label="置顶" width="120"></el-table-column>
              <el-table-column fixed="right" label="操作" width="100">
                <template slot-scope="scope">
                  <el-button
                    @click="handleClick(scope.row)"
                    type="text"
                    size="small"
                    style="color:rgb(101, 155, 124)"
                  >编辑</el-button>
                  <el-button type="text" size="small" style="color:rgb(101, 155, 124)" @click="preview()">预览</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div style="margin-top:1rem">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage4"
              :page-sizes="[100, 200, 300, 400]"
              :page-size="100"
              layout="total, sizes, prev, pager, next, jumper"
              :total="400"
            ></el-pagination>
          </div>
          <!-- <div class="">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="dialogVisible = true"
              style="margin-right: 0.1rem;"
            ></el-button>
            <el-dialog title="添加板块" :visible.sync="dialogVisible" width="50%">
              <el-form ref="form" :model="form">
                <el-form-item label="名称">
                  <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="排序">
                  <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="是否单页">
                  <el-select v-model="form.region" placeholder="请选择！">
                    <el-option label="是" value="shanghai"></el-option>
                    <el-option label="否" value="beijing"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="是否图片新闻">
                  <el-select v-model="form.region" placeholder="请选择！">
                    <el-option label="是" value="shanghai"></el-option>
                    <el-option label="否" value="beijing"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="是否首页菜单">
                  <el-select v-model="form.region" placeholder="请选择！">
                    <el-option label="是" value="shanghai"></el-option>
                    <el-option label="否" value="beijing"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="onSubmit">立即创建</el-button>
                  <el-button @click="dialogVisible = false">取消</el-button>
                </el-form-item>
              </el-form>
            </el-dialog>
            <el-button
              type="primary"
              icon="el-icon-delete"
              size="mini"
              @click="dialogVisible1 = true"
            ></el-button>
          </div>-->
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<style  scoped>
.big {
  font-size: 0.1rem;
}
.top {
  background-color: #e5e6d3;
  width: calc(100% - 0.4rem);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.2rem;
  box-shadow: 2px 2px 2px 2px #c3c6a5;
  border-radius: 0.3rem;
}
.helper {
  font-size: 0.9rem;
  display: inline-block;
  color: #83847e;
  margin-right: 1rem;
}
.main {
  width: 70%;
  margin: 0 auto;
  margin-top: 2rem;
}
[class*="el-col-"] {
  float: unset;
  box-sizing: border-box;
}
@media screen and (min-width: 300px) and (max-width: 767px) {
  .main {
    width: 90%;
    margin: 0 auto;
    margin-top: 1rem;
  }
  .main-right {
    width: 92% !important;
    margin-left: 0 !important;
    margin-top: 2rem !important;
  }
  .el-pagination {
    white-space: normal;
  }
  .ss{
    margin-left: 0 !important;
  }
  .dp{
     margin-bottom: 1rem !important;
  }
  .el-menu-item {
    font-size: 0.3rem !important;
  }
  .header {
    font-size: 0.3rem !important;
    padding: 0.4rem;
  }
  .dp {
    display: inline-block;
    font-size: 0.9rem;
  }
  .sou {
    width: 100%;
    /* display: inline-block; */
  }
  .neirong {
    width: 100% !important;
    display: inline-block !important;
  }
  .fenye {
    margin-left: 0rem !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px){
  .main {
    width: 80%;
    margin: 0 auto;
    margin-top: 1rem;
  }
  .main-right {
    width: 61% !important;
  }
  .el-pagination {
    white-space: normal;
  }
  .ss{
    margin-left: 0 !important;
  }
  
}
.main-left {
  width: 20%;
  /* display: inline-block; */
  float: left;
  box-shadow: 2px 2px 2px 2px #c3c6a5;
  border-radius: 0.3rem;
}
.main-right {
  width: 70%;
  /* background-color: skyblue; */
  /* padding: 1rem; */
  /* float: right; */
  display: inline-block;
  padding: 0.8rem;
  background-color: #e5e6d3;
  margin-left: 2rem;
  /* background-color:#fff; */
  box-shadow: 2px 2px 2px 2px #c3c6a5;
  border-radius: 0.3rem;
  margin-bottom: 4%;
}
.dp {
  display: inline-block;
  font-size: 0.9rem;
  margin-top: 0.4rem;
}
.ico {
  /* float: right; */
  font-size: 0.9rem;
  text-align: left;
}
.sou {
  width: 73%;
  display: inline-block;
}
.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 73%;
  margin-right: 0.1rem;
}
.nei {
  float: left;
}
.neirong {
  width: 70%;
  display: inline-block;
}
.fenye {
  margin-left: 1.9rem;
}
</style>

<script>
import continuehead from "@/components/continuehead";
import continueleft from "@/components/continueleft";

export default {
  components: {
    continuehead,
    continueleft
  },
  data() {
    return {
      input: "",
      dialogVisible: false,
      dialogVisible1: false,
      dialogFormVisible3: false,
      currentPage4: 4,
      currentPage3: 2,
      input3: "",
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: ""
      },
      tableData: [
        {
          date: "20",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上",
          zip: "2020-08-23"
        }
      ],
      options: [
        {
          value: "选项1",
          label: "黄金糕"
        },
        {
          value: "选项2",
          label: "双皮奶"
        },
        {
          value: "选项3",
          label: "蚵仔煎"
        },
        {
          value: "选项4",
          label: "龙须面"
        },
        {
          value: "选项5",
          label: "北京烤鸭"
        }
      ],
      value: ""
    };
  },
  methods: {
    handleOpen(key, keyPath) {
    },
    handleClose1(key, keyPath) {
    },
    onSubmit() {
      this.dialogVisible = false;
    },
    handleClick() {
       this.$router.push({name: 'Editarticle'});
    },
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
    },
    //预览
    preview(){
       this.$router.push({name: 'preview'});
    }
  }
};
</script>


