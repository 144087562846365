<template>
  <!-- 大盒子 -->
  <div>
    <!-- 头部导航头 -->
    <div class="navigation">
      <div class="left">
        <div class="lm">
          <span>学校云</span>
        </div>
        <div class="om">|</div>
        <div class="lm1">
          <span>建设您的专属在线教育平台</span>
        </div>
      </div>
      <div class="right">
        <div class="sp1">
          <span class="login">解决方案</span>
        </div>
        <div class="sp1">
          <span class="login">渠道合作</span>
        </div>
        <div class="sp1">
          <span class="login">30天免费试用</span>
        </div>
        <div class="sp1">
          <span class="login">立即采购</span>
        </div>
        <div style="display: inline-block">|</div>
        <div class="sp1">
          <span class="login">学生认证</span>
        </div>
        <div style="display: inline-block">|</div>
        <div class="sp1">
          <span class="login" @click="dialogVisible = true">登录/注册</span>
        </div>
        <el-dialog
          :visible.sync="dialogVisible"
          width="40%"
          :before-close="handleClose"
        >
          <div class="abd">
            <div class="left1">
              <div class="zi">
                <span>登录</span>
              </div>
              <div class="geng">
                <div class="userbname">
                  <el-input
                    placeholder="用户名/手机/邮箱"
                    prefix-icon="el-icon-user"
                    v-model="input2"
                  ></el-input>
                </div>
                <div class="userbname">
                  <el-input
                    placeholder="请输入密码"
                    prefix-icon="el-icon-unlock"
                    v-model="input2"
                  ></el-input>
                </div>
              </div>
              <div class="cvg">
                <div class="zh">
                  <span>账号注册></span>
                </div>
                <div class="ma">
                  <span>忘记密码></span>
                </div>
              </div>
              <div class="loginer">一键登录</div>
              <p
                style="
                  text-align: center;
                  padding: 0.6rem;
                  font-size: 0.2rem;
                  color: #dadada;
                "
              >
                <el-checkbox v-model="checked"
                  >我已阅读并同意《用户协议》</el-checkbox
                >
              </p>
            </div>
            <div class="right1">
              <div class="zi1">
                <span>更多方式</span>
              </div>
              <div style="text-align: left; margin-top: 1rem">
                <img src="@/assets/20200601112643.png" alt />
                <img
                  src="@/assets/20200601112639.png"
                  alt
                  style="margin-left: 1rem"
                />
                <img
                  src="@/assets/20200601112633.png"
                  alt
                  style="margin-left: 1rem"
                />
              </div>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
    <!-- 导航头 结束 -->
    <!-- 大图介绍 开始 -->
    <div class="da">
      <!-- 背景图 -->
      <img class="yijian" src="@/assets/yijian.png" alt />
      <!-- 按钮 -->
      <!-- 学校/机构免费试用 -->
      <button class="an">学校/机构免费试用</button>
      <!-- 底部学校logo展示 -->
      <!-- <div class="lv">
        <div class="qv">
          <img src="@/assets/q1.png" alt />
        </div>
        <div class="qv">
          <img src="@/assets/q2.png" alt />    
        </div>
        <div class="qv">
          <img src="@/assets/q3.png" alt />
        </div>
        <div class="qv">
          <img src="@/assets/q4.png" alt />
        </div>
        <div class="qv">
          <img src="@/assets/q5.png" alt />
        </div>
        <div class="qv">
          <img src="@/assets/q6.png" alt />
        </div>
      </div>-->
    </div>
    <!-- 大图介绍 结束 -->
    <!-- 学校云学生认证    开始 -->
    <div class="renzheng">
      <!-- 左侧div -->
      <div class="zu">
        <!-- 学校云学生认证 -->
        <div class="xo">学校云学生认证</div>
        <div class="xiao">
          若你的学校已开通学校服务,请立即认证身份,加入你的学校主页
        </div>
      </div>
      <!-- 右侧div -->
      <div class="yo">
        <!-- 学生认证 -->
        <button class="an1" @click="dialogVisible1 = true">学生认证</button>
      </div>

      <el-dialog
        :visible.sync="dialogVisible1"
        width="40%"
        :before-close="handleClose"
      >
        <div class="abc">
          <div class="def">立即认证，开启学习之旅</div>
          <div>
            <div class="xuex">学校：</div>
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
        </div>
      </el-dialog>
    </div>
    <!-- 学校云学生认证    结束 -->
    <div class="cloud">
      <div class="clouder">
        <h2>为什么要选择学校云</h2>
        <div style="margin-top: 3rem">
          <div class="main-title">
            <img src="@/assets/20200803095124.png" alt />
            <h3>一站解决课前/课中/课后教学</h3>
            <p>
              课堂教育与在线课程打通，用一个工作后台解决老师课前教学、课中互动、课后测验的主要问题
            </p>
          </div>
          <div class="block-middle">
            <img src="@/assets/20200803095037.png" alt />
            <h3>成熟的平台服务，更贴合实际教学</h3>
            <p>
              覆盖上千所国内外高校、机构，近万门课程用于私有教学，提供丰富的应用场景和经验
            </p>
          </div>
          <div class="block-right">
            <img src="@/assets/20200803095031.png" alt />
            <h3>海量高质量课程资源提效教学</h3>
            <p>
              上百门国家级精品课程、8000多门精品课程，被上万次引用于各个学校来提高教学效率和教学效果
            </p>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 4rem" class="container">
      <img src="@/assets/20200803101143.png" alt style="width: 100%" />
      <div class="certification">
        <h3>全方位线上线下教学解决方案</h3>
      </div>
      <div class="certifi">
        <h4>方案一</h4>
        <h5>在线教育平台解决方案</h5>
        <p>一体化解决在线教学平台建设要求,满足完整的教学流程</p>
        <ul>
          <li>学校云在线教育平台</li>
          <li>慕课堂教学工具</li>
          <li>数据分析工具</li>
        </ul>
        <button>查看详情》</button>
        <img src="@/assets/20200803103840.png" alt />
      </div>
    </div>
    <div class="reason">
      <h2>1000+学校/机构加入学习云，已服务1400W+学生</h2>
      <div style="width: 60%; margin: 0 auto; margin-top: 3rem">
        <el-carousel height="190px">
          <el-carousel-item v-for="item in 4" :key="item">
            <h3 class="small">{{ item }}</h3>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="slide">
      <div class="tail-action">
        <div class="context">高等院校</div>
        <div class="exhibition">
          <img
            src="@/assets/885dab943fff4d418ade66bee5de64bf.png"
            alt
            style="width: 100%"
          />
        </div>
        <div class="exhibition">
          <img
            src="@/assets/885dab943fff4d418ade66bee5de64bf.png"
            alt
            style="width: 100%"
          />
        </div>
        <div class="exhibition">
          <img
            src="@/assets/885dab943fff4d418ade66bee5de64bf.png"
            alt
            style="width: 100%"
          />
        </div>
        <div class="exhibition">
          <img
            src="@/assets/885dab943fff4d418ade66bee5de64bf.png"
            alt
            style="width: 100%"
          />
        </div>
        <div class="exhibition">
          <img
            src="@/assets/885dab943fff4d418ade66bee5de64bf.png"
            alt
            style="width: 100%"
          />
        </div>
        <div class="exhibition">
          <img
            src="@/assets/885dab943fff4d418ade66bee5de64bf.png"
            alt
            style="width: 100%"
          />
        </div>
      </div>
      <div class="tail-action">
        <div class="context">公司机构</div>
        <div class="exhibition">
          <img
            src="@/assets/885dab943fff4d418ade66bee5de64bf.png"
            alt
            style="width: 100%"
          />
        </div>
        <div class="exhibition">
          <img
            src="@/assets/885dab943fff4d418ade66bee5de64bf.png"
            alt
            style="width: 100%"
          />
        </div>
        <div class="exhibition">
          <img
            src="@/assets/885dab943fff4d418ade66bee5de64bf.png"
            alt
            style="width: 100%"
          />
        </div>
        <div class="exhibition">
          <img
            src="@/assets/885dab943fff4d418ade66bee5de64bf.png"
            alt
            style="width: 100%"
          />
        </div>
        <div class="exhibition">
          <img
            src="@/assets/885dab943fff4d418ade66bee5de64bf.png"
            alt
            style="width: 100%"
          />
        </div>
        <div class="exhibition">
          <img
            src="@/assets/885dab943fff4d418ade66bee5de64bf.png"
            alt
            style="width: 100%"
          />
        </div>
      </div>
      <div class="tail-action">
        <div class="context">教育联盟</div>
        <div class="exhibition">
          <img
            src="@/assets/885dab943fff4d418ade66bee5de64bf.png"
            alt
            style="width: 100%"
          />
        </div>
        <div class="exhibition">
          <img
            src="@/assets/885dab943fff4d418ade66bee5de64bf.png"
            alt
            style="width: 100%"
          />
        </div>
        <div class="exhibition">
          <img
            src="@/assets/885dab943fff4d418ade66bee5de64bf.png"
            alt
            style="width: 100%"
          />
        </div>
        <div class="exhibition">
          <img
            src="@/assets/885dab943fff4d418ade66bee5de64bf.png"
            alt
            style="width: 100%"
          />
        </div>
        <div class="exhibition">
          <img
            src="@/assets/885dab943fff4d418ade66bee5de64bf.png"
            alt
            style="width: 100%"
          />
        </div>
      </div>
      <div class="tail-action">
        <div class="context">职业院校</div>
        <div class="exhibition">
          <img
            src="@/assets/885dab943fff4d418ade66bee5de64bf.png"
            alt
            style="width: 100%"
          />
        </div>
        <div class="exhibition">
          <img
            src="@/assets/885dab943fff4d418ade66bee5de64bf.png"
            alt
            style="width: 100%"
          />
        </div>
        <div class="exhibition">
          <img
            src="@/assets/885dab943fff4d418ade66bee5de64bf.png"
            alt
            style="width: 100%"
          />
        </div>
        <div class="exhibition">
          <img
            src="@/assets/885dab943fff4d418ade66bee5de64bf.png"
            alt
            style="width: 100%"
          />
        </div>
        <div class="exhibition">
          <img
            src="@/assets/885dab943fff4d418ade66bee5de64bf.png"
            alt
            style="width: 100%"
          />
        </div>
        <div class="exhibition">
          <img
            src="@/assets/885dab943fff4d418ade66bee5de64bf.png"
            alt
            style="width: 100%"
          />
        </div>
      </div>
    </div>
    <div>
      <div class="footer-bg-img">
        <img src="@/assets/20200803111334.png" alt style="width: 100%" />
        <!-- 20200803110659.png -->
        <div class="footer-bg-color">
          <h2>即刻使用学校云服务完成信息化教学升级</h2>
        </div>
        <div class="th-bk-main">
          <button>免费试用30天</button>
        </div>
      </div>
      <div class="mooc-cloud-footer-container">
        <div class="cloud-footer">
          <p>
            由高教社联手网易推出，让每一个有提升愿望的用户能够学到中国知名高校的课程，并获得认证。
          </p>
          <p style="margin-top: 2rem">| 京ICP备12020869号-2 |</p>
        </div>
      </div>
    </div>
  </div>
</template>
<style  scoped>
.navigation {
  /* width: 100%; */
  margin: 0 auto;
  padding: 0.8rem;
  background-color: #0228f591;
}
.aa {
  display: inline-block;
  float: right;
}
.el-dropdown-link {
  cursor: pointer;
  color: #333;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.left {
  display: inline-block;
  width: 60%;
  text-align: left;
}
.loginer {
  padding: 0.8rem;
  background-color: #ededed;
  margin-top: 1.3rem;
  border-radius: 30px;
  font-size: 0.95rem;
  text-align: center;
}
.left1 {
  display: inline-block;
  text-align: left;
  width: 39%;
  margin-top: 1rem;
}
.right {
  display: inline-block;
}
.sp {
  display: inline-block;
  margin-right: 1rem;
  font-size: 0.9rem;
}
.sp1 {
  display: inline-block;
  margin-right: 1rem;
  font-size: 0.9rem;
  color: #fff;
  margin-left: 0.9rem;
}
.om {
  display: inline-block;
  margin-right: 0.1rem;
  color: #fff;
}
.lm {
  display: inline-block;
  margin-right: 1rem;
  font-size: 1.5rem;
  color: #fff;
}
.lm1 {
  display: inline-block;
  color: #fff;
  font-size: 0.8rem;
}
.quan {
  width: 55%;
  margin: 0 auto;
  padding: 1.5rem;
}
.tup {
  float: left;
}
.sou {
  width: 35%;
  display: inline-block;
}
.liu {
  display: inline-block;
  margin-right: 1rem;
}
.shou {
  display: inline-block;
}
.login {
  cursor: pointer;
}
.abd {
  padding: 3.6rem;
  background-image: url("../../assets/bj.png");
  background-repeat: no-repeat, repeat-x, repeat-y;
}
.zi {
  font-size: 1.6rem;
  color: #00a1ff;
  text-align: center;
  margin-bottom: 2rem;
}
.userbname {
  margin-bottom: 1.5rem;
}
.zi1 {
  font-size: 1rem;
  font-weight: 800;
  color: #00a1ff;
  text-align: left;
}
.xuex {
  display: inline-block;
}
.right1 {
  width: 44%;
  float: right;
  margin-top: 1rem;
}
.zh {
  display: inline-block;
}
.ma {
  display: inline-block;
  float: right;
}
.yijian {
  width: 100%;
}
.da {
  position: relative;
}
.an {
  padding: 0.8rem 5rem;
  border-radius: 0.8rem;
  background-color: #4eba46;
  border: none;
  color: #fff;
  position: absolute;
  left: 16.4%;
  top: 61%;
  font-size: 1rem;
  font-weight: 600;
}
.el-input {
  width: 50%;
}
.an1 {
  padding: 0.8rem 5rem;
  border-radius: 0.8rem;
  background-image: linear-gradient(to right, #516ab7, #6d86d1);
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
}
.lv {
  background-color: #4656b4;
  position: absolute;
  width: 100%;
  top: 89%;
}
.qv {
  display: inline-block;
  margin: 0.5rem 3rem;
}
.renzheng {
  /* border: 0.1rem  solid #5e5e5e; */
  /* div下边框阴影设置 */
  box-shadow: 0px 20px 20px -20px #5e5e5e;
  padding: 1.2rem;
}
.zu {
  display: inline-block;
  text-align: left;
}
.yo {
  display: inline-block;
  margin-left: 2rem;
}
.xo {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.2rem;
}
.xiao {
  font-size: 0.9rem;
}
.cloud {
  padding: 2rem;
  margin-top: 2rem;
}
.clouder {
  width: 70%;
  margin: 0 auto;
  background-color: #fff;
}
.clouder h2 {
  font-weight: 200;
  font-size: 1.2rem;
}
.main-title {
  width: 30%;
  display: inline-block;
}
.main-title h3 {
  font-weight: 200;
  font-size: 0.9rem;
}
.main-title p {
  margin-top: 0.6rem;
  font-size: 0.8rem;
}
.block-middle {
  width: 30%;
  display: inline-block;
  margin-left: 2rem;
}
.block-middle h3 {
  font-weight: 200;
  font-size: 0.9rem;
}
.block-middle p {
  margin-top: 0.6rem;
  font-size: 0.8rem;
}
.block-right {
  width: 30%;
  display: inline-block;
  margin-left: 2rem;
}
.block-right h3 {
  font-weight: 200;
  font-size: 0.9rem;
}
.block-right p {
  margin-top: 0.6rem;
  font-size: 0.8rem;
}
.container {
  position: relative;
}
.certification {
  position: absolute;
  top: 15%;
  left: 40%;
}
.certification h3 {
  font-weight: 200;
  font-size: 1.4rem;
  letter-spacing: 0.3rem;
  color: #fff;
}
.certifi h4 {
  position: absolute;
  font-weight: 200;
  font-size: 1.2rem;
  letter-spacing: 0.3rem;
  color: #fff;
  margin-top: 1rem;
  top: 30%;
  left: 30%;
}
.certifi h5 {
  position: absolute;
  font-weight: 200;
  font-size: 1rem;
  letter-spacing: 0.3rem;
  color: #fff;
  margin-top: 1rem;
  top: 35%;
  left: 30%;
}
.certifi p {
  position: absolute;
  font-weight: 200;
  font-size: 0.8rem;
  color: #fff;
  margin-top: 1rem;
  top: 38%;
  left: 30%;
}
/*  */
.certifi ul {
  position: absolute;
  font-weight: 200;
  font-size: 0.9rem;
  color: #fff;
  margin-top: 1rem;
  top: 42%;
  left: 30%;
}
.certifi ul li {
  margin-bottom: 1rem;
}
.certifi button {
  padding: 1rem 5rem;
  position: absolute;
  font-weight: 200;
  font-size: 0.9rem;
  margin-top: 1rem;
  top: 58%;
  left: 30%;
  background-color: #8fb7ff;
  border: none;
}
.certifi img {
  position: absolute;
  font-weight: 200;
  font-size: 0.9rem;
  margin-top: 1rem;
  top: 25%;
  left: 55%;
}
.reason {
  padding: 2rem;
  margin-top: 3rem;
}
.reason h2 {
  font-size: 2rem;
  font-weight: 300;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 190px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.slide {
  width: 80%;
  margin: 0 auto;
  padding: 2rem;
}
.context {
  text-align: left;
  width: 96%;
  margin: auto;
}
.exhibition {
  width: 15%;
  display: inline-block;
  margin-right: 1rem;
  margin-top: 1rem;
}
.tail-action {
  margin-bottom: 2rem;
}
.footer-bg-img {
  position: relative;
}
.footer-bg-color h2 {
  position: absolute;
  top: 30%;
  left: 35%;
  color: #fff;
  font-weight: 200;
  font-size: 1.7rem;
  letter-spacing: 0.3rem;
}
.th-bk-main button {
  position: absolute;
  top: 60%;
  left: 42%;
  color: #fff;
  font-weight: 200;
  font-size: 1rem;
  letter-spacing: 0.3rem;
  padding: 1rem 5rem;

  /* Radial Gradients */
  background-image: linear-gradient(to bottom right, #2e3846, #4b4e56);
  border-radius: 1.3rem;
  border: none;
}
.mooc-cloud-footer-container {
  background-color: #2e3846;
  padding: 2rem;
}
.cloud-footer {
  width: 70%;
  margin: 0 auto;
  font-size: 0.9rem;
}
.cloud-footer p {
  color: #fff;
}
.def {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.abc {
  padding: 2rem;
}
/* 测试 */
.ad {
  margin-bottom: 2.1rem;
  padding: 12rem;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .certifi p {
    top: 39%;
    margin-top: 1.3rem;
  }
  .certifi ul {
    top: 46%;
  }
  .certifi button {
    top: 70%;
  }
  .certifi img {
    top: 28%;
    left: 70%;
    width: 24%;
  }
  .certifi ul li {
    margin-bottom: 0.7rem;
  }
  .footer-bg-color h2 {
    left: 19%;
  }
  .th-bk-main button {
    left: 33%;
  }
  .certification {
    left: 32%;
  }
}
@media screen and (min-width: 300px) and (max-width: 767px) {
  .an {
    padding: 0.2rem 2rem;
    border-radius: 0.2rem;
    font-size: 0.3rem;
  }
  .renzheng {
    padding: 1rem;
  }
  .xo {
    font-size: 0.8rem;
  }
  .xiao {
    font-size: 0.6rem;
  }
  .an1 {
    padding: 0.6rem 2rem;
  }
  .main-title {
    width: 70%;
  }
  .block-middle {
    width: 70%;
    margin-left: 0;
  }
  .block-right {
    width: 70%;
    margin-left: 0;
  }
  .certification h3 {
    font-size: 0.8rem;
  }
  .certification {
    left: 24%;
  }
  .certifi h4 {
    font-size: 0.5rem;
    top: 17%;
    left: 7%;
  }
  .certifi h5 {
    font-size: 0.5rem;
    top: 27%;
    left: 7%;
  }
  .certifi p {
    font-size: 0.2rem;
    top: 38%;
    left: 7%;
  }
  .certifi ul {
    font-size: 0.2rem;
    top: 58%;
    left: 7%;
    margin-top: 0.1rem;
  }
  .certifi ul li {
    margin-bottom: 0.1rem;
  }
  .certifi img {
    display: none;
  }
  .certifi button {
    padding: 0.4rem 2rem;
    left: 54%;
  }
  .reason h2 {
    font-size: 1.3rem;
  }
  .footer-bg-color h2 {
    font-size: 0.6rem;
    left: 16%;
  }
  .th-bk-main button {
    padding: 0.4rem 2rem;
    font-size: 0.5rem;
    left: 31%;
  }
}
</style>
<script>
export default {
  data() {
    return {
      input: "",
      dialogVisible: false,
      dialogVisible1: false,
      input2: "",
      checked: true,
    };
  },
  methods: {
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
  },
};
</script> 