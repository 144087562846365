<template>
  <div>
    <!---头部图片-->
    <div>
      <ContinuingHead></ContinuingHead>
      <router-view></router-view>
    </div>
    <div style="background-color:rgb(242, 236, 217)">
      <div class="top">
        <!---导航头-->
        <div>
          <Continuingleft></Continuingleft>
          <router-view></router-view>
          <!---通知公告-->
          <div class="blocker" style="font-size:0.2rem;">
            <div class="title">
              <h2>
                <span>政策法规</span>
                <span style="float:right">
                  <p style="display:inline-block;color:#333;">首页 ></p>政策法规
                </span>
              </h2>
              <div style="text-align:left;padding:0.1rem;margin-top:1rem">
                <p class="sort">排序</p>
                <p class="according">按时间</p>
                <p class="according">|</p>
                <p class="according">按排序</p>
                <p class="according">
                  <el-input placeholder="请输入内容" v-model="input" class="input-with-select">
                    <el-button slot="append" icon="el-icon-search"></el-button>
                  </el-input>
                </p>
              </div>
              <ul style="margin-top:1rem">
                <li>
                  <router-link class="nower" to>
                    河南省教育厅关于公布河南省教育科学“十三五”规划 2019年度教育装备和实践教育专项课题立项名单的通知
                    <span
                      class="dater"
                    >2019-05-15</span>
                    <span style="margin-right:2rem" class="dater">2851次</span>
                  </router-link>
                </li>
                <li>
                  <router-link class="nower" to>
                    河南省教育厅关于公布河南省教育科学“十三五”规划 2019年度教育装备和实践教育专项课题立项名单的通知
                    <span
                      class="dater"
                    >2019-05-15</span>
                    <span style="margin-right:2rem" class="dater">2851次</span>
                  </router-link>
                </li>
                <li>
                  <router-link class="nower" to>
                    河南省教育厅关于公布河南省教育科学“十三五”规划 2019年度教育装备和实践教育专项课题立项名单的通知
                    <span
                      class="dater"
                    >2019-05-15</span>
                    <span style="margin-right:2rem" class="dater">2851次</span>
                  </router-link>
                </li>
                <li>
                  <router-link class="nower" to>
                    河南省教育厅关于公布河南省教育科学“十三五”规划 2019年度教育装备和实践教育专项课题立项名单的通知
                    <span
                      class="dater"
                    >2019-05-15</span>
                    <span style="margin-right:2rem" class="dater">2851次</span>
                  </router-link>
                </li>
                <li>
                  <router-link class="nower" to>
                    河南省教育厅关于公布河南省教育科学“十三五”规划 2019年度教育装备和实践教育专项课题立项名单的通知
                    <span
                      class="dater"
                    >2019-05-15</span>
                    <span style="margin-right:2rem" class="dater">2851次</span>
                  </router-link>
                </li>
                <li>
                  <router-link class="nower" to>
                    河南省教育厅关于公布河南省教育科学“十三五”规划 2019年度教育装备和实践教育专项课题立项名单的通知
                    <span
                      class="dater"
                    >2019-05-15</span>
                    <span style="margin-right:2rem" class="dater">2851次</span>
                  </router-link>
                </li>
                <li>
                  <router-link class="nower" to>
                    河南省教育厅关于公布河南省教育科学“十三五”规划 2019年度教育装备和实践教育专项课题立项名单的通知
                    <span
                      class="dater"
                    >2019-05-15</span>
                    <span style="margin-right:2rem" class="dater">2851次</span>
                  </router-link>
                </li>
                <li>
                  <router-link class="nower" to>
                    河南省教育厅关于公布河南省教育科学“十三五”规划 2019年度教育装备和实践教育专项课题立项名单的通知
                    <span
                      class="dater"
                    >2019-05-15</span>
                    <span style="margin-right:2rem" class="dater">2851次</span>
                  </router-link>
                </li>
                <div style="padding:1rem;">
                  <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage1"
                    :page-size="100"
                    layout="total, prev, pager, next"
                    :total="1000"
                  ></el-pagination>
                </div>
              </ul>
            </div>
            <!---分页-->
          </div>
        </div>
        <ContinuingBottom></ContinuingBottom>
          <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import ContinuingHead from "@/components/ContinuingHead";
import Continuingleft from "@/components/Continuingleft";
import ContinuingBottom from "@/components/ContinuingBottom";

// @ is an alias to /src
export default {
  components: {
    ContinuingHead,
    Continuingleft,ContinuingBottom
  },
  data() {
    return {
      dialogFormVisible: false,
      currentPage1: 1,
      input: ""
    };
  },
  methods: {
    //登陆
    login() {
      this.dialogFormVisible = true;
    },
    //分页
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
    }
  }
};
</script>
<style scoped>
.header {
  background-color: #006834;
  padding: 0.2rem 0.5rem;
  color: #fff;
  font-size: 0.18rem;
  text-align: left;
}
.loginer {
  text-align: right;
}
.top {
  width: 65%;
  margin: 0 auto;
  margin-top: 2%;
}
.top img {
  width: 100%;
  margin: 0 auto;
  height: 100%;
}
#nav {
  margin: 0 auto;
  padding: 0;
}
#nav ul,
#nav li {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
#nav ul {
  display: flex;
  flex-direction: row;
  background-color: #006834;
}
#nav li {
  color: #fff;
  font-size: 0.15rem;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 12px;
  flex: auto; /*这是关键*/
  cursor: pointer;
}
#nav li a {
  color: #fff;
  text-decoration: none;
}
#nav a.router-link-exact-active {
  color: #fff;
  text-decoration: none;
}
.sort {
  display: inline-block;
  font-size: 0.9rem;
}
.according {
  display: inline-block;
  margin-left: 1.5rem;
  font-size: 0.9rem;
}
@media screen and (min-width: 300px) and (max-width: 767px) {
  .top{
    width: 88%;
  }
  .block {
    width: 100% !important;
  }
  .blocker {
    width: 91% !important;
    margin-bottom: 3% !important;
    margin-top: 2rem !important;
    float: none !important;
    margin-left: 0 !important;
  }
  .title ul li {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .title h2 span {
    padding-left: 0.6rem !important;
  }
  #nav li {
    font-size: 0.1rem !important;
  }
  .indexCenter {
    width: 100% !important;
    margin-bottom: 3% !important;
  }
  .indexCenters {
    width: 90% !important;
  }
  .titler h2 span {
    padding-left: 0.6rem !important;
  }
  .footer {
    font-size: 0.1rem;
  }
  .el-pagination {
    white-space: normal !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .top {
    width: 86% !important;
  }
  .block {
    width: 100% !important;
  }
  .blocker {
    width: 52% !important;
    margin-bottom: 3% !important;
    margin-top: 2rem !important;
    display: inline-block !important;
    float: none !important;
  }
  .title ul li {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .title h2 span {
    padding-left: 0.6rem !important;
  }
  .according{
        margin-left: 0.5rem !important;
  }
  #nav li {
    font-size: 0.1rem !important;
  }
  .indexCenter {
    width: 100% !important;
    margin-bottom: 3% !important;
  }
  .indexCenters {
    width: 36% !important;
    margin-top: 2rem !important;
    float: left !important;
  }
  .titler h2 span {
    padding-left: 0.6rem !important;
  }
  .footer {
    font-size: 0.1rem;
  }
  .el-pagination {
    white-space: normal !important;
  }
}
/* @media only screen and (max-width: 2000px) {
  .top{
    width: 70% !important;
  }
} */
.blocker {
  width: 68%;
  /* display: inline-block; */
  margin-left: 6%;
  float: right;
  background-color: #e5e6d3;
  box-shadow: 2px 2px 2px 2px #c3c6a5;
  border-radius: 0.3rem;
  margin-top: 2%;
  padding: 1rem;
}
.title h2 {
  border-bottom: 1px solid #006834;
  color: #505050;
  font-size: 0.9rem;
  color: rgb(130, 162, 149);
  line-height: 39px;
  text-align: left;
  background: url(../assets/titbor.png) no-repeat 0px 37px;
  padding-left: 0.1rem;
}
.title ul {
  list-style: none;
}
.title .more {
  text-decoration: none;
  font-size: 0.1rem;
  color: #006834;
}
.title ul li {
  border-bottom: 1px solid #fff;
  font-size: 0.12rem;
  text-align: left;
  line-height: 36px;
}
.title .nower {
  text-decoration: none;
  font-size: 0.12rem;
  color: #585858;
}
.title .nowers {
  background: url(../assets/l_li.jpg);
  border: none;
  padding: 0.2rem;
}
.title .dater {
  float: right;
  color: #909090;
}
.titler {
  /* padding-top: 4rem; */
  padding: 4rem 2rem 2rem 2rem;
}
.titler h2 {
  border-bottom: 1px solid #006834;
  color: #505050;
  font-size: 0.1rem;
  line-height: 39px;
  text-align: left;
  background: url(../assets/titbor.png) no-repeat 0px 37px;
  padding-left: 0.1rem;
}
.titler ul {
  list-style: none;
}
.titler h2 span {
  /* color: #505050; */
  font-size: 0.1rem;
  /* background: url(../assets/icontit.png) no-repeat 0px 9px; */
  text-align: left;
  padding-left: 0.3rem;
  display: inline-block;
}
.titler .more {
  text-decoration: none;
  font-size: 0.1rem;
  color: #006834;
}
.titler ul li {
  font-size: 0.12rem;
}
.titler .nower {
  text-decoration: none;
  font-size: 0.12rem;
  color: #909090;
}
.titler li {
  /* background: url(../assets/l_li.jpg) no-repeat; */
  background-color: #eee;
  border-radius: 30px;
}
.titler .dater {
  float: right;
  color: #909090;
}
.indexCenters ul li .nowers {
  font-size: 0.12rem;
  text-align: center;
  text-decoration: none;
  color: #333;
  font-weight: bold;
}
.footer {
  margin-top: 1%;
  font-size: 0.15rem;
}
.bottomlookcount {
  border-top: 1px solid #016834;
}
</style>
