<template>
  <div class="big">
    <continuehead></continuehead>
    <router-view></router-view>
    <div style="background-color: rgb(242, 236, 217);">
      <div class="main">
        <continueleft></continueleft>
        <router-view></router-view>
        <div class="main-right">
          <div style="text-align:left;margin-bottom:3%">
            <div class="dp">
              <i class="el-icon-set-up"></i>
              <span>添加新闻</span>
            </div>
            <div style="display: inline-block;float: right;">
              <el-button type="success" style="padding: 8px 9px;">保存</el-button>
              <el-button type="success" style="padding: 8px 9px;" @click="back()">返回</el-button>
            </div>
          </div>
          <div style="margin-top:1rem;font-size:0.9rem;text-align:left">
            <div style="padding:0.6rem">
              名称：
              <el-input v-model="input" placeholder="请输入内容"></el-input>
            </div>
            <div style="padding:0.6rem;display: inline-block;">
              是否单页：
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div style="padding:0.6rem;display: inline-block;">
              是否图片新闻：
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div style="padding:0.6rem;display: inline-block;">
              是否首页菜单：
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div style="padding:0.6rem;display: inline-block;">
              排序：
              <el-input v-model="input" placeholder="请输入内容" style="width:79%"></el-input>
            </div>
            <div style="padding:0.6rem;display: inline-block;">
              发布时间：
              <el-date-picker v-model="value1" type="date" placeholder="选择日期"></el-date-picker>
            </div>
            <div style="padding:0.6rem;">
              <span>封面照片：</span>
              <img class="portrait_image" src="@/assets/defaultCover.png" style="width:30%" alt />
              <!-- <div>
                    <input type="file" id="file" style="display:none">
                    <label for="">上传图片</label>
              </div>-->
              <label>
                <input type="file" id="file" style="display:none;" />
                <!-- <img class="portrait_image" src="@/assets/defaultCover.png" style="width:30%" alt /> -->
                <div class="portrait_Sprite">
                  <span>上传图片</span>
                </div>
              </label>
            </div>
            <div style="padding:0.6rem;">
              内容：
              <!-- <quill-editor
                v-model="content"
                ref="myQuillEditor"
                style="height: 500px;"
                :options="editorOption"
              ></quill-editor> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style  scoped>
.big {
  font-size: 0.1rem;
}
.top {
  background-color: #e5e6d3;
  width: calc(100% - 0.4rem);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.2rem;
  box-shadow: 2px 2px 2px 2px #c3c6a5;
  border-radius: 0.3rem;
}
.helper {
  font-size: 0.9rem;
  display: inline-block;
  color: #83847e;
  margin-right: 1rem;
}
.main {
  width: 70%;
  margin: 0 auto;
  margin-top: 2rem;
}
[class*="el-col-"] {
  float: unset;
  box-sizing: border-box;
}
.portrait_Sprite {
  padding: 0.6rem;
  display: inline-block;
  background-color: #67c23a;
  color: #fff;
  border-radius: 10%;
  margin-left: 2rem;
}
@media screen and (min-width: 300px) and (max-width: 767px) {
  .main {
    width: 90%;
    margin: 0 auto;
    margin-top: 0.2rem;
  }
  .main-right {
    width: 92% !important;
    margin-left: 0 !important;
    margin-top: 2rem;
  }
  .el-input {
    width: 84% !important;
  }
  .el-menu-item {
    font-size: 0.3rem !important;
  }
  .header {
    font-size: 0.3rem !important;
    padding: 0.4rem;
  }
  .dp {
    display: inline-block;
    font-size: 0.9rem;
  }
  .sou {
    width: 100%;
    /* display: inline-block; */
  }
  .neirong {
    width: 100% !important;
    display: inline-block !important;
  }
  .fenye {
    margin-left: 0rem !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .main {
    width: 80%;
    margin: 0 auto;
    margin-top: 1rem;
  }
  .main-right {
    width: 61% !important;
  }
}
.main-left {
  width: 20%;
  /* display: inline-block; */
  float: left;
  box-shadow: 2px 2px 2px 2px #c3c6a5;
  border-radius: 0.3rem;
}
.main-right {
  width: 70%;
  /* background-color: skyblue; */
  /* padding: 1rem; */
  /* float: right; */
  display: inline-block;
  padding: 0.8rem;
  background-color: #e5e6d3;
  margin-left: 2rem;
  /* background-color:#fff; */
  box-shadow: 2px 2px 2px 2px #c3c6a5;
  border-radius: 0.3rem;
  margin-bottom: 4%;
}
.dp {
  display: inline-block;
  font-size: 0.9rem;
  margin-top: 0.4rem;
}
.ico {
  /* float: right; */
  font-size: 0.9rem;
  text-align: left;
}
.sou {
  width: 73%;
  display: inline-block;
}
.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 70%;
  margin-right: 0.1rem;
}
.nei {
  float: left;
}
.neirong {
  width: 70%;
  display: inline-block;
}
.fenye {
  margin-left: 1.9rem;
}
</style>

<script>
import continuehead from "@/components/continuehead";
import continueleft from "@/components/continueleft";

export default {
  components: {
    continuehead,
    continueleft
  },
  data() {
    return {
      input: "",
      options: [
        {
          value: "选项1",
          label: "黄金糕"
        },
        {
          value: "选项2",
          label: "双皮奶"
        },
        {
          value: "选项3",
          label: "蚵仔煎"
        },
        {
          value: "选项4",
          label: "龙须面"
        },
        {
          value: "选项5",
          label: "北京烤鸭"
        }
      ],
      value: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            }
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            }
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            }
          }
        ]
      },
      value1: "",
      value2: ""
    };
  },
  methods: {
    //返回
    back() {
      this.$router.go(-1);
    }
  }
};
</script>


