<template>
  <div class="main-left">
    <!-- 教学后台管理 -->
    <el-col>
      <h5
        class="header"
        style="background-color: rgb(84, 92, 100);font-size: 0.15rem;color: rgb(255, 255, 255);padding: 0.3rem;"
      >
        <i class="el-icon-location"></i> 教改后台管理
      </h5>
      <el-menu
        :default-active="onRoutes"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose1"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b"
        router
      >
      <!-- 管理员管理 -->
        <el-menu-item index="Background">
          <i class="el-icon-menu"></i>
          <span slot="title">管理员管理</span>
        </el-menu-item>
        <!-- 文章管理 -->
        <el-menu-item index="managementel">
          <i class="el-icon-setting"></i>
          <span slot="title">文章管理</span>
        </el-menu-item>
        <!-- 技术支持管理 -->
        <el-menu-item index="adminitor">
          <i class="el-icon-folder-opened"></i>
          <span slot="title">技术支持管理</span>
        </el-menu-item>
        <!-- 留言板管理 -->
        <el-menu-item index="MessageBoard">
          <i class="el-icon-files"></i>
          <span slot="title">留言板管理</span>
        </el-menu-item>
        <!-- 联系我们管理 -->
        <el-menu-item index="Contus">
          <i class="el-icon-edit-outline"></i>
          <span slot="title">联系我们管理</span>
        </el-menu-item>
         <!-- <el-menu-item index="Contus">
          <i class="el-icon-edit-outline"></i>
          <span slot="title">项目负责人管理</span>
        </el-menu-item> -->
      </el-menu>
    </el-col>
  </div>
</template>
<style  scoped>
.big {
  font-size: 0.1rem;
}
.top {
  background-color: #153c75;
  width: calc(100% - 0.4rem);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.2rem;
}
.helper {
  font-size: 0.15rem;
  display: inline-block;
  color: #fff;
  margin-right: 0.1rem;
}
.main {
  width: 70%;
  margin: 0 auto;
  margin-top: 0.2rem;
}
.main-left {
  width: 22%;
  display: inline-block;
  float: left;
}
[class*="el-col-"] {
  float: unset;
  box-sizing: border-box;
}
@media only screen and (max-width: 540px) {
  .main {
    width: 90%;
    margin: 0 auto;
    margin-top: 0.2rem;
  }
  .main-left {
    width: 100% !important;
    margin-bottom: 0.2rem !important;
  }
  .el-menu-item {
    font-size: 0.15rem !important;
  }
  .header {
    font-size: 0.16rem !important;
    padding: 0.4rem;
  }
  .dp[data-v-1aa8c6e6] {
    display: inline-block;
    font-size: 0.3rem;
    float: left;
    margin-top: 0.09rem;
  }
  .sou[data-v-1aa8c6e6] {
    width: 100%;
    /* display: inline-block; */
  }
  .neirong[data-v-1aa8c6e6] {
    width: 100% !important;
    display: inline-block !important;
  }
  .fenye {
    margin-left: 0rem !important;
  }
}
.dp {
  display: inline-block;
  font-size: 0.15rem;
  float: left;
  margin-top: 0.04rem;
}
.ico {
  float: right;
}
.sou {
  width: 73%;
  display: inline-block;
}
.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 45%;
  margin-right: 0.1rem;
}
.nei {
  float: left;
}
.neirong {
  width: 70%;
  display: inline-block;
}
.fenye {
  margin-left: 1.9rem;
}
</style>
<script>
export default {
  computed: {
    // 左边栏跳转路径
    onRoutes() {
      return this.$route.path.replace("/", "");
    }
  },
  data() {
    return {
    };
  },
  methods: {
    handleOpen(key, keyPath) {
    },
    handleClose1(key, keyPath) {
    },
  }
};
</script>
