import { render, staticRenderFns } from "./Management.vue?vue&type=template&id=4bd8254f&scoped=true"
var script = {}
import style0 from "./Management.vue?vue&type=style&index=0&id=4bd8254f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bd8254f",
  null
  
)

export default component.exports