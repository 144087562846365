import { render, staticRenderFns } from "./Subblockadd.vue?vue&type=template&id=20c2a78e&scoped=true"
import script from "./Subblockadd.vue?vue&type=script&lang=js"
export * from "./Subblockadd.vue?vue&type=script&lang=js"
import style0 from "./Subblockadd.vue?vue&type=style&index=0&id=20c2a78e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20c2a78e",
  null
  
)

export default component.exports