<template>
  <div>
    <div class="footer">
      <div class="footerCon">
        <ul>
          <li>
            <router-link to>研究动态</router-link>
          </li>
          <li>|</li>
          <li>
            <router-link to>项目应用</router-link>
          </li>
          <li>|</li>
          <li>
            <router-link to>项目资料</router-link>
          </li>
          <li>|</li>
          <li>
            <router-link to>项目负责人</router-link>
          </li>
          <li>|</li>
          <li>
            <router-link to>项目成果</router-link>
          </li>
          <li>|</li>
          <li>
            <router-link to>留言反馈</router-link>
          </li>
          <li>|</li>
          <li>
            <router-link to>联系我们</router-link>
          </li>
          <li>|</li>
          <li>
            <router-link to>技术支持</router-link>
          </li>
          <li>|</li>
          <li>
            <span style="cursor: pointer;" @click="ners()">新闻管理</span>
          </li>
        </ul>
        <p class="newfooter">技术支持©河南工业大学远程与继续教育学院</p>
        <p class="newfooter" style="padding-bottom:0.2rem">豫ICP备17027020号-1</p>
      </div>
      <!--登录弹框 -->
      <div>
      <el-dialog
        :visible.sync="dialogFormVisible"
        center
        :append-to-body="true"
        :lock-scroll="false"
         :width="dialogWidth"
      >
        <!--用户名登录表单-->
        <div>
          <h2 style="padding:0.1rem;text-align:center;background-color:#475d70;color:#fff;font-weight:500;">账号登陆</h2>
          <div style="padding:0.2rem;text-align:center;">
            登录名：
            <el-input v-model="input" placeholder="请输入登录名"></el-input>
          </div>
            <div style="padding-left:0.2rem;text-align:center;">
            密码：
            <el-input v-model="input" placeholder="请输入密码" show-password></el-input>
          </div>
          <div style="padding:0.2rem;text-align:center;">
             <el-button type="primary" @click="login">登录</el-button>
          </div>
        </div>
      </el-dialog>
      </div>
    </div>
  </div>
</template>
<style scoped>
.footer {
  background-color: #51585c;
}
.footerCon {
  width: 60%;
  margin: 0 auto;
}
.footerCon ul {
  list-style: none;
  font-size: 0.15rem;
  color: #fff;
  display: inline-block;
}
.footerCon ul li {
  float: left;
  margin-left: 0.25rem;
  color: #fff;
}
.footerCon ul li a {
  color: #fff;
  text-decoration: none;
  text-align: center;
}                        
.newfooter {
  font-size: 0.14rem;
  color: #939393;
}
.el-input{
  width:50%;
}
.el-button--primary{
  background-color: #339100;
  border: #339100
}
@media only screen and (max-width: 800px) {
}
</style>
<script>
export default {
  data() {
    return {
      dialogFormVisible: false,
      input: '',
      dialogWidth: 0,
    };
  },
  created() {
    this.setDialogWidth()
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.setDialogWidth()
      })()
    }
  },
  methods: {
    setDialogWidth() {
      var val = document.body.clientWidth
      const def = 450 // 默认宽度
      if (val < def) {
        this.dialogWidth = '80%'
      } else {
        this.dialogWidth = def + 'px'
      }
    },
    ners() {
      this.dialogFormVisible = true;
    },
    login(){
          this.$router.push('/Background')
    }
  }
};
</script>