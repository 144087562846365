<template>
  <div>
    <!--引入头部-->
    <div>
      <Header></Header>
      <router-view></router-view>
    </div>
    <!--内容-->
    <div class="contrain">
      <div class="contrainr">
        <div class="tcleft">
          <div class="title">
            <h2>
              <span>您的位置：首页>项目资料></span>
              <router-link to class="more" style="float:right;">More></router-link>
            </h2>
            <ul>
              <li>
                <router-link class="nower" to>2019年12月职业与继续教育要闻简报</router-link>
              </li>
              <li>
                <router-link class="nower" to>2019年12月职业与继续教育要闻简报</router-link>
              </li>
              <li>
                <router-link class="nower" to>2019年12月职业与继续教育要闻简报</router-link>
              </li>
              <li>
                <router-link class="nower" to>2019年12月职业与继续教育要闻简报</router-link>
              </li>
              <li>
                <router-link class="nower" to>2019年12月职业与继续教育要闻简报</router-link>
              </li>
            </ul>
          </div>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage3"
            :page-size="100"
            layout="prev, pager, next, jumper"
            :total="1000"
            small
          ></el-pagination>
        </div>
        <div class="tcright">
          <div class="title">
            <h2>
              <span>热点新闻</span>
              <router-link to class="more" style="float:right;">More></router-link>
            </h2>
            <ul>
              <li>
                <router-link class="nower" to>2019年12月职业与继续教育要闻简报</router-link>
              </li>
              <li>
                <router-link class="nower" to>2019年12月职业与继续教育要闻简报</router-link>
              </li>
              <li>
                <router-link class="nower" to>2019年12月职业与继续教育要闻简报</router-link>
              </li>
              <li>
                <router-link class="nower" to>2019年12月职业与继续教育要闻简报</router-link>
              </li>
              <li>
                <router-link class="nower" to>2019年12月职业与继续教育要闻简报</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
         <div>
          <Footer></Footer>
          <router-view></router-view>
        </div>
    </div>
  </div>
</template>
<style scoped>
.contrain {
  background-color: #efefef;
}
.contrainr {
  width: 90%;
  margin: 0 auto;
}
.tcleft {
  width: 64%;
  background-color: #fff;
  display: inline-block;
  margin-top: 0.2rem;
  margin-bottom: 0.4rem;
}
.title h2 {
  border-bottom: 1px solid #1697eb;
  color: #505050;
  font-size: 0.1rem;
  line-height: 39px;
  text-align: left;
}
.title ul {
  list-style: none;
  padding: 0.1rem;
}
.title h2 span {
  color: #505050;
  font-size: 0.15rem;
  text-align: left;
  background-color: #1697eb;
  display: inline-block;
  color: #fff;
  padding: 0.08rem;
}
.title .more {
  text-decoration: none;
  font-size: 0.14rem;
  color: #999;
  margin-right: 0.1rem;
  margin-top: 0.1rem;
}
.title ul li {
  font-size: 0.3rem;
  text-align: left;
  line-height: 36px;
}
.title .nower {
  text-decoration: none;
  font-size: 0.14rem;
  color: #909090;
}
.title .dater {
  float: right;
  color: #909090;
}
.tcright {
  width: 30%;
  background-color: #fff;
  display: inline-block;
  float: right;
  margin-top: 0.2rem;
  margin-bottom: 0.4rem;
}
@media only screen and (max-width: 800px) {
    .tcright{
        width: 100% !important;
        float: none !important;
    }
    .tcleft{
        width: 100% !important;
    }
    .el-pagination{
      white-space:pre !important;
    }
}
</style>
<script>
import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
export default {
  components: { Header, Footer },
  data() {
    return {
      currentPage3: 1
    };
  },
  methods: {
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
    }
  }
};
</script>