<template>
  <div class="big">
    <div>
      <Header></Header>
      <router-view></router-view>
    </div>
    <div class="main">
      <Left></Left>
      <router-view></router-view>
      <div class="mainright">
        <div style="border-color: #ddd;">
          <div class="clearfix">
            <h3 class="ampanel">
              <i class="el-icon-s-fold"></i>
              管理员管理
              <div class="amtext">
                <button @click="creatr()">+ 添加</button>
                <button>x 删除</button>
              </div>
            </h3>
          </div>
          <el-dialog
            :visible.sync="dialogFormVisible"
            center
            :append-to-body="true"
            :lock-scroll="false"
            :width="dialogWidth"
          >
            <!--用户名登录表单-->
            <div>
              <h2 style="padding:0.1rem;text-align:center;font-weight:500;">新增管理员信息</h2>
              <div class="namer">
                姓名：
                <el-input v-model="input" style="width:54%" placeholder="请输入内容"></el-input>
              </div>
              <div class="namer">
                性别：
                <el-select v-model="value" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <div class="namer">
                手机号：
                <el-input v-model="input" style="width:54%" placeholder="请输入内容"></el-input>
              </div>
              <div class="namer">
                邮箱：
                <el-input v-model="input" style="width:54%" placeholder="请输入内容"></el-input>
              </div>
              <div class="namer">
                密码：
                <el-input v-model="input" style="width:54%" placeholder="请输入内容"></el-input>
              </div>
              <div class="namer">
                初始密码：
                <el-input v-model="input" style="width:54%" placeholder="请输入内容"></el-input>
              </div>
              <div class="namer">
                单位：
                <el-input v-model="input" style="width:54%" placeholder="请输入内容"></el-input>
              </div>
              <div class="namer">
                <el-button type="success">提交</el-button>
                <el-button type="success">取消</el-button>
              </div>
            </div>
          </el-dialog>
          <div class="amform">
            <h3>
              板块名称：
              <el-input v-model="input" placeholder="请输入内容"></el-input>
              <el-button type="success">查询</el-button>
            </h3>
          </div>
          <div>
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column prop="date" label="姓名" width="120"></el-table-column>
              <el-table-column prop="name" label="性别" width="120"></el-table-column>
              <el-table-column prop="province" label="手机号" width="120"></el-table-column>
              <el-table-column prop="city" label="邮箱" width="120"></el-table-column>
              <el-table-column prop="city" label="密码" width="120"></el-table-column>
              <el-table-column prop="address" label="单位" width="300"></el-table-column>
              <el-table-column prop="zip" label="状态" width="120"></el-table-column>
              <el-table-column fixed="right" label="操作" width="130">
                <template slot-scope="scope">
                  <el-button type="text"  @click="handleClick(scope.row)"  size="small">编辑</el-button>
                  <el-button type="text" size="small">禁用</el-button>
                  <el-button type="text" size="small">启动</el-button>
                </template>
              </el-table-column>
            </el-table>
             <el-dialog
            :visible.sync="dialogFormVisible1"
            center
            :append-to-body="true"
            :lock-scroll="false"
            :width="dialogWidth"
          >
            <!--用户名登录表单-->
            <div>
              <h2 style="padding:0.1rem;text-align:center;font-weight:500;">编辑管理员信息</h2>
              <div class="namer">
                姓名：
                <el-input v-model="input" style="width:54%" placeholder="请输入内容"></el-input>
              </div>
              <div class="namer">
                性别：
                <el-select v-model="value" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <div class="namer">
                手机号：
                <el-input v-model="input" style="width:54%" placeholder="请输入内容"></el-input>
              </div>
              <div class="namer">
                邮箱：
                <el-input v-model="input" style="width:54%" placeholder="请输入内容"></el-input>
              </div>
              <div class="namer">
                密码：
                <el-input v-model="input" style="width:54%" placeholder="请输入内容"></el-input>
              </div>
              <div class="namer">
                修改密码：
                <el-input v-model="input" style="width:54%" placeholder="请输入内容"></el-input>
              </div>
              <div class="namer">
                单位：
                <el-input v-model="input" style="width:54%" placeholder="请输入内容"></el-input>
              </div>
              <div class="namer">
                <el-button type="success">提交</el-button>
                <el-button type="success">取消</el-button>
              </div>
            </div>
          </el-dialog>
            <div class="block" style="padding:0.1rem;">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage1"
                :page-size="100"
                layout="total, prev, pager, next"
                :total="1000"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div>
        <Footer></Footer>
        <router-view></router-view>
    </div>-->
  </div>
</template>
<style  scoped>
.big {
  font-size: 0.1rem;
}
.top {
  background-color: #153c75;
  width: calc(100% - 0.4rem);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.2rem;
}
.namer {
  margin-top: 0.2rem;
  text-align: center;
}
.helper {
  font-size: 0.15rem;
  display: inline-block;
  color: #fff;
  margin-right: 0.1rem;
}
.main {
  width: 70%;
  margin: 0 auto;
  margin-top: 0.2rem;
}
.mainright {
  width: 70%;
  display: inline-block;
  border: 1px solid transparent;
  border-color: #ddd;
}
.clearfix {
  color: #444;
  background-color: #f5f5f5;
  border-color: #ddd;
  padding: 0.15rem;
}
.ampanel {
  font-size: 0.15rem;
  text-align: left;
}
.amtext {
  display: inline-block;
  float: right;
}
.amtext button {
  padding-left: 0.1rem;
  padding-top: 0.05rem;
  padding-bottom: 0.05rem;
  padding-right: 0.1rem;
  background-color: #77b723;
  border: #77b723;
  color: #fff;
  border-radius: 0.05rem;
  margin-left: 0.1rem;
}
.amform {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  font-size: 0.12rem;
  text-align: left;
}
.el-button--success {
  background-color: #77b723;
  border-color: #77b723;
}
.el-button--text {
  color: #77b723;
}
.el-pagination {
  white-space: normal;
}
[class*="el-col-"] {
  float: unset;
  box-sizing: border-box;
}
@media only screen and (max-width: 1100px) {
  .main {
    width: 90%;
    margin: 0 auto;
    margin-top: 0.2rem;
  }
  .main-left {
    width: 100% !important;
    margin-bottom: 0.2rem !important;
  }
  .mainright {
    width: 100% !important;
  }
  .el-input {
    width: 50% !important;
  }
}
.dp {
  display: inline-block;
  font-size: 0.15rem;
  float: left;
  margin-top: 0.04rem;
}
.ico {
  float: right;
}

.sou {
  width: 73%;
  display: inline-block;
}
.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 25%;
  margin-right: 0.1rem;
}
.nei {
  float: left;
}
.neirong {
  width: 70%;
  display: inline-block;
}
.fenye {
  margin-left: 1.9rem;
}
</style>
<script>
import Header from "@/components/BackHead";
import Left from "@/components/BackLeft";
// import Footer from "@/components/Footer";
export default {
  components: {
    Header,
    Left
    // Footer
  },
  data() {
    return {
      input: "",
      dialogFormVisible: false,
      dialogFormVisible1: false,
      currentPage1: 4,
      currentPage3: 2,
      dialogWidth: 0,
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: ""
      },

      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1518 弄",
          zip: 200333
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1517 弄",
          zip: 200333
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1519 弄",
          zip: 200333
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1516 弄",
          zip: 200333
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1516 弄",
          zip: 200333
        }
      ],
      options: [
        {
          value: "选项1",
          label: "男"
        },
        {
          value: "选项2",
          label: "女"
        }
      ],
      value: ""
    };
  },
  created() {
    this.setDialogWidth();
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.setDialogWidth();
      })();
    };
  },
  methods: {
    //新增
    creatr() {
      this.dialogFormVisible = true;
    },
    setDialogWidth() {
      var val = document.body.clientWidth;
      const def = 450; // 默认宽度
      if (val < def) {
        this.dialogWidth = "80%";
      } else {
        this.dialogWidth = def + "px";
      }
    },
    handleOpen(key, keyPath) {
    },
    handleClose1(key, keyPath) {
    },
    onSubmit() {
      this.dialogVisible = false;
    },
    handleClick() {
      this.dialogFormVisible1 = true;
    },
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
    }
  }
};
</script>


