<template>
  <div class="big">
    <!-- 引入公共头部 -->
    <div>
      <ClassHead></ClassHead>
      <router-view></router-view>
    </div>
    <!-- 轮播部分 -->
    <div class="dalunbo">
      <div class="liebiao">
        <div class="nue">
          <router-link to="/Alliance/Home">
            <div class="wne">
              <span>首页</span>
            </div>
            </router-link>
          <router-link to="/Alliance/supermarket">
            <div class="wne">
              <span>课程超市</span>
            </div>
          </router-link>

          <router-link to="/Alliance/school">
            <div class="wne">
              <span>学校</span>
            </div>
          </router-link>
          <router-link to="/Alliance/SchoolCloud">
            <div class="wne">
              <span>学校云</span>
            </div>
          </router-link>
          <div class="wne">
            <span>公共服务平台</span>
          </div>
        </div>
      </div>
      <div class="lunbo">
        <div class="block">
          <el-carousel height="380px">
            <el-carousel-item v-for="item in 4" :key="item">
              <h3 class="small">{{ item }}</h3>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>




    <div class="centr">
      <div style="padding: 0.9%"></div>
      <div>
        <div class="toubu">
          <div class="gn">
            <span>计算机</span>
          </div>
          <div class="gn">
            <span>经济管理</span>
          </div>
          <div class="gn">
            <span>考研</span>
          </div>
          <div class="gn">
            <span>心理学</span>
          </div>
          <div class="gn">
            <span>外语</span>
          </div>
          <div class="gn">
            <span>文史历史</span>
          </div>
          <div class="gn">
            <span>艺术设计</span>
          </div>
          <div class="gn">
            <span>工学</span>
          </div>
        </div>
        <div class="youhui">
          <div class="computer">
            <div class="calculator">计算机</div>
            <div style>
              <div class="technology">前沿技术</div>
              <div class="technologys">C语言</div>
              <div class="technology">前沿技术</div>
              <div class="technologys">C语言</div>
              <div class="technology">前沿技术</div>
              <div class="technologys">C语言</div>
            </div>
            <div class="calcul">优惠卷</div>
            <div>
              <div class="coupon">
                <p style>￥50</p>
                <p class="fe" style="font-size: 0.2rem; color: #c3c3c3">
                  50元优惠卷
                </p>
              </div>
              <div class="coupon">
                <p style>￥50</p>
                <p class="fe" style="font-size: 0.2rem; color: #c3c3c3">
                  50元优惠卷
                </p>
              </div>
            </div>
          </div>
          <div class="vouchers">
            <router-link to="/Alliance/detailPage">
              <div class="glorious">
                <img src="@/assets/zn.png" alt />
                <span class="om"
                  >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
                >
              </div>
            </router-link>

            <div class="gloriou">
              <img src="@/assets/zn.png" alt />
              <span class="om"
                >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
              >
            </div>
            <div class="gloriou">
              <img src="@/assets/zn.png" alt />
              <span class="om"
                >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
              >
            </div>
            <div class="glorious">
              <img src="@/assets/zn.png" alt />
              <span class="om"
                >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
              >
            </div>
            <div class="gloriou">
              <img src="@/assets/zn.png" alt />
              <span class="om"
                >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
              >
            </div>
            <div class="gloriou">
              <img src="@/assets/zn.png" alt />
              <span class="om"
                >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
              >
            </div>
          </div>
          <div class="Everyonesaid">
            <div class="gang">
              <span class="dj">大家说</span>
            </div>
            <div class="shuaxin">
              <i class="el-icon-refresh-right"></i>
              刷新
            </div>
            <div class="da">
              <div class="pnda">
                <div class="imga">
                  <img src="@/assets/gp.jpg" alt />
                </div>
                <div class="right">
                  <div class="mingcheng">是你啊！</div>
                  <div class="ping">
                    <el-rate
                      v-model="value"
                      disabled
                      show-score
                      text-color="#ff9900"
                      score-template="{value}"
                    ></el-rate>
                  </div>
                </div>
              </div>
              <div>
                <span class="ina"
                  >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
                >
              </div>
              <div class="mc">
                <span class="kc">课程:C语言--课程名称课程名称</span>
              </div>
            </div>

            <div class="da">
              <div class="pnda">
                <div class="imga">
                  <img src="@/assets/gp.jpg" alt />
                </div>
                <div class="right">
                  <div class="mingcheng">是你啊！</div>
                  <div class="ping">
                    <el-rate
                      v-model="value"
                      disabled
                      show-score
                      text-color="#ff9900"
                      score-template="{value}"
                    ></el-rate>
                  </div>
                </div>
              </div>
              <div>
                <span class="ina"
                  >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
                >
              </div>
              <div class="mc">
                <span class="kc">课程:C语言--课程名称课程名称</span>
              </div>
            </div>
            <div class="da">
              <div class="pnda">
                <div class="imga">
                  <img src="@/assets/gp.jpg" alt />
                </div>
                <div class="right">
                  <div class="mingcheng">是你啊！</div>
                  <div class="ping">
                    <el-rate
                      v-model="value"
                      disabled
                      show-score
                      text-color="#ff9900"
                      score-template="{value}"
                    ></el-rate>
                  </div>
                </div>
              </div>
              <div>
                <span class="ina"
                  >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
                >
              </div>
              <div class="mc">
                <span class="kc">课程:C语言--课程名称课程名称</span>
              </div>
            </div>
          </div>
        </div>
        <div class="youhui" style="margin-top: 2%">
          <div class="computer">
            <div class="calculator">计算机</div>
            <div style>
              <div class="technology">前沿技术</div>
              <div class="technologys">C语言</div>
              <div class="technology">前沿技术</div>
              <div class="technologys">C语言</div>
              <div class="technology">前沿技术</div>
              <div class="technologys">C语言</div>
            </div>
            <div class="calcul">优惠卷</div>
            <div>
              <div class="coupon">
                <p style>￥50</p>
                <p class="fe" style="font-size: 0.2rem; color: #c3c3c3">
                  50元优惠卷
                </p>
              </div>
              <div class="coupon">
                <p style>￥50</p>
                <p class="fe" style="font-size: 0.2rem; color: #c3c3c3">
                  50元优惠卷
                </p>
              </div>
            </div>
          </div>
          <div class="vouchers">
            <div class="glorious">
              <img src="@/assets/zn.png" alt />
              <span class="om"
                >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
              >
            </div>
            <div class="gloriou">
              <img src="@/assets/zn.png" alt />
              <span class="om"
                >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
              >
            </div>
            <div class="gloriou">
              <img src="@/assets/zn.png" alt />
              <span class="om"
                >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
              >
            </div>
            <div class="glorious">
              <img src="@/assets/zn.png" alt />
              <span class="om"
                >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
              >
            </div>
            <div class="gloriou">
              <img src="@/assets/zn.png" alt />
              <span class="om"
                >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
              >
            </div>
            <div class="glorious">
              <img src="@/assets/zn.png" alt />
              <span class="om"
                >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
              >
            </div>
          </div>
          <div class="Everyonesaid">
            <div class="gang">
              <span class="dj">大家说</span>
            </div>
            <div class="shuaxin">
              <i class="el-icon-refresh-right"></i>
              刷新
            </div>
            <div class="da">
              <div class="pnda">
                <div class="imga">
                  <img src="@/assets/gp.jpg" alt />
                </div>
                <div class="right">
                  <div class="mingcheng">是你啊！</div>
                  <div class="ping">
                    <el-rate
                      v-model="value"
                      disabled
                      show-score
                      text-color="#ff9900"
                      score-template="{value}"
                    ></el-rate>
                  </div>
                </div>
              </div>
              <div>
                <span class="ina"
                  >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
                >
              </div>
              <div class="mc">
                <span class="kc">课程:C语言--课程名称课程名称</span>
              </div>
            </div>

            <div class="da">
              <div class="pnda">
                <div class="imga">
                  <img src="@/assets/gp.jpg" alt />
                </div>
                <div class="right">
                  <div class="mingcheng">是你啊！</div>
                  <div class="ping">
                    <el-rate
                      v-model="value"
                      disabled
                      show-score
                      text-color="#ff9900"
                      score-template="{value}"
                    ></el-rate>
                  </div>
                </div>
              </div>
              <div>
                <span class="ina"
                  >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
                >
              </div>
              <div class="mc">
                <span class="kc">课程:C语言--课程名称课程名称</span>
              </div>
            </div>
            <div class="da">
              <div class="pnda">
                <div class="imga">
                  <img src="@/assets/gp.jpg" alt />
                </div>
                <div class="right">
                  <div class="mingcheng">是你啊！</div>
                  <div class="ping">
                    <el-rate
                      v-model="value"
                      disabled
                      show-score
                      text-color="#ff9900"
                      score-template="{value}"
                    ></el-rate>
                  </div>
                </div>
              </div>
              <div>
                <span class="ina"
                  >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
                >
              </div>
              <div class="mc">
                <span class="kc">课程:C语言--课程名称课程名称</span>
              </div>
            </div>
          </div>
        </div>
        <div class="youhui" style="margin-top: 2%">
          <div class="computer">
            <div class="calculator">计算机</div>
            <div style>
              <div class="technology">前沿技术</div>
              <div class="technologys">C语言</div>
              <div class="technology">前沿技术</div>
              <div class="technologys">C语言</div>
              <div class="technology">前沿技术</div>
              <div class="technologys">C语言</div>
            </div>
            <div class="calcul">优惠卷</div>
            <div>
              <div class="coupon">
                <p style>￥50</p>
                <p class="fe" style="font-size: 0.2rem; color: #c3c3c3">
                  50元优惠卷
                </p>
              </div>
              <div class="coupon">
                <p style>￥50</p>
                <p class="fe" style="font-size: 0.2rem; color: #c3c3c3">
                  50元优惠卷
                </p>
              </div>
            </div>
          </div>
          <div class="vouchers">
            <div class="glorious">
              <img src="@/assets/zn.png" alt />
              <span class="om"
                >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
              >
            </div>
            <div class="gloriou">
              <img src="@/assets/zn.png" alt />
              <span class="om"
                >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
              >
            </div>
            <div class="gloriou">
              <img src="@/assets/zn.png" alt />
              <span class="om"
                >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
              >
            </div>
            <div class="glorious">
              <img src="@/assets/zn.png" alt />
              <span class="om"
                >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
              >
            </div>
            <div class="gloriou">
              <img src="@/assets/zn.png" alt />
              <span class="om"
                >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
              >
            </div>
            <div class="gloriou">
              <img src="@/assets/zn.png" alt />
              <span class="om"
                >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
              >
            </div>
          </div>
          <div class="Everyonesaid">
            <div class="gang">
              <span class="dj">大家说</span>
            </div>
            <div class="shuaxin">
              <i class="el-icon-refresh-right"></i>
              刷新
            </div>
            <div class="da">
              <div class="pnda">
                <div class="imga">
                  <img src="@/assets/gp.jpg" alt />
                </div>
                <div class="right">
                  <div class="mingcheng">是你啊！</div>
                  <div class="ping">
                    <el-rate
                      v-model="value"
                      disabled
                      show-score
                      text-color="#ff9900"
                      score-template="{value}"
                    ></el-rate>
                  </div>
                </div>
              </div>
              <div>
                <span class="ina"
                  >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
                >
              </div>
              <div class="mc">
                <span class="kc">课程:C语言--课程名称课程名称</span>
              </div>
            </div>

            <div class="da">
              <div class="pnda">
                <div class="imga">
                  <img src="@/assets/gp.jpg" alt />
                </div>
                <div class="right">
                  <div class="mingcheng">是你啊！</div>
                  <div class="ping">
                    <el-rate
                      v-model="value"
                      disabled
                      show-score
                      text-color="#ff9900"
                      score-template="{value}"
                    ></el-rate>
                  </div>
                </div>
              </div>
              <div>
                <span class="ina"
                  >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
                >
              </div>
              <div class="mc">
                <span class="kc">课程:C语言--课程名称课程名称</span>
              </div>
            </div>
            <div class="da">
              <div class="pnda">
                <div class="imga">
                  <img src="@/assets/gp.jpg" alt />
                </div>
                <div class="right">
                  <div class="mingcheng">是你啊！</div>
                  <div class="ping">
                    <el-rate
                      v-model="value"
                      disabled
                      show-score
                      text-color="#ff9900"
                      score-template="{value}"
                    ></el-rate>
                  </div>
                </div>
              </div>
              <div>
                <span class="ina"
                  >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
                >
              </div>
              <div class="mc">
                <span class="kc">课程:C语言--课程名称课程名称</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 合作高校 -->
        <div class="gx">
          <div class="biao">合作高校</div>
          <div class="tu">
            <div class="glorious1">
              <img src="@/assets/zn.png" alt />
              <span class="om"
                >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
              >
            </div>
            <div class="glorious1">
              <img src="@/assets/zn.png" alt />
              <span class="om"
                >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
              >
            </div>
            <div class="glorious1">
              <img src="@/assets/zn.png" alt />
              <span class="om"
                >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
              >
            </div>
            <div class="glorious1">
              <img src="@/assets/zn.png" alt />
              <span class="om"
                >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
              >
            </div>
            <div class="glorious1">
              <img src="@/assets/zn.png" alt />
              <span class="om"
                >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
              >
            </div>
            <div class="glorious1">
              <img src="@/assets/zn.png" alt />
              <span class="om"
                >25日晚，刘雨昕和虞书欣现身李佳琦直播间，直播过程中李佳琦把虞书欣叫成刘雨昕，虞书欣灵机一动回答：“没关系，许佳琪”，机智又可爱。</span
              >
            </div>
          </div>
        </div>
        <div>
          <Commonbottom></Commonbottom>
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<style  scoped>
.liebiao {
  background-color: skyblue;
  width: 100%;
}
.nue {
  margin: 0 auto;
  padding: 0.8rem;
}
.wne {
  display: inline-block;
  margin-right: 8rem;
  font-size: 0.9rem;
  color: #fff;
  font-size: 1rem;
  text-align: left;
  cursor: pointer;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 380px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.centr {
  background-color: #f8f8f8;
}
.gn {
  display: inline-block;
  font-size: 1.2rem;
  margin-right: 5.5rem;
}
.toubu {
  padding: 1rem;
  width: 70%;
  margin: 0 auto;
  background-color: #ffffff;
  border-bottom: 0.1rem solid #8e8fb2;
}
.youhui {
  /* background-color: #f8f8f8; */
  padding: 2rem 1rem;
  width: 70%;
  margin: 0 auto;
  background-color: #ffffff;
  display: inline-block;
}
.computer {
  padding: 1.5%;
  width: 21%;
  display: inline-block;
  background-image: linear-gradient(to bottom right, #f16c62, #feca93);
  float: left;
}
.calculator {
  font-size: 1.3rem;
  color: #fff;
}
.calcul {
  font-size: 1.3rem;
  color: #fff;
  margin-top: 2rem;
}
.technology {
  display: inline-block;
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
  width: 24%;
  background-color: #e1856b;
  color: #fff;
  margin-top: 1rem;
  border-radius: 0.3rem;
}
.technologys {
  display: inline-block;
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
  width: 22%;
  background-color: #e1856b;
  margin-left: 1rem;
  color: #fff;
  margin-top: 1rem;
  border-radius: 0.3rem;
}
.coupon {
  background-image: url("../../assets/zu6.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1.4rem;
  font-size: 1.5rem;
  color: #e55044;
  text-align: left;
  margin-top: 1.2rem;
}
.vouchers {
  width: 50%;
  /* padding: 3rem; */
  /* background-color: skyblue; */
  display: inline-block;
  margin-left: 1.5rem;
}
.Everyonesaid {
  width: 18%;
  padding: 1rem;
  /* background-color: yellow; */
  display: inline-block;
  /* margin-left: 1.5rem; */
  float: right;
}
.glorious {
  width: 28%;
  /* background-color: red; */
  /* padding: 2rem; */
  display: inline-block;
  margin-top: 3rem;
}
.glorious img {
  width: 100%;
}
.glorious1 {
  width: 15%;
  /* background-color: red; */
  /* padding: 2rem; */
  display: inline-block;
  /* margin-top: 3rem; */
  margin-right: 1rem;
}
.glorious1 img {
  width: 100%;
}
.glorious2 {
  width: 20%;
  /* background-color: red; */
  /* padding: 2rem; */
  display: inline-block;
  margin-top: 3rem;
}
.glorious2 img {
  width: 100%;
}
.gloriou {
  width: 28%;
  /* background-color: red; */
  padding: 1rem;
  display: inline-block;
  margin-left: 1rem;
  margin-top: 1rem;
}
.gloriou img {
  width: 100%;
}
.om {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 0.5rem;
}
.ina {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 0.5rem;
  color: #859fc3;
  font-size: 0.9rem;
}
.gang {
  display: inline-block;
  color: #bbb1b1;
  font-size: 1.1rem;
  /* text-align: left; */
  float: left;
  border-left: 0.3rem solid #212fe8;
  padding-left: 0.7rem;
}
.jd {
  color: #333;
}
.shuaxin {
  border: 0.1rem solid #212fe8;
  border-radius: 1.1rem;
  display: inline-block;
  padding-left: rem;
  padding: 0rem 0.8rem;
  float: right;
}
.da {
  display: inline-block;
  margin-top: 2rem;
}
.pnda {
  display: inline-block;
}
.imga {
  display: inline-block;
  width: 20%;
  float: left;
}
.imga img {
  width: 100%;
  border-radius: 2.5rem;
  float: left;
}
.mingcheng {
  margin-top: 0.2rem;
  text-align: left;
}
.ping {
  text-align: left;
}
.mc {
  display: inline-block;
  float: left;
  margin-left: 0.2rem;
}
.kc {
  font-size: 0.5rem;
  color: #bfbfbf;
  text-align: left;
}
.right {
  display: inline-block;
  width: 65%;
}
.gx {
  margin: 0 auto;
  background-color: #fdfcf5;
  width: 70%;
  padding: 2rem 1rem;
  margin-top: 2rem;
  position: relative;
  margin-bottom: 2rem;
}
.biao {
  /* width: 25%; */
  /* display: inline-block; */
  /* float: left; */
  font-size: 1.5rem;
  /* font-weight: 600; */
  /* padding-top: 6rem; */
}
.tu {
  display: inline-block;
  /* width: 75%; */
  /* text-align: left; */
  margin-top: 1rem;
}
.di {
  /* margin: 2rem; */
  border-bottom: 0.1rem solid blue;
}
.ac {
  padding: 1.5rem;
}
@media screen and (min-width: 300px) and (max-width: 767px) {
  .youhui {
    width: 91% !important;
  }
  .wne {
    margin-right: 0.9rem !important;
  }
  .dalunbo {
    margin-top: 0.5rem !important;
  }
  .gn {
    font-size: 1rem !important;
    margin-right: 0.5rem !important;
  }
  .computer {
    width: 100% !important;
  }
  .technology {
    width: 25% !important;
  }
  .coupon {
    padding: 1.2rem !important;
  }
  .vouchers {
    width: 100% !important;
    margin-left: 0rem !important;
  }
  .glorious {
    width: 48% !important;
    margin-right: 0.4rem !important;
  }
  .gloriou {
    width: 48% !important;
    padding: 0rem !important;
    margin: 0 auto !important;
  }
  .Everyonesaid {
    width: 88% !important;
  }
  .glorious1 {
    width: 42% !important;
    margin-right: 0.4rem !important;
  }
  .coupon {
    padding: 2.2rem !important;
  }
  .tu {
    width: 100% !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .gn {
    margin-right: 0.5rem !important;
  }
  .wne {
    margin-right: 3rem !important;
  }
  .toubu {
    width: 85% !important;
  }
  .youhui {
    width: 85% !important;
  }
  .vouchers {
    width: 53% !important;
    margin-left: 0rem !important;
  }
  .gloriou {
    padding: 0rem !important;
  }
  .glorious[data-v-7c34008d] {
    margin-top: 6rem !important;
  }
  .fe {
    margin-left: 0.3rem !important;
  }
  .coupon {
    padding: 0rem !important;
  }
  .technologys[data-v-7c34008d] {
    margin-left: 0rem !important;
  }
}
</style>
<script>
import ClassHead from "@/components/ClassHead";
import Commonbottom from "@/components/Commonbottom";
export default {
  components: { ClassHead, Commonbottom },
  computed: {},
  data() {
    return {
      value: 5,
    };
  },
  methods: {},
};
</script>