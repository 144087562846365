<template>
  <div>
    <!--引入头部-->
    <div>
      <Header></Header>
      <router-view></router-view>
    </div>
    <!--内容-->
    <div class="contrain">
      <div class="contrainr">
        <div class="tcleft">
          <div class="title">
            <h2>
              <span>您的位置：首页>留言反馈></span>
            </h2>
            <div class="feedback">
                <label> 留言反馈：</label>
                <el-input type="textarea" :rows="2" placeholder="请输入反馈内容" v-model="textarea"></el-input>
            </div>
             <div class="feedback">
                <label>你的姓名：</label>
              <el-input v-model="input" placeholder="请输入姓名"></el-input>
            </div>
             <div class="feedback">
                <label>联系电话：</label>
              <el-input v-model="input" placeholder="请输入联系电话"></el-input>
            </div>
             <div class="feedback">
                <label>联系邮箱：</label>
              <el-input v-model="input" placeholder="请输入联系邮箱"></el-input>
            </div>
             <!-- <div class="form-group" style="display: flex;">
                    <div>
                        <span>验证码：</span>
                        <input type="text" id="code" v-model="code" class="code"  placeholder="请输入您的验证码" />
                    </div>
                  <div class="login-code" @click="refreshCode"> -->
            <!--验证码组件-->
            <!-- <s-identify :identifyCode="identifyCode"></s-identify>
            </div>
                </div> -->
                <div class="feedback">
                     <el-button type="primary">提交</el-button>
                </div>
        </div>
        </div>
      </div>
      <div>
        <Footer></Footer>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<style scoped>
.contrain {
  background-color: #efefef;
}
.contrainr {
  width: 90%;
  margin: 0 auto;
}
.tcleft {
  width: 64%;
  background-color: #fff;
  display: inline-block;
  margin-top: 0.2rem;
  margin-bottom: 0.4rem;
}
.title h2 {
  border-bottom: 1px solid #1697eb;
  color: #505050;
  font-size: 0.1rem;
  line-height: 39px;
  text-align: left;
}
.title ul {
  list-style: none;
  padding: 0.1rem;
}
.title h2 span {
  color: #505050;
  font-size: 0.15rem;
  text-align: left;
  background-color: #1697eb;
  display: inline-block;
  color: #fff;
  padding: 0.08rem;
}
.title .more {
  text-decoration: none;
  font-size: 0.14rem;
  color: #999;
  margin-right: 0.1rem;
  margin-top: 0.1rem;
}
.title ul li {
  font-size: 0.3rem;
  text-align: left;
  line-height: 36px;
}
.title .nower {
  text-decoration: none;
  font-size: 0.14rem;
  color: #909090;
}
.title .dater {
  float: right;
  color: #909090;
}
.tcright {
  width: 30%;
  background-color: #fff;
  display: inline-block;
  margin-top: 0.2rem;
  margin-bottom: 0.4rem;
}
@media only screen and (max-width: 800px) {
  .tcright {
    width: 100% !important;
    float: none !important;
  }
  .tcleft {
    width: 100% !important;
  }
}
.feedback{
    font-weight: 500;
    font-size: 0.15rem;
    padding: 0.1rem;
    text-align: left;
}
.el-textarea{
    width: 50%;
}
.el-input{
    width: 50%;
}
 .s-canvas {
     /* height: 38px; */
    
 }
 .s-canvas canvas{
     margin-top: 1px;
    margin-left: 8px; }
     /*验证码样式*/
 .code{
     width:124px;
     height:31px;
     border:1px solid rgba(186,186,186,1);
 }
 .login-code{
      cursor: pointer;
 }
</style>
<script>
import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
// import SIdentify  from '../../components/sidentify'
export default {
  components: { Header, Footer },
//    name: 'SIdentify',
  data() {
    return {
        textarea: '',
        input:'',
        // identifyCodes: "1234567890",
        // identifyCode: "",
        // code:"",//text框输入的验证码
    };
  },
  created() {
    //   this.refreshCode()
  },
//    mounted(){
//      this.identifyCode = "";
//      this.makeCode(this.identifyCodes, 4);
//  },
 methods: {
      //验证码
//  randomNum(min, max) {
//      return Math.floor(Math.random() * (max - min) + min);
//  },
       
//  refreshCode() {
//      this.identifyCode = "";
//      this.makeCode(this.identifyCodes, 4);
//  },
//  makeCode(o, l) {
//      for (let i = 0; i < l; i++) {
//          this.identifyCode += this.identifyCodes[
//            this.randomNum(0, this.identifyCodes.length)
//          ];
//      }
//  },
     },
};
</script>