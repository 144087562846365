<template>
  <div class="ingh">
    <div>
      <ClassHead></ClassHead>
      <router-view></router-view>
    </div>
    <div class="dalunbo">
      <!-- 课程导航部分 -->
      <div class="liebiao">
        <div class="nue">
          <router-link to="/Alliance/home">
            <div class="wne">
              <span>首页</span>
            </div>
          </router-link>
          <router-link to="/Alliance/supermarket">
            <div class="wne">
              <span>课程超市</span>
            </div>
          </router-link>
          <router-link to="/Alliance/school">
            <div class="wne">
              <span>学校</span>
            </div>
          </router-link>
          <router-link to="/Alliance/SchoolCloud">
            <div class="wne">
              <span>学校云</span>
            </div>
          </router-link>
          <div class="wne">
            <span>公共服务平台</span>
          </div>
        </div>
      </div>
    </div>
    <div style="background-color: #efefef;">
      <div class="cent">
        <!-- 左边盒子 -->
        <div class="zuo">
          <div class="qv">
            <span class="quan">全部</span>
            <span>学历教育课程</span>
            <span class="pex">培训课程</span>
          </div>
          <div class="two">
            <div class="gj">已选</div>
            <div class="fj2">学历教育课程</div>
          </div>
          <div>
            <div class="gj">分类</div>
            <div class="fj1">
              <span class="fj">全部</span>
              <span class="kc">外语</span>
              <span class="kc">外语</span>
              <span class="kc">外语</span>
              <span class="kc">外语</span>

              <span class="kc">外语</span>
              <span class="kc">外语</span>
              <span class="kc">外语</span>
            </div>
          </div>
          <div class="zt">
            <div class="gj">状态</div>
            <div class="fj1">
              <span class="fj">全部</span>
              <span class="kc">直播</span>
              <span class="kc">录播</span>
            </div>
          </div>

          <div class="zt">
            <div class="gj">属性</div>
            <div class="fj1">
              <span class="fj">全部</span>
              <span class="kc">付费</span>
              <span class="kc">免费</span>
            </div>
          </div>
        </div>
        <div class="you">
          <div class="top">
            <div class="za">
              <span class="qw">人气排行</span>
              <span class="qw">总收藏</span>
              <span class="qw">播放次数</span>
            </div>
            <div class="ruv">
              <span class="zim">共1024门相关课程</span>
              <span class="get">|</span>
              <i class="el-icon-menu"></i>
              <span class="get1">|</span>
              <i class="el-icon-s-unfold"></i>
            </div>
          </div>
          <div class="content">
            <div class="vouchers">
              <div class="glorious">
                <img src="@/assets/zn.png" alt />
                <span class="om">教师情绪管理</span>
                <div class="ming">浙江大学</div>
                <div class="ming">叶映华</div>
                <div>
                  <div class="ren">
                    <i class="el-icon-user-solid"></i> 3175
                  </div>
                  <div class="jin">
                    <i class="el-icon-watch"></i> 课程已进行到第3周
                  </div>
                </div>
              </div>
              <div class="gloriou">
                <img src="@/assets/zn.png" alt />
                <span class="om">教师情绪管理</span>
                <div class="ming">浙江大学</div>
                <div class="ming">叶映华</div>
                <div>
                  <div class="ren">
                    <i class="el-icon-user-solid"></i> 3175
                  </div>
                  <div class="jin">
                    <i class="el-icon-watch"></i> 课程已进行到第3周
                  </div>
                </div>
              </div>
              <div class="gloriou">
                <img src="@/assets/zn.png" alt />
                <span class="om">教师情绪管理</span>
                <div class="ming">浙江大学</div>
                <div class="ming">叶映华</div>
                <div>
                  <div class="ren">
                    <i class="el-icon-user-solid"></i> 3175
                  </div>
                  <div class="jin">
                    <i class="el-icon-watch"></i> 课程已进行到第3周
                  </div>
                </div>
              </div>
              <div class="glorious">
                <img src="@/assets/zn.png" alt />
                <span class="om">教师情绪管理</span>
                <div class="ming">浙江大学</div>
                <div class="ming">叶映华</div>
                <div>
                  <div class="ren">
                    <i class="el-icon-user-solid"></i> 3175
                  </div>
                  <div class="jin">
                    <i class="el-icon-watch"></i> 课程已进行到第3周
                  </div>
                </div>
              </div>
              <div class="gloriou">
                <img src="@/assets/zn.png" alt />
                <span class="om">教师情绪管理</span>
                <div class="ming">浙江大学</div>
                <div class="ming">叶映华</div>
                <div>
                  <div class="ren">
                    <i class="el-icon-user-solid"></i> 3175
                  </div>
                  <div class="jin">
                    <i class="el-icon-watch"></i> 课程已进行到第3周
                  </div>
                </div>
              </div>
              <div class="gloriou">
                <img src="@/assets/zn.png" alt />
                <span class="om">教师情绪管理</span>
                <div class="ming">浙江大学</div>
                <div class="ming">叶映华</div>
                <div>
                  <div class="ren">
                    <i class="el-icon-user-solid"></i> 3175
                  </div>
                  <div class="jin">
                    <i class="el-icon-watch"></i> 课程已进行到第3周
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 引入公共底部 -->
    <div style="background-color: rgb(239, 239, 239);">
      <Commonbottom></Commonbottom>
      <router-view></router-view>
    </div>
  </div>
</template>
<style  scoped>
.liebiao {
  background-color: skyblue;
  width: 100%;
}
.nue {
  margin: 0 auto;
  padding: 0.8rem;
}

.wne {
  display: inline-block;
  margin-right: 8rem;
  font-size: 0.9rem;
  color: #fff;
  font-size: 1rem;
  text-align: left;
  cursor: pointer;
}
.cent {
  width: 65%;
  margin: 0 auto;
  /* margin-top: 1rem; */
  padding-top: 1rem;
  display: inline-block;
  margin-bottom: 1.5rem;
}
.zuo {
  background-color: #fff;
  background-size: cover;
  width: 24%;
  margin-right: 0.5rem;
  background-repeat: no-repeat;
  display: inline-block;
  border-radius: 0.5rem;
  padding: 0.5rem 0;
}
.quan {
  font-size: 1rem;
  font-weight: 800;
  float: left;
}
.qv {
  border-bottom: 0.05rem solid #efefef;
  width: 94%;
  margin: 0 auto;
  padding-bottom: 0.3rem;
}
.you {
  width: 75%;
  display: inline-block;
  float: right;
}
.top {
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0.5rem;
  border-radius: 0.5rem;
  text-align: left;
}
.za {
  display: inline-block;
  /* float: left; */
}
.pex {
  float: right;
}
.qw {
  margin-right: 1.5rem;
}
.ruv {
  display: inline-block;
  float: right;
}
.zim {
  /* float: right; */
  margin-right: 0.5rem;
}
.get {
  margin-right: 0.5rem;
}
.get1 {
  margin-right: 1rem;
  margin-left: 0.5rem;
}
.two {
  /* margin-top: 0.5rem; */
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 0.01rem solid #efefef;
}
.gj {
  text-align: left;
  margin-left: 0.5rem;
  font-size: 1rem;
  font-weight: 800;
}
.fj1 {
  text-align: left;
  margin-left: 0.5rem;
  border-bottom: 0.01rem solid #efefef;
  padding-bottom: 0.5em;
}
.fj {
  color: skyblue;
  text-align: left;
  /* margin-left: 0.5rem; */
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
}
.fj2 {
  color: skyblue;
  text-align: left;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
}
.kc {
  margin-right: 1rem;
}
.zt {
  margin-top: 0.5rem;
}
.content {
  background-color: #fff;
}
.vouchers {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.glorious {
  width: 28%;
  display: inline-block;
  margin-top: 3rem;
  border: 0.1rem solid;
  border-radius: 12%;
  text-align: left;
}
.glorious img {
  width: 100%;
  border-top-left-radius: 19%;
  border-top-right-radius: 19%;
}
.gloriou {
  width: 28%;
  display: inline-block;
  margin-left: 1rem;
  margin-top: 1rem;
  border: 0.1rem solid;
  border-radius: 11%;
  text-align: left;
}
.gloriou img {
  width: 100%;

  border-top-left-radius: 19%;
  border-top-right-radius: 19%;
}
.om {
  font-size: 1rem;
  font-weight: 900;
  margin-left: 1.5rem;
}
.ming {
  margin-left: 1.5rem;
  font-size: 1rem;
  font-weight: 500;
}
.ren {
  display: inline-block;
  float: left;
  font-size: 0.4rem;
  margin-left: 1.5rem;
  margin-top: 0.4rem;
  margin-bottom: 0.2rem;
}
.jin {
  display: inline-block;
  float: right;
  font-size: 0.4rem;
  margin-right: 1.1rem;
  margin-top: 0.4rem;
  margin-bottom: 0.2rem;
}
@media screen and (min-width: 300px) and (max-width: 767px) {
  .wne {
    margin-right: 1rem !important;
  }
  .zuo {
    width: 100% !important;
    margin-right: 0rem !important;
  }
  .cent {
    width: 85% !important;
  }
  .you {
    width: 100% !important;
    float: none !important;
    margin-top: 0.4rem !important;
  }
  .get1 {
    margin-right: 0.5rem !important;
  }
  .glorious {
    width: 70% !important;
    margin-top: 1rem !important;
  }
  .gloriou {
    width: 70% !important;
    margin-left: 0rem !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .you {
    width: 70% !important;
  }
  .zuo {
    width: 28% !important;
  }
  .cent {
    width: 98% !important;
  }
}
</style>
<script>
import ClassHead from "@/components/ClassHead";
import Commonbottom from "@/components/Commonbottom";
export default {
  components: { ClassHead, Commonbottom },
  computed: {},
  data() {
    return {
      value: 5
    };
  },
  methods: {}
};
</script>