<template>
  <div>
    <!-- 引入头部 -->
    <div>
      <ClassHead></ClassHead>
      <router-view></router-view>
    </div>
    <!-- 导航部分 -->
    <div class="dalunbo">
      <!-- 课程导航部分 -->
      <div class="liebiao">
        <div class="nue">
          <div class="wne">
            <span>首页</span>
          </div>
          <router-link to="/Alliance/supermarket">
            <div class="wne">
              <span>课程超市</span>
            </div>
          </router-link>
          <router-link to="/Alliance/school">
            <div class="wne">
              <span>学校</span>
            </div>
          </router-link>
          <router-link to="/Alliance/SchoolCloud">
            <div class="wne">
              <span>学校云</span>
            </div>
          </router-link>
          <div class="wne">
            <span>公共服务平台</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 展示背景图、个人头像 -->
    <div class="contas">
      <img src="@/assets/grn.png" alt style="width:100%;height: 22rem;" />
      <div>
        <img
          src="@/assets/qqq.jpeg"
          alt
          style="position: absolute;left: 49%;top: 5%;"
          class="touxiang"
        />
        <div class="mingcheng">昵称123</div>
        <div class="ida">ID:11223344</div>
        <div class="an">
          <el-button type="danger" round>去认证</el-button>
        </div>
        <div class="zz">
          <div>2</div>
          <div>正在学习课程</div>
        </div>
        <div class="lj">
          <div>2</div>
          <div>累计学习课程</div>
        </div>
        <div class="fb">
          <div>0</div>
          <div>发布的讨论</div>
        </div>
      </div>
    </div>

    <!-- 课程内容 -->
    <div class="mas">
      <div class="minzdf" style="padding:1rem;width:75%;margin:0 auto;display: inline-block;">
        <!-- 左侧展示 -->
        <div class="kcleft">
          <ul>
            <router-link to="/Alliance/curriculum">
              <li>
                <i class="el-icon-collection" style="color:skyblue;margin-right:0.3rem"></i>我的课程
              </li>
            </router-link>

            <li>
              <i class="el-icon-office-building" style="color:skyblue;margin-right:0.3rem"></i>我的直播
            </li>
            <li>
              <i class="el-icon-document-copy" style="color:skyblue;margin-right:0.3rem"></i>我的讨论
            </li>
            <li>
              <i class="el-icon-pie-chart" style="color:skyblue;margin-right:0.3rem"></i>购买记录
            </li>
            <router-link to="/Alliance/coupon">
              <li>
                <i class="el-icon-paperclip" style="color:skyblue;margin-right:0.3rem"></i>优惠券
              </li>
            </router-link>
            <li>
              <i class="el-icon-collection" style="color:skyblue;margin-right:0.3rem"></i>我的证书
            </li>
            <li class="tubi">
              <i class="el-icon-collection" style="color:skyblue;margin-right:0.3rem"></i>信息设置
            </li>
          </ul>
        </div>
        <!-- 右侧展示 -->
        <div class="xqright">
          <div class="tb">
            <div class="yh">优惠劵</div>
            <div class="di"></div>
          </div>
          <div class="cxk">
            <div class="coupon">
              <p style>￥2888</p>
              <p class="fe" style="font-size:0.2rem;color:#c3c3c3">新手专享特权体验金</p>
              <p class="fe" style="font-size:0.2rem;color:#c3c3c3">2020.6.18过期</p>
            </div>
            <div class="coupon">
              <p style>￥2888</p>
              <p class="fe" style="font-size:0.2rem;color:#c3c3c3">新手专享特权体验金</p>
              <p class="fe" style="font-size:0.2rem;color:#c3c3c3">2020.6.18过期</p>
            </div>
            <div class="coupon">
              <p style>￥2888</p>
              <p class="fe" style="font-size:0.2rem;color:#c3c3c3">新手专享特权体验金</p>
              <p class="fe" style="font-size:0.2rem;color:#c3c3c3">2020.6.18过期</p>
            </div>
            <div class="coupon">
              <p style>￥2888</p>
              <p class="fe" style="font-size:0.2rem;color:#c3c3c3">新手专享特权体验金</p>
              <p class="fe" style="font-size:0.2rem;color:#c3c3c3">2020.6.18过期</p>
            </div>
            <div class="coupon">
              <p style>￥2888</p>
              <p class="fe" style="font-size:0.2rem;color:#c3c3c3">新手专享特权体验金</p>
              <p class="fe" style="font-size:0.2rem;color:#c3c3c3">2020.6.18过期</p>
            </div>
            <div class="coupon">
              <p style>￥2888</p>
              <p class="fe" style="font-size:0.2rem;color:#c3c3c3">新手专享特权体验金</p>
              <p class="fe" style="font-size:0.2rem;color:#c3c3c3">2020.6.18过期</p>
            </div>
          </div>
          <div class="xiabu">
            <div class="shiyong">优惠券使用说明</div>
            <div class="zitid">请在券面所示有效期内使用，逾期失效</div>
            <div class="zitid">用券应满足券面所示使用范围、满减金额。通用券可全平台通用，课程/微专业券仅限购买指定课程或微专业时使用</div>
            <div class="zitid">如您的订单在用券后24小时内未最终支付，则订单失效，优惠券自动返还</div>
            <div class="zitid">如您的订单在用券后发生退款，优惠券不予以返还</div>
            <div class="zitid">一笔订单只能使用一张优惠券，优惠券无法叠加使用</div>
            <div class="zitid">在个别特殊促销活动中，优惠券无法与其他优惠券叠加使用，请以具体的活动规则为准</div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Commonbottom></Commonbottom>
      <router-view></router-view>
    </div>
  </div>
</template>
<style  scoped>
a {
  color: black;
}
a,
a:hover,
a:active,
a:visited,
a:link,
a:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  outline: none;
  background: none;
  text-decoration: none;
}
.contas {
  position: relative;
}
.liebiao {
  background-color: skyblue;
  width: 100%;
}
.nue {
  margin: 0 auto;
  padding: 0.8rem;
}
.wne {
  display: inline-block;
  margin-right: 8rem;
  font-size: 0.9rem;
  color: #fff;
  font-size: 1rem;
  text-align: left;
  cursor: pointer;
}
.touxiang {
  width: 6%;
  border-radius: 5rem;
}
.mingcheng {
  position: absolute;
  top: 40%;
  left: 50.5%;
  color: #fff;
}
.ida {
  position: absolute;
  top: 49.5%;
  left: 49.5%;
  color: #fff;
}
.an {
  position: absolute;
  top: 60%;
  left: 49.6%;
}
.zz {
  position: absolute;
  top: 82%;
  left: 34%;
  color: #fff;
}
.lj {
  position: absolute;
  top: 82%;
  left: 49.5%;
  color: #fff;
}
.fb {
  position: absolute;
  top: 82%;
  left: 66%;
  color: #fff;
}
.mas {
  background-color: #efefef;
}
.kcleft {
  background-color: #fff;
  padding: 1.5rem 3rem;
  width: 15%;
  display: inline-block;
  border-radius: 1rem;
  float: left;
}
.kcleft ul {
  list-style: none;
}
.kcleft ul li {
  padding: 1.2rem;
  border-top: 0.07rem solid #999;
  font-weight: 550;
  letter-spacing: 0.1rem;
  font-size: 15px;
}
.tubi {
  padding: 1.2rem;
  border-top: 0.07rem solid #999;
  font-weight: 550;
  letter-spacing: 0.1rem;
  font-size: 15px;
  border-bottom: 0.07rem solid #999;
}
.xqright {
  width: 72%;
  display: inline-block;
  background-color: #fff;
  border-radius: 1rem;
}
.vouchers {
  display: inline-block;
  margin-bottom: 0.5rem;
  width: 100%;
}
.glorious {
  width: 28%;
  display: inline-block;
  margin-top: 1.5rem;
  border: 0.1rem solid;
  border-radius: 12%;
  text-align: left;
  margin-right: 1rem;
  float: left;
  margin-left: 1.5rem;
}
.glorious img {
  width: 100%;
  border-top-left-radius: 19%;
  border-top-right-radius: 19%;
}
.om {
  font-size: 1rem;
  font-weight: 900;
  margin-left: 1.5rem;
}
.ming {
  margin-left: 1.5rem;
  font-size: 1rem;
  font-weight: 500;
}
.ren {
  margin-left: 1.4rem;
  display: inline-block;
}
.jin {
  margin-left: 0.8rem;
  display: inline-block;
}
.fge {
  margin-bottom: 0.8rem;
}
.cs {
  width: 100%;
  background: #f09;
  opacity: 0;
  display: block;
  font-size: 12px;
  transition: 0.3s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
}
.glorious:hover .cs {
  color: #656e73;
  opacity: 1;
}
.tb {
  text-align: left;
  margin-left: 3.2rem;
  width: 100%;
  margin-top: 0.5rem;
}
.yh {
  display: inline-block;
  padding: 0.1rem;
  border-bottom: 0.1rem orange solid;
}
.di {
  border-bottom: 0.1rem solid;
  width: 70%;
}
.coupon {
  background-image: url("../../assets/zu6.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1.1rem;
  font-size: 1.5rem;
  color: #e55044;
  text-align: left;
  margin-top: 1.2rem;
  width: 27%;
  display: inline-block;
}
.cxk {
  padding: 1.5rem;
  width: 92%;
  border-bottom: 0.1rem solid;
  margin-left: 1rem;
}
.xiabu {
  text-align: left;
  margin-left: 3.2rem;
  margin-bottom: 1.2rem;
  margin-top: 1.2rem;
}
.shiyong {
  font-weight: 700;
}
.zitid {
  font-size: 0.2rem;
  color: #999;
}
/* 手机端自适应  300px-767px格式的 开始 */
@media only screen and (max-width: 1100px) {
  .wne {
    margin-right: 1rem !important;
  }
  .touxiang {
    width: 22% !important;
    left: 40% !important;
  }
  .mingcheng {
    top: 31% !important;
    left: 43.5% !important;
  }
  .ida {
    top: 40.5% !important;
    left: 39.5% !important;
  }
  .an {
    top: 57% !important;
    left: 39.6% !important;
  }
  .zz {
    left: 5% !important;
    /* 左侧间隔 */
  }
  .lj {
    left: 38.5% !important;
  }
  .fb {
    left: 72% !important;
  }
  .minzdf {
    width: 100% !important;
    padding: 0rem !important;
  }
  .kcleft {
    padding: 0rem !important;
    width: 95% !important;
    float: none !important;
    margin-bottom: 1.2rem !important;
    margin-top: 1.2rem !important;
  }
  .xqright {
    width: 95% !important;
    margin-bottom: 1.5rem !important;
  }
  .glorious {
    width: 95% !important;
    margin-left: 0.5rem !important;
  }
  .jin {
    margin-left: 5.8rem !important;
  }
  .cxk {
    padding: 0rem !important;
    width: 100% !important;
    border-bottom: 0.1rem solid !important;
    margin-left: 0rem !important;
    padding-bottom: 0.5rem !important;
  }
  .wne {
    margin-right: 4rem !important;
  }
  .mingcheng {
    top: 25% !important;
    left: 48.5% !important;
  }
  .ida {
    top: 36.5% !important;
    left: 46.5% !important;
  }
  .an {
    top: 53% !important;
    left: 46.6% !important;
  }
  .zz {
    top: 79% !important;
    left: 24% !important;
  }
  .lj {
    top: 79% !important;
    left: 46.5% !important;
  }
  .fb {
    top: 79% !important;
    left: 70% !important;
  }
  .minzdf {
    width: 100% !important;
  }
  q .kcleft {
    padding: 1.5rem 2rem !important;
  }
  .glorious {
    width: 40% !important;
  }
  .coupon {
    width: 69% !important;
  }
  .xiabu {
    margin-left: 0.2rem !important;
  }
}
/* 结束 */
</style>
<script>
import ClassHead from "@/components/ClassHead";
import Commonbottom from "@/components/Commonbottom";
export default {
  components: { ClassHead, Commonbottom },
  computed: {},
  data() {
    return {
      value: 5
    };
  },
  methods: {}
};
</script>