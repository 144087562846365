var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('withoutBanner'),_c('router-view')],1),_c('div',[_c('div',{staticClass:"combined"},[_c('img',{staticClass:"ks",staticStyle:{"width":"100%","opacity":"0.4"},attrs:{"src":require("@/assets/20200630095956.png"),"alt":""}}),_vm._m(0),_c('div',[_c('h2',{staticClass:"Psychology"},[_vm._v("心理学概念")]),_c('div',{staticClass:"abnormal"},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('span',{staticClass:"general"},[_vm._v("开课学院：2020-01-19至2020-01-19")]),_c('span',{staticClass:"Cambridge"},[_vm._v("2423423人已报名")])])]),_c('div',{staticStyle:{"background-color":"#f2f2f2","padding":"1%","display":"inline-block"}},[_c('div',{staticClass:"cent"},[_c('div',{staticClass:"ob",staticStyle:{"width":"22%","display":"inline-block"}},[_c('div',{staticClass:"column"},[_c('ul',[_vm._m(1),_c('router-link',{attrs:{"to":"/Alliance/discussion"}},[_c('li',[_c('i',{staticClass:"el-icon-collection",staticStyle:{"color":"skyblue","margin-right":"0.3rem"}}),_vm._v("课程讨论 ")])]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)],1)]),_vm._m(7)]),_c('div',{staticClass:"righter"},[_vm._m(8),_c('div',{staticClass:"wenben"},[_c('el-input',{attrs:{"type":"textarea","rows":2,"placeholder":"请输入内容"},model:{value:(_vm.textarea),callback:function ($$v) {_vm.textarea=$$v},expression:"textarea"}})],1),_c('div',{staticClass:"changyan"},[_c('el-button',{attrs:{"type":"info","round":""}},[_vm._v("畅言一下")])],1),_vm._m(9),_c('div',{staticClass:"biaoti"},[_vm._v("热门评论")]),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15)])])])]),_c('div',[_c('Commonbottom'),_c('router-view')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"ainf",staticStyle:{"position":"absolute","left":"24%","top":"25%"},attrs:{"src":require("@/assets/20200630100506.png"),"alt":""}}),_c('button',{staticClass:"jion"},[_vm._v("加入学习")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#1"}},[_c('li',[_c('i',{staticClass:"el-icon-collection",staticStyle:{"color":"skyblue","margin-right":"0.3rem"}}),_vm._v("课程介绍 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#2"}},[_c('li',[_c('i',{staticClass:"el-icon-office-building",staticStyle:{"color":"skyblue","margin-right":"0.3rem"}}),_vm._v("开课机构 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#3"}},[_c('li',[_c('i',{staticClass:"el-icon-document-copy",staticStyle:{"color":"skyblue","margin-right":"0.3rem"}}),_vm._v("授课老师 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#4"}},[_c('li',[_c('i',{staticClass:"el-icon-pie-chart",staticStyle:{"color":"skyblue","margin-right":"0.3rem"}}),_vm._v("课程章节 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#5"}},[_c('li',[_c('i',{staticClass:"el-icon-paperclip",staticStyle:{"color":"skyblue","margin-right":"0.3rem"}}),_vm._v("课程服务 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#6"}},[_c('li',{staticStyle:{"border-bottom":"0.07rem solid #999"}},[_c('i',{staticClass:"el-icon-files",staticStyle:{"color":"skyblue","margin-right":"0.3rem"}}),_vm._v("权威认证证书 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relevant"},[_c('h2',[_vm._v("相关课程")]),_c('div',{staticStyle:{"margin-bottom":"1.2rem"}},[_c('div',{staticClass:"dependence"},[_c('img',{attrs:{"src":require("@/assets/yl.jpg"),"alt":""}})]),_c('div',{staticClass:"correlation"},[_vm._v(" 大学生如何找兼职？ "),_c('div',{staticStyle:{"color":"#aaa","margin-top":"0.2rem"}},[_vm._v("周建芳")]),_c('div',{staticStyle:{"color":"#aaa","margin-top":"0.8rem"}},[_c('i',{staticClass:"el-icon-user"},[_vm._v("2381人参加")])])])]),_c('div',{staticStyle:{"margin-bottom":"1.2rem"}},[_c('div',{staticClass:"dependence"},[_c('img',{attrs:{"src":require("@/assets/yl.jpg"),"alt":""}})]),_c('div',{staticClass:"correlation"},[_vm._v(" 大学生如何找兼职？ "),_c('div',{staticStyle:{"color":"#aaa","margin-top":"0.2rem"}},[_vm._v("周建芳")]),_c('div',{staticStyle:{"color":"#aaa","margin-top":"0.8rem"}},[_c('i',{staticClass:"el-icon-user"},[_vm._v("2381人参加")])])])]),_c('div',{staticStyle:{"margin-bottom":"1.2rem"}},[_c('div',{staticClass:"dependence"},[_c('img',{attrs:{"src":require("@/assets/yl.jpg"),"alt":""}})]),_c('div',{staticClass:"correlation"},[_vm._v(" 大学生如何找兼职？ "),_c('div',{staticStyle:{"color":"#aaa","margin-top":"0.2rem"}},[_vm._v("周建芳")]),_c('div',{staticStyle:{"color":"#aaa","margin-top":"0.8rem"}},[_c('i',{staticClass:"el-icon-user"},[_vm._v("2381人参加")])])])]),_c('div',{staticStyle:{"margin-bottom":"1.2rem"}},[_c('div',{staticClass:"dependence"},[_c('img',{attrs:{"src":require("@/assets/yl.jpg"),"alt":""}})]),_c('div',{staticClass:"correlation"},[_vm._v(" 大学生如何找兼职？ "),_c('div',{staticStyle:{"color":"#aaa","margin-top":"0.2rem"}},[_vm._v("周建芳")]),_c('div',{staticStyle:{"color":"#aaa","margin-top":"0.8rem"}},[_c('i',{staticClass:"el-icon-user"},[_vm._v("2381人参加")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login"},[_c('button',{staticClass:"jion1"},[_vm._v("登录")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pinglun"},[_c('div',{staticClass:"toubt"},[_vm._v("评论")]),_c('div',{staticClass:"zuoce"},[_c('div',{staticClass:"shu"},[_vm._v("895")]),_c('div',{staticClass:"ming1"},[_vm._v("人参与,")]),_c('div',{staticClass:"shu"},[_vm._v("151")]),_c('div',{staticClass:"ming1"},[_vm._v("条评论")])]),_c('div',{staticClass:"xian"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"neirong"},[_c('div',{staticClass:"touxiang1"},[_c('img',{staticClass:"fengqing",attrs:{"src":require("@/assets/666.jpg"),"alt":""}})]),_c('div',{staticClass:"zcw"},[_c('div',{staticClass:"shangbu"},[_c('div',{staticClass:"fpx"},[_c('div',{staticClass:"web"},[_vm._v("中国大学MOOC网友")]),_c('div',{staticClass:"wangyou"},[_vm._v("[江苏南京网友]")])]),_c('div',{staticClass:"shijian1"},[_vm._v("2020年8月5日 10:06")])]),_c('div',{staticClass:"zxc"},[_vm._v("建议课程不要先下架，特别好的课程一直留着，随时可以学习，这样的资源太少了，都一直在学习")]),_c('div',{staticClass:"huifukuang"},[_c('div',{staticClass:"huifu"},[_vm._v("回复")]),_c('div',{staticClass:"tuz"},[_c('img',{staticClass:"dianz",attrs:{"src":require("@/assets/dianzan.png"),"alt":""}})]),_c('div',{staticClass:"renshu"},[_vm._v("123")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"neirong"},[_c('div',{staticClass:"touxiang1"},[_c('img',{staticClass:"fengqing",attrs:{"src":require("@/assets/666.jpg"),"alt":""}})]),_c('div',{staticClass:"zcw"},[_c('div',{staticClass:"shangbu"},[_c('div',{staticClass:"fpx"},[_c('div',{staticClass:"web"},[_vm._v("中国大学MOOC网友")]),_c('div',{staticClass:"wangyou"},[_vm._v("[江苏南京网友]")])]),_c('div',{staticClass:"shijian1"},[_vm._v("2020年8月5日 10:06")])]),_c('div',{staticClass:"zxc"},[_vm._v("建议课程不要先下架，特别好的课程一直留着，随时可以学习，这样的资源太少了，都一直在学习")]),_c('div',{staticClass:"huifukuang"},[_c('div',{staticClass:"huifu"},[_vm._v("回复")]),_c('div',{staticClass:"tuz"},[_c('img',{staticClass:"dianz",attrs:{"src":require("@/assets/dianzan.png"),"alt":""}})]),_c('div',{staticClass:"renshu"},[_vm._v("123")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"neirong"},[_c('div',{staticClass:"touxiang1"},[_c('img',{staticClass:"fengqing",attrs:{"src":require("@/assets/666.jpg"),"alt":""}})]),_c('div',{staticClass:"zcw"},[_c('div',{staticClass:"shangbu"},[_c('div',{staticClass:"fpx"},[_c('div',{staticClass:"web"},[_vm._v("中国大学MOOC网友")]),_c('div',{staticClass:"wangyou"},[_vm._v("[江苏南京网友]")])]),_c('div',{staticClass:"shijian1"},[_vm._v("2020年8月5日 10:06")])]),_c('div',{staticClass:"zxc"},[_vm._v("建议课程不要先下架，特别好的课程一直留着，随时可以学习，这样的资源太少了，都一直在学习")]),_c('div',{staticClass:"huifukuang"},[_c('div',{staticClass:"huifu"},[_vm._v("回复")]),_c('div',{staticClass:"tuz"},[_c('img',{staticClass:"dianz",attrs:{"src":require("@/assets/dianzan.png"),"alt":""}})]),_c('div',{staticClass:"renshu"},[_vm._v("123")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"neirong"},[_c('div',{staticClass:"touxiang1"},[_c('img',{staticClass:"fengqing",attrs:{"src":require("@/assets/666.jpg"),"alt":""}})]),_c('div',{staticClass:"zcw"},[_c('div',{staticClass:"shangbu"},[_c('div',{staticClass:"fpx"},[_c('div',{staticClass:"web"},[_vm._v("中国大学MOOC网友")]),_c('div',{staticClass:"wangyou"},[_vm._v("[江苏南京网友]")])]),_c('div',{staticClass:"shijian1"},[_vm._v("2020年8月5日 10:06")])]),_c('div',{staticClass:"zxc"},[_vm._v("建议课程不要先下架，特别好的课程一直留着，随时可以学习，这样的资源太少了，都一直在学习")]),_c('div',{staticClass:"huifukuang"},[_c('div',{staticClass:"huifu"},[_vm._v("回复")]),_c('div',{staticClass:"tuz"},[_c('img',{staticClass:"dianz",attrs:{"src":require("@/assets/dianzan.png"),"alt":""}})]),_c('div',{staticClass:"renshu"},[_vm._v("123")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"neirong"},[_c('div',{staticClass:"touxiang1"},[_c('img',{staticClass:"fengqing",attrs:{"src":require("@/assets/666.jpg"),"alt":""}})]),_c('div',{staticClass:"zcw"},[_c('div',{staticClass:"shangbu"},[_c('div',{staticClass:"fpx"},[_c('div',{staticClass:"web"},[_vm._v("中国大学MOOC网友")]),_c('div',{staticClass:"wangyou"},[_vm._v("[江苏南京网友]")])]),_c('div',{staticClass:"shijian1"},[_vm._v("2020年8月5日 10:06")])]),_c('div',{staticClass:"zxc"},[_vm._v("建议课程不要先下架，特别好的课程一直留着，随时可以学习，这样的资源太少了，都一直在学习")]),_c('div',{staticClass:"huifukuang"},[_c('div',{staticClass:"huifu"},[_vm._v("回复")]),_c('div',{staticClass:"tuz"},[_c('img',{staticClass:"dianz",attrs:{"src":require("@/assets/dianzan.png"),"alt":""}})]),_c('div',{staticClass:"renshu"},[_vm._v("123")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"neirong"},[_c('div',{staticClass:"touxiang1"},[_c('img',{staticClass:"fengqing",attrs:{"src":require("@/assets/666.jpg"),"alt":""}})]),_c('div',{staticClass:"zcw"},[_c('div',{staticClass:"shangbu"},[_c('div',{staticClass:"fpx"},[_c('div',{staticClass:"web"},[_vm._v("中国大学MOOC网友")]),_c('div',{staticClass:"wangyou"},[_vm._v("[江苏南京网友]")])]),_c('div',{staticClass:"shijian1"},[_vm._v("2020年8月5日 10:06")])]),_c('div',{staticClass:"zxc"},[_vm._v("建议课程不要先下架，特别好的课程一直留着，随时可以学习，这样的资源太少了，都一直在学习")]),_c('div',{staticClass:"huifukuang"},[_c('div',{staticClass:"huifu"},[_vm._v("回复")]),_c('div',{staticClass:"tuz"},[_c('img',{staticClass:"dianz",attrs:{"src":require("@/assets/dianzan.png"),"alt":""}})]),_c('div',{staticClass:"renshu"},[_vm._v("123")])])])])
}]

export { render, staticRenderFns }