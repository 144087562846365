<template>
  <div>
    <!--引入头部-->
    <div>
      <Header></Header>
      <router-view></router-view>
    </div>
    <!--内容-->
    <div class="contrain">
      <div class="contrainr">
        <div class="tcleft">
          <div class="titles">
            <h2>2018年10月职业与继续教育新闻简报</h2>
            <div class="caption">
              发布时间：
              <i style="color:#4489da;padding-right:0.2rem;">2018/11/6 16:24:22</i>
              字号：
              <span style="margin-right:0.2rem;">大、小</span>
              阅读：
              <i style="color:#4489da;padding-right:0.2rem;">199次</i>
            </div>
            <div style="font-size:0.14rem;text-align:left;line-height:23px;">
              密苏里大学哥伦比亚分校
              世界上第一所新闻学院即是密苏里大学哥伦比亚分校的新闻学院，在这里产生了让美国称为隐形的第四界（即新闻。其他三界为分立的三权，司法、行政、立法）。
              中国的这个阶段属于拓荒期。
              密苏里新闻帮的崛起
              在1928年前，有名的密苏里大学哥伦比亚分校毕业的记者在远东工作，其中超过半数以上在中国。在这些人中比较著名的包括密勒（Thomas F.F.Millard）、鲍威尔（J.B.Powell）、美联社的莫里斯（John R.Morris）、哈瑞斯（Morris Harris）、巴布（J.C.Babb）、怀特（James D.White）、合众国际社的克林（Benjamin Kline)、《纽约时报》的米索威滋(Hernry F Misselwitz），《纽约先锋论坛报》的科内（Vitor Keen）、《密勒氏评论报》的克劳（Carl Crow）等，后来又有武道（Maurice Votaw）、斯诺（Edgar Snow），还有虽非密大背景、但出自密苏里州的史沫特莱（Agnes Smedley）、项美丽（Emily Hahn）等。从1900年开始，这些人从美国中西部络绎不绝地开赴中国，形成了一道壮丽的景观。西北大学的汉密尔顿教授（J.M.Hamilton）形容他们为“密苏里新闻团伙”（Missouri Monopoly），阿道夫大学的罗赞斯基博士（Mordechai Rozanski） 更戏称这些人为“密苏里黑手党”（Missouri Mafia）。
              从另一方面观察，民国时期中国一大批重要的新闻记者、新闻教育家、新闻官员、政治家、院士多出于密苏里大学哥伦比亚分校。 例如《广州时报》主笔黄宪昭、主管对外新闻的国民党中宣部副部长董显光,《中央日报》社长马星野，《申报》著名记者、后任复旦大学教授的汪英宾, 路透社记者赵敏恒、国民党新闻官员沈剑虹、著名报人吴嘉棠、新闻教育家蒋荫恩、梁士纯、谢然之等, 显然,中国也有一支“密苏里新闻帮”。
            </div>
          </div>
        </div>
        <div class="tcright">
          <div class="title">
            <h2>
              <span>热点新闻</span>
              <router-link to class="more" style="float:right;">More></router-link>
            </h2>
            <ul>
              <li>
                <router-link class="nower" to>2019年12月职业与继续教育要闻简报</router-link>
              </li>
              <li>
                <router-link class="nower" to>2019年12月职业与继续教育要闻简报</router-link>
              </li>
              <li>
                <router-link class="nower" to>2019年12月职业与继续教育要闻简报</router-link>
              </li>
              <li>
                <router-link class="nower" to>2019年12月职业与继续教育要闻简报</router-link>
              </li>
              <li>
                <router-link class="nower" to>2019年12月职业与继续教育要闻简报</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <Footer></Footer>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<style scoped>
.contrain {
  background-color: #efefef;
}
.contrainr {
  width: 90%;
  margin: 0 auto;
}
.tcleft {
  width: 64%;
  background-color: #fff;
  display: inline-block;
  margin-top: 0.2rem;
  margin-bottom: 0.4rem;
}
.title h2 {
  border-bottom: 1px solid #1697eb;
  color: #505050;
  font-size: 0.1rem;
  line-height: 39px;
  text-align: left;
}
.title ul {
  list-style: none;
  padding: 0.1rem;
}
.title h2 span {
  color: #505050;
  font-size: 0.15rem;
  text-align: left;
  background-color: #1697eb;
  display: inline-block;
  color: #fff;
  padding: 0.08rem;
}
.title .more {
  text-decoration: none;
  font-size: 0.14rem;
  color: #999;
  margin-right: 0.1rem;
  margin-top: 0.1rem;
}
.title ul li {
  font-size: 0.3rem;
  text-align: left;
  line-height: 36px;
}
.title .nower {
  text-decoration: none;
  font-size: 0.14rem;
  color: #909090;
}
.title .dater {
  float: right;
  color: #909090;
}
.tcright {
  width: 30%;
  background-color: #fff;
  display: inline-block;
  float: right;
  margin-top: 0.2rem;
  margin-bottom: 0.4rem;
}
.titles {
  padding: 0.2rem;
}
.titles h2 {
  font-size: 0.3rem;
  color: #4489da;
  padding-top: 0.5rem;
}
.caption {
  border: 1px dashed #a0a0a0;
  padding: 0.1rem;
  font-size: 0.1rem;
  margin: 0.2rem;
}
@media only screen and (max-width: 800px) {
  .tcright {
    width: 100% !important;
    float: none !important;
  }
  .tcleft {
    width: 100% !important;
  }
}
</style>
<script>
import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
export default {
  components: { Header, Footer },
  data() {
    return {
      currentPage3: 1
    };
  },
  methods: {
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
    }
  }
};
</script>