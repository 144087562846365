<template>
  <div>
    <!-- 引入公共部分 -->
    <div>
      <withoutBanner></withoutBanner>
      <router-view></router-view>
    </div>
    <div>
      <div class="combined">
        <img src="@/assets/20200630095956.png" alt class="ks" style="width:100%;opacity: 0.4;" />
        <div>
          <img
            src="@/assets/20200630100506.png"
            alt
            class="ainf"
            style="position: absolute;left:24%;top:25%;"
          />
          <!-- 加入学习 -->
          <button class="jion">加入学习</button>
        </div>
        <!-- 心理学概念 -->
        <div>
          <h2 class="Psychology">心理学概念</h2>
          <div class="abnormal">
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <!-- 开课学院的时间 -->
          <span class="general">开课学院：2020-01-19至2020-01-19</span>
          <!-- 参加课程的报名人数 -->
          <span class="Cambridge">2423423人已报名</span>
        </div>
      </div>
      <div style="background-color:#f2f2f2;padding:1%;display: inline-block;">
        <div class="cent" style>
          <div style="width:22%;display:inline-block" class="ob">
            <div class="column">
              <ul>
                <a href="#1">
                  <!-- 课程介绍 -->
                  <li>
                    <i class="el-icon-collection" style="color:skyblue;margin-right:0.3rem"></i>课程介绍
                  </li>
                </a>
                <!-- 课程讨论 -->
                <router-link to="/Alliance/discussion">
                  <li>
                    <i class="el-icon-collection" style="color:skyblue;margin-right:0.3rem"></i>课程讨论
                  </li>
                </router-link>
                <!-- 开课机构 -->
                <a href="#2">
                  <li>
                    <i class="el-icon-office-building" style="color:skyblue;margin-right:0.3rem"></i>开课机构
                  </li>
                </a>
                <!-- 授课老师 -->
                <a href="#3">
                  <li>
                    <i class="el-icon-document-copy" style="color:skyblue;margin-right:0.3rem"></i>授课老师
                  </li>
                </a>
                <!-- 课程章节 -->
                <a href="#4">
                  <li>
                    <i class="el-icon-pie-chart" style="color:skyblue;margin-right:0.3rem"></i>课程章节
                  </li>
                </a>
                <!-- 课程服务 -->
                <a href="#5">
                  <li>
                    <i class="el-icon-paperclip" style="color:skyblue;margin-right:0.3rem"></i>课程服务
                  </li>
                </a>
                <!-- 权威认证证书 -->
                <a href="#6">
                  <li style="border-bottom: 0.07rem solid #999;">
                    <i class="el-icon-files" style="color:skyblue;margin-right:0.3rem"></i>权威认证证书
                  </li>
                </a>
              </ul>
            </div>
            <!-- 相关课程 -->
            <div class="relevant">
              <h2>相关课程</h2>
              <div style="margin-bottom:1.2rem">
                <div class="dependence">
                  <img src="@/assets/yl.jpg" alt />
                </div>
                <!-- 课程标题 -->
                <div class="correlation">
                  大学生如何找兼职？
                  <!-- 授课老师 -->
                  <div style="color:#aaa;margin-top:0.2rem">周建芳</div>
                  <!-- 课程参加人数 -->
                  <div style="color:#aaa;margin-top:0.8rem">
                    <i class="el-icon-user">2381人参加</i>
                  </div>
                </div>
              </div>
              <div style="margin-bottom:1.2rem">
                <div class="dependence">
                  <img src="@/assets/yl.jpg" alt />
                </div>
                <div class="correlation">
                  大学生如何找兼职？
                  <div style="color:#aaa;margin-top:0.2rem">周建芳</div>
                  <div style="color:#aaa;margin-top:0.8rem">
                    <i class="el-icon-user">2381人参加</i>
                  </div>
                </div>
              </div>
              <div style="margin-bottom:1.2rem">
                <div class="dependence">
                  <img src="@/assets/yl.jpg" alt />
                </div>
                <div class="correlation">
                  大学生如何找兼职？
                  <div style="color:#aaa;margin-top:0.2rem">周建芳</div>
                  <div style="color:#aaa;margin-top:0.8rem">
                    <i class="el-icon-user">2381人参加</i>
                  </div>
                </div>
              </div>
              <div style="margin-bottom:1.2rem">
                <div class="dependence">
                  <img src="@/assets/yl.jpg" alt />
                </div>
                <div class="correlation">
                  大学生如何找兼职？
                  <div style="color:#aaa;margin-top:0.2rem">周建芳</div>
                  <div style="color:#aaa;margin-top:0.8rem">
                    <i class="el-icon-user">2381人参加</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 右侧评论模块 -->
          <div class="righter">
            <!-- <comment></comment> -->
            <!-- 登录按钮 -->
            <div class="login">
              <button class="jion1">登录</button>
            </div>
            <!-- 评论输入框 -->
            <div class="wenben">
              <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="textarea"></el-input>
            </div>
            <!-- 畅言一下(发表评论) -->
            <div class="changyan">
              <el-button type="info" round>畅言一下</el-button>
            </div>
            <div class="pinglun">
              <!-- 评论顶部 -->
              <div class="toubt">评论</div>
              <div class="zuoce">
                <div class="shu">895</div>
                <div class="ming1">人参与,</div>
                <div class="shu">151</div>
                <div class="ming1">条评论</div>
              </div>
              <div class="xian"></div>
            </div>
            <div class="biaoti">热门评论</div>
            <div class="neirong">
              <div class="touxiang1">
                <img class="fengqing" src="@/assets/666.jpg" alt />
              </div>
              <div class="zcw">
                <div class="shangbu">
                  <div class="fpx">
                    <div class="web">中国大学MOOC网友</div>
                    <div class="wangyou">[江苏南京网友]</div>
                  </div>
                  <div class="shijian1">2020年8月5日 10:06</div>
                </div>
                <div class="zxc">建议课程不要先下架，特别好的课程一直留着，随时可以学习，这样的资源太少了，都一直在学习</div>
                <div class="huifukuang">
                  <div class="huifu">回复</div>
                  <div class="tuz">
                    <img class="dianz" src="@/assets/dianzan.png" alt />
                  </div>
                  <div class="renshu">123</div>
                </div>
              </div>
            </div>

            <div class="neirong">
              <div class="touxiang1">
                <img class="fengqing" src="@/assets/666.jpg" alt />
              </div>
              <div class="zcw">
                <div class="shangbu">
                  <div class="fpx">
                    <div class="web">中国大学MOOC网友</div>
                    <div class="wangyou">[江苏南京网友]</div>
                  </div>
                  <div class="shijian1">2020年8月5日 10:06</div>
                </div>
                <div class="zxc">建议课程不要先下架，特别好的课程一直留着，随时可以学习，这样的资源太少了，都一直在学习</div>
                <div class="huifukuang">
                  <div class="huifu">回复</div>
                  <div class="tuz">
                    <img class="dianz" src="@/assets/dianzan.png" alt />
                  </div>
                  <div class="renshu">123</div>
                </div>
              </div>
            </div>

            <div class="neirong">
              <div class="touxiang1">
                <img class="fengqing" src="@/assets/666.jpg" alt />
              </div>
              <div class="zcw">
                <div class="shangbu">
                  <div class="fpx">
                    <div class="web">中国大学MOOC网友</div>
                    <div class="wangyou">[江苏南京网友]</div>
                  </div>
                  <div class="shijian1">2020年8月5日 10:06</div>
                </div>
                <div class="zxc">建议课程不要先下架，特别好的课程一直留着，随时可以学习，这样的资源太少了，都一直在学习</div>
                <div class="huifukuang">
                  <div class="huifu">回复</div>
                  <div class="tuz">
                    <img class="dianz" src="@/assets/dianzan.png" alt />
                  </div>
                  <div class="renshu">123</div>
                </div>
              </div>
            </div>

            <div class="neirong">
              <div class="touxiang1">
                <img class="fengqing" src="@/assets/666.jpg" alt />
              </div>
              <div class="zcw">
                <div class="shangbu">
                  <div class="fpx">
                    <div class="web">中国大学MOOC网友</div>
                    <div class="wangyou">[江苏南京网友]</div>
                  </div>
                  <div class="shijian1">2020年8月5日 10:06</div>
                </div>
                <div class="zxc">建议课程不要先下架，特别好的课程一直留着，随时可以学习，这样的资源太少了，都一直在学习</div>
                <div class="huifukuang">
                  <div class="huifu">回复</div>
                  <div class="tuz">
                    <img class="dianz" src="@/assets/dianzan.png" alt />
                  </div>
                  <div class="renshu">123</div>
                </div>
              </div>
            </div>

            <div class="neirong">
              <div class="touxiang1">
                <img class="fengqing" src="@/assets/666.jpg" alt />
              </div>
              <div class="zcw">
                <div class="shangbu">
                  <div class="fpx">
                    <div class="web">中国大学MOOC网友</div>
                    <div class="wangyou">[江苏南京网友]</div>
                  </div>
                  <div class="shijian1">2020年8月5日 10:06</div>
                </div>
                <div class="zxc">建议课程不要先下架，特别好的课程一直留着，随时可以学习，这样的资源太少了，都一直在学习</div>
                <div class="huifukuang">
                  <div class="huifu">回复</div>
                  <div class="tuz">
                    <img class="dianz" src="@/assets/dianzan.png" alt />
                  </div>
                  <div class="renshu">123</div>
                </div>
              </div>
            </div>

            <div class="neirong">
              <div class="touxiang1">
                <img class="fengqing" src="@/assets/666.jpg" alt />
              </div>
              <div class="zcw">
                <div class="shangbu">
                  <div class="fpx">
                    <div class="web">中国大学MOOC网友</div>
                    <div class="wangyou">[江苏南京网友]</div>
                  </div>
                  <div class="shijian1">2020年8月5日 10:06</div>
                </div>
                <div class="zxc">建议课程不要先下架，特别好的课程一直留着，随时可以学习，这样的资源太少了，都一直在学习</div>
                <div class="huifukuang">
                  <div class="huifu">回复</div>
                  <div class="tuz">
                    <img class="dianz" src="@/assets/dianzan.png" alt />
                  </div>
                  <div class="renshu">123</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 引入公共底部部分 -->
    <div>
      <Commonbottom></Commonbottom>
      <router-view></router-view>
    </div>
  </div>
</template>
<style  scoped>
a {
  color: black;
}
a,
a:hover,
a:active,
a:visited,
a:link,
a:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  outline: none;
  background: none;
  text-decoration: none;
}
.combined {
  width: 100%;
  position: relative;
  background-color: #f2f2f2;
}
.jion {
  padding: 0.8rem 5rem;
  border-radius: 0.8rem;
  background-color: #ff9552;
  color: #fff;
  position: absolute;
  left: 23%;
  top: 55%;
  font-size: 14px;
}
.jion1 {
  padding: 0.4rem 0.1rem;
  border-radius: 1.8rem;
  color: #1b1a1a;
  font-size: 14px;
}
.login {
  float: left;
  margin-bottom: 1.2rem;
}
.wenben {
  margin-bottom: 0.5rem;
}
.changyan {
  float: right;
}
.toubt {
  display: inline-block;
  padding: 0.5rem 1.2rem;
  border: 0.1rem solid;
  border-bottom: 0rem;
  border-radius: 0.6rem;
}
.xian {
  border-bottom: 0.1rem solid;
}
.pinglun {
  display: inline-block;
  width: 100%;
  text-align: left;
}
.biaoti {
  float: left;
  margin-left: 1rem;
  margin-top: 0.5rem;
}
.Psychology {
  font-size: 1.6rem;
  letter-spacing: 0.1rem;
  position: absolute;
  left: 63%;
  top: 24%;
}
.abnormal {
  position: absolute;
  left: 63%;
  top: 44%;
}
.general {
  font-size: 0.8rem;
  position: absolute;
  left: 63%;
  top: 56%;
  font-weight: bold;
}
.Cambridge {
  font-size: 0.8rem;
  position: absolute;
  left: 63%;
  top: 66%;
  font-weight: bold;
}
.column {
  background-color: #fff;
  padding: 1.5rem 3rem;
  width: 65%;
  display: inline-block;
  border-radius: 1rem;
}
.column ul {
  list-style: none;
}
.column ul li {
  padding: 1.2rem;
  border-top: 0.07rem solid #999;
  font-weight: 550;
  letter-spacing: 0.1rem;
  font-size: 15px;
}
.relevant {
  background-color: #fff;
  padding: 1.5rem 2rem;
  width: 77%;
  display: inline-block;
  border-radius: 1rem;
  margin-top: 1rem;
}
.relevant h2 {
  padding: 0 1rem 1rem 1rem;
  font-size: 1.2rem;
  font-weight: 550;
}
.dependence {
  width: 40%;
  display: inline-block;
  float: left;
}
.dependence img {
  width: 100%;
}
.correlation {
  font-size: 14px;
  margin-left: 6.9rem;
  text-align: left;
}
.righter {
  background-color: #fff;
  padding: 1%;
  width: 70%;
  display: inline-block;
  margin-left: 1%;
  float: right;
}
.wenzi {
  width: 31%;
  display: inline-block;
  float: left;
  margin-left: 1rem;
  margin-top: 3.5rem;
}
.wenzi span {
  font-size: 1rem;
  font-weight: 600;
}
.ship {
  width: 56%;
  display: inline-block;
  margin-left: 6.5rem;
}
.shi {
  background-color: #f1f9fe;
}
.rili {
  display: inline-block;
  padding: 3rem;
}
.shijian {
  display: inline-block;
  padding: 3rem;
}
.tushu {
  display: inline-block;
  padding: 3rem;
}
.gong {
  color: #3487fa;
}
.kai {
  background-color: #fff;
}
.gb {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.imc {
  padding-bottom: 1rem;
}
.tu img {
  width: 13%;
  border-radius: 50%;
}
.inf {
  background-color: #efefef;
}
.inf span {
  font-size: 1.1rem;
  font-weight: 600;
}
.grf {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.jibie {
  border-bottom: 0.1rem solid #e7e7e7;
  width: 70%;
  margin: 0 auto;
}
.jibie span {
  font-size: 0.4rem;
  font-weight: 300;
}
.jiea {
  width: 60%;
  margin: 0 auto;
  text-align: left;
  padding-bottom: 2.2rem;
}
.jiea span {
  font-size: 0.3rem;
}
.zhang {
  background-color: #fff;
}
.zf {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.youhui {
  width: 80%;
  margin: 0 auto;
}
.mianfei {
  width: 40%;
  background: #ffff;
  box-shadow: #efefef 0px 0px 10px;
  border-radius: 2rem;
  display: inline-block;
  float: left;
}
.mianfei h3 {
  font-weight: 600;
  text-align: left;
  margin-left: 3.5rem;
  padding-top: 1rem;
}
.ren h3 {
  font-weight: 600;
  text-align: left;
  margin-left: 3.5rem;
  padding-top: 1rem;
}
.mianfei span {
  text-align: right;
}
.rq {
  color: #9d9e9b;
  margin: 1rem 0rem;
}
.xisc {
  text-align: left;
  margin-left: 3.4rem;
  margin-bottom: 1.2rem;
}
.xiaojie {
  text-align: left;
  margin-left: 3.4rem;
  padding-bottom: 5rem;
}
.ming {
  width: 65%;
}
.citiao {
  text-align: right;
  margin-right: 1rem;
}
.anniu {
  padding-bottom: 1.2rem;
}
.xiaojie1 {
  text-align: left;
  margin-left: 3.4rem;
  padding-bottom: 0.5rem;
}
.ren {
  width: 40%;
  background: #ffff;
  box-shadow: #efefef 0px 0px 10px;
  border-radius: 2rem;
  display: inline-block;
}
.yang {
  font-size: 0.5rem;
  color: #697cb1;
}
.el-icon-star-on {
  color: #697cb1;
}
.afshq {
  text-align: left;
  margin-left: 3.5rem;
}
.kecha {
  margin-bottom: 0.5rem;
}
.jiaol {
  margin-bottom: 0.5rem;
}
.zshu {
  margin-bottom: 0.5rem;
}
.kjie {
  margin-top: 2rem;
}
.fu {
  margin-bottom: 0.5rem;
}
.cent {
  width: 100%;
  margin: 0 auto;
}
.shu {
  display: inline-block;
  font-weight: 800;
}
.ming1 {
  display: inline-block;
}
.zuoce {
  display: inline-block;
  float: right;
  margin-top: 0.8rem;
}
.fengqing {
  width: 100%;
  border-radius: 50%;
}
.neirong {
  display: inline-block;
  width: 100%;
  margin-top: 1.5rem;
  border-bottom: 0.1rem #d3d4d1 dashed;
  padding-bottom: 0.5rem;
}
.touxiang1 {
  display: inline-block;
  width: 5%;
  float: left;
  margin-left: 0.5rem;
}
.zcw {
  display: inline-block;
  width: 92%;
  text-align: left;
}
.shangbu {
  text-align: left;
  /* display: inline-block; */
}
.fpx {
  display: inline-block;
}
.web {
  display: inline-block;
}
.wangyou {
  display: inline-block;
  color: #d3d4d1;
}
.shijian1 {
  float: right;
  color: #d3d4d1;
}
.zxc {
  margin-top: 1rem;
}
.huifukuang {
  float: right;
  width: 10%;
  color: #d3d4d1;
}
.huifu {
  margin-top: 0.5rem;
  display: inline-block;
  margin-right: 0.5rem;
}
.tuz {
  width: 16%;
  display: inline-block;
}
.dianz {
  width: 100%;
  display: inline-block;
}
.renshu {
  display: inline-block;
}
/* 最大值1100px媒体查询 */
@media only screen and (max-width: 1100px) {
  .jion {
    padding: 0.1rem 2rem !important;
    border-radius: 0.8rem !important;
    left: 8.5% !important;
    top: 52% !important;
  }
  .Psychology {
    font-size: 0.6rem !important;
  }
  .ainf {
    width: 14% !important;
    left: 18% !important;
  }
  .ks {
    height: 10rem !important;
  }
  .abnormal {
    left: 61% !important;
    top: 37% !important;
  }
  .general {
    left: 61% !important;
    top: 63% !important;
  }
  .Cambridge {
    left: 68% !important;
    top: 83% !important;
  }
  .cent {
    width: 100% !important;
  }
  .column {
    padding: 1rem 0.5rem !important;
    width: 100% !important;
  }
  .correlation[data-v-4df49d0e] {
    margin-left: 0rem !important;
    float: none !important;
    text-align: center !important;
  }
  .mimg {
    width: 100% !important;
  }
  .relevant {
    padding: 0rem !important;
    width: 122% !important;
  }
  .dependence {
    width: 95% !important;
    margin: 0 0.1rem !important;
  }
  .wenzi {
    width: 90% !important;
  }
  .ship {
    width: 98% !important;
    margin-left: 0rem !important;
  }
  .ob {
    float: left !important;
  }
  .column ul li {
    padding: 0.8rem !important;
  }
  .mianfei {
    width: 100% !important;
    float: none !important;
  }
  .ren {
    width: 100% !important;
    margin-top: 1.2rem !important;
  }
  .xisc {
    text-align: left !important;
    margin-left: 1.4rem !important;
    margin-bottom: 1.2rem;
  }
  .xiaojie1 {
    text-align: left;
    margin-left: 1.4rem;
    padding-bottom: 0.5rem;
  }
  .afshq {
    text-align: left;
    margin-left: 0.5rem;
  }
  .correlation {
    margin-left: 0rem;
  }
  .touxiang1 {
    width: 20% !important;
}
.zxc {
    margin-top: 2rem !important;
}
.huifukuang {
    width: 32% !important;
}
}

</style>
<script>
import withoutBanner from "@/components/withoutBanner";
import Commonbottom from "@/components/Commonbottom";
// import comment from "hbl-comment";
export default {
  components: { withoutBanner, Commonbottom },
  computed: {},
  data() {
    return {
      options: [
        {
          value: "选项1",
          label: "黄金糕"
        },
        {
          value: "选项2",
          label: "双皮奶"
        },
        {
          value: "选项3",
          label: "蚵仔煎"
        },
        {
          value: "选项4",
          label: "龙须面"
        },
        {
          value: "选项5",
          label: "北京烤鸭"
        }
      ],
      value: 5,
      activeNames: [""],
      textarea: ""
    };
  },
  methods: {
    handleChange(val) {
    }
  }
};
</script>