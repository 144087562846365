var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('ClassHead'),_c('router-view')],1),_c('div',{staticClass:"dalunbo"},[_c('div',{staticClass:"liebiao"},[_c('div',{staticClass:"nue"},[_vm._m(0),_c('router-link',{attrs:{"to":"/Alliance/supermarket"}},[_c('div',{staticClass:"wne"},[_c('span',[_vm._v("课程超市")])])]),_c('router-link',{attrs:{"to":"/Alliance/school"}},[_c('div',{staticClass:"wne"},[_c('span',[_vm._v("学校")])])]),_c('router-link',{attrs:{"to":"/Alliance/SchoolCloud"}},[_c('div',{staticClass:"wne"},[_c('span',[_vm._v("学校云")])])]),_vm._m(1)],1)])]),_c('div',{staticClass:"contas"},[_c('img',{staticStyle:{"width":"100%","height":"22rem"},attrs:{"src":require("@/assets/grn.png"),"alt":""}}),_c('div',[_c('img',{staticClass:"touxiang",staticStyle:{"position":"absolute","left":"49%","top":"5%"},attrs:{"src":require("@/assets/qqq.jpeg"),"alt":""}}),_c('div',{staticClass:"mingcheng"},[_vm._v("昵称123")]),_c('div',{staticClass:"ida"},[_vm._v("ID:11223344")]),_c('div',{staticClass:"an"},[_c('el-button',{attrs:{"type":"danger","round":""}},[_vm._v("去认证")])],1),_vm._m(2),_vm._m(3),_vm._m(4)])]),_c('div',{staticClass:"mas"},[_c('div',{staticClass:"minzdf",staticStyle:{"padding":"1rem","width":"75%","margin":"0 auto","display":"inline-block"}},[_c('div',{staticClass:"kcleft"},[_c('ul',[_c('router-link',{attrs:{"to":"/Alliance/curriculum"}},[_c('li',[_c('i',{staticClass:"el-icon-collection",staticStyle:{"color":"skyblue","margin-right":"0.3rem"}}),_vm._v("我的课程 ")])]),_vm._m(5),_vm._m(6),_vm._m(7),_c('router-link',{attrs:{"to":"/Alliance/coupon"}},[_c('li',[_c('i',{staticClass:"el-icon-paperclip",staticStyle:{"color":"skyblue","margin-right":"0.3rem"}}),_vm._v("优惠券 ")])]),_vm._m(8),_vm._m(9)],1)]),_c('div',{staticClass:"xqright"})])]),_c('div',[_c('Commonbottom'),_c('router-view')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wne"},[_c('span',[_vm._v("首页")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wne"},[_c('span',[_vm._v("公共服务平台")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zz"},[_c('div',[_vm._v("2")]),_c('div',[_vm._v("正在学习课程")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lj"},[_c('div',[_vm._v("2")]),_c('div',[_vm._v("累计学习课程")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fb"},[_c('div',[_vm._v("0")]),_c('div',[_vm._v("发布的讨论")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"el-icon-office-building",staticStyle:{"color":"skyblue","margin-right":"0.3rem"}}),_vm._v("我的直播 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"el-icon-document-copy",staticStyle:{"color":"skyblue","margin-right":"0.3rem"}}),_vm._v("我的讨论 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"el-icon-pie-chart",staticStyle:{"color":"skyblue","margin-right":"0.3rem"}}),_vm._v("购买记录 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"el-icon-collection",staticStyle:{"color":"skyblue","margin-right":"0.3rem"}}),_vm._v("我的证书 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"tubi"},[_c('i',{staticClass:"el-icon-collection",staticStyle:{"color":"skyblue","margin-right":"0.3rem"}}),_vm._v("信息设置 ")])
}]

export { render, staticRenderFns }