<template>
  <div>
    <div>
      <withoutBanner></withoutBanner>
      <router-view></router-view>
    </div>
    <div>
      <div class="combined">
        <img
          src="@/assets/20200630095956.png"
          alt
          class="ks"
          style="width: 100%; opacity: 0.4"
        />
        <div>
          <img
            src="@/assets/20200630100506.png"
            alt
            class="ainf"
            style="position: absolute; left: 24%; top: 25%"
          />
          <button class="jion">加入学习</button>
        </div>
        <div>
          <h2 class="Psychology">心理学概念</h2>
          <div class="abnormal">
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <span class="general">开课学院：2020-01-19至2020-01-19</span>
          <span class="Cambridge">2423423人已报名</span>
        </div>
      </div>
      <div
        style="background-color: #f2f2f2; padding: 1%; display: inline-block"
      >
        <div class="cent" style="width: 75%; margin: 0 auto">
          <div style="width: 22%; display: inline-block" class="ob">
            <div class="column">
              <ul>
                <a href="#1">
                  <li>
                    <i
                      class="el-icon-collection"
                      style="color: skyblue; margin-right: 0.3rem"
                    ></i
                    >课程介绍
                  </li>
                </a>
                <router-link to="/Alliance/discussion">
                  <li>
                    <i
                      class="el-icon-collection"
                      style="color: skyblue; margin-right: 0.3rem"
                    ></i
                    >课程讨论
                  </li>
                </router-link>
                <a href="#2">
                  <li>
                    <i
                      class="el-icon-office-building"
                      style="color: skyblue; margin-right: 0.3rem"
                    ></i
                    >开课机构
                  </li>
                </a>
                <a href="#3">
                  <li>
                    <i
                      class="el-icon-document-copy"
                      style="color: skyblue; margin-right: 0.3rem"
                    ></i
                    >授课老师
                  </li>
                </a>
                <a href="#4">
                  <li>
                    <i
                      class="el-icon-pie-chart"
                      style="color: skyblue; margin-right: 0.3rem"
                    ></i
                    >课程章节
                  </li>
                </a>
                <a href="#5">
                  <li>
                    <i
                      class="el-icon-paperclip"
                      style="color: skyblue; margin-right: 0.3rem"
                    ></i
                    >课程服务
                  </li>
                </a>
                <a href="#6">
                  <li style="border-bottom: 0.07rem solid #999">
                    <i
                      class="el-icon-files"
                      style="color: skyblue; margin-right: 0.3rem"
                    ></i
                    >权威认证证书
                  </li>
                </a>
              </ul>
            </div>
            <div class="relevant">
              <h2>相关课程</h2>
              <div style="margin-bottom: 1.2rem">
                <div class="dependence">
                  <img src="@/assets/yl.jpg" alt />
                </div>
                <div class="correlation">
                  大学生如何找兼职？
                  <div style="color: #aaa; margin-top: 0.2rem">周建芳</div>
                  <div style="color: #aaa; margin-top: 0.8rem">
                    <i class="el-icon-user">2381人参加</i>
                  </div>
                </div>
              </div>
              <div style="margin-bottom: 1.2rem">
                <div class="dependence">
                  <img src="@/assets/yl.jpg" alt />
                </div>
                <div class="correlation">
                  大学生如何找兼职？
                  <div style="color: #aaa; margin-top: 0.2rem">周建芳</div>
                  <div style="color: #aaa; margin-top: 0.8rem">
                    <i class="el-icon-user">2381人参加</i>
                  </div>
                </div>
              </div>
              <div style="margin-bottom: 1.2rem">
                <div class="dependence">
                  <img src="@/assets/yl.jpg" alt />
                </div>
                <div class="correlation">
                  大学生如何找兼职？
                  <div style="color: #aaa; margin-top: 0.2rem">周建芳</div>
                  <div style="color: #aaa; margin-top: 0.8rem">
                    <i class="el-icon-user">2381人参加</i>
                  </div>
                </div>
              </div>
              <div style="margin-bottom: 1.2rem">
                <div class="dependence">
                  <img src="@/assets/yl.jpg" alt />
                </div>
                <div class="correlation">
                  大学生如何找兼职？
                  <div style="color: #aaa; margin-top: 0.2rem">周建芳</div>
                  <div style="color: #aaa; margin-top: 0.8rem">
                    <i class="el-icon-user">2381人参加</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="righter">
            <!-- 课程介绍和介绍视频 -->
            <div style="display: inline-block">
              <!-- 课程介绍 -->
              <div class="wenzi">
                <h2 id="1">课程介绍</h2>
                <span
                  >本课程为心理学基础导论课，以心理学的经典理论和最新实证研究为蓝本，以积极心理学的角度，介绍科学心理学的基本理论、基本方法、研究领域和研究进展。在人们物质生活飞速发展的今天，关注心理学显得尤为重要。在美国大学，心理学是学生人数最多而且最受欢迎的学科之一。</span
                >
              </div>
              <!-- 介绍视屏 -->
              <div class="ship">
                <video
                  class="video"
                  controls
                  style="height: 20rem; width: 100%"
                >
                  <source src="@/assets/1.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
            <!-- 开课时间   教学时长  学习投入 -->
            <div class="shi">
              <div class="rili">
                <img src="@/assets/rili.png" alt />
                <div class="gong">开课时间：</div>
                <div class="gong">2020.01.29</div>
              </div>
              <div class="shijian">
                <img src="@/assets/shijian.png" alt />
                <div class="gong">教学时长：</div>
                <div class="gong">365分钟</div>
              </div>
              <div class="tushu">
                <img src="@/assets/tushu.png" alt />
                <div class="gong">学习投入：</div>
                <div class="gong">未结</div>
              </div>
            </div>
            <!-- 开课机构  学校logo -->
            <div class="kai">
              <h2 class="gb" id="2">开课机构</h2>
              <div class="imc">
                <img src="@/assets/njlogo.png" alt />
              </div>
            </div>
            <!-- 授课老师介绍 -->
            <div class="inf">
              <h2 class="grf" id="3">授课老师</h2>
              <div class="tu">
                <img src="@/assets/jiaoshi.jpg" alt />
              </div>
              <span>候候</span>
              <div class="jibie">
                <span>家里蹲系 研究生</span>
              </div>
              <div class="jiea">
                <span
                  >候候研究生，毕业于家里蹲大学家里蹲系，因成绩优异，被学校列入重点培养名册，在校期间曾参加过家里好声音拿到了季军的成绩，以迷人的歌喉争夺了很多学弟学妹们的心，后因毕业被学校列为最有成就的研究生。</span
                >
              </div>
            </div>
            <!-- 课程章节 -->
            <div class="zhang">
              <h2 class="zf" id="4">课程章节</h2>
              <el-collapse v-model="activeNames" @change="handleChange">
                <el-collapse-item title="一致性 Consistency" name="1">
                  <div>
                    与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；
                  </div>
                  <div>
                    在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。
                  </div>
                </el-collapse-item>
                <el-collapse-item title="反馈 Feedback" name="2">
                  <div>
                    控制反馈：通过界面样式和交互动效让用户可以清晰的感知自己的操作；
                  </div>
                  <div>
                    页面反馈：操作后，通过页面元素的变化清晰地展现当前状态。
                  </div>
                </el-collapse-item>
                <el-collapse-item title="效率 Efficiency" name="3">
                  <div>简化流程：设计简洁直观的操作流程；</div>
                  <div>
                    清晰明确：语言表达清晰且表意明确，让用户快速理解进而作出决策；
                  </div>
                  <div>
                    帮助用户识别：界面简单直白，让用户快速识别而非回忆，减少用户记忆负担。
                  </div>
                </el-collapse-item>
                <el-collapse-item title="可控 Controllability" name="4">
                  <div>
                    用户决策：根据场景可给予用户操作建议或安全提示，但不能代替用户进行决策；
                  </div>
                  <div>
                    结果可控：用户可以自由的进行操作，包括撤销、回退和终止当前操作等。
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
            <!-- 课程服务 -->
            <div>
              <h2 class="zf" id="5">课程服务</h2>
              <div class="youhui">
                <div class="mianfei">
                  <h3>免费学习</h3>
                  <div class="citiao">
                    <span>免费</span>
                  </div>
                  <div class="rq">
                    <i class="el-icon-date"></i>
                    <span>2020-01-19 至 2020-07-31</span>
                  </div>
                  <div class="xisc">
                    <i class="el-icon-tickets"></i>
                    <span>60天回顾学习时长</span>
                  </div>
                  <div class="xiaojie">
                    <i class="el-icon-time"></i>
                    <span>70小节</span>
                  </div>
                  <div class="anniu">
                    <el-button type="warning" plain class="ming"
                      >免 费 加 入 学 习</el-button
                    >
                  </div>
                </div>
                <!-- 认证学习 -->
                <div class="ren">
                  <h3>认证学习</h3>
                  <div class="citiao">
                    <span>免费</span>
                  </div>
                  <div class="rq">
                    <i class="el-icon-date"></i>
                    <span>2020-01-19 至 2020-07-31</span>
                  </div>
                  <div class="xisc">
                    <i class="el-icon-tickets"></i>
                    <span>60天回顾学习时长</span>
                  </div>
                  <div class="xiaojie1">
                    <i class="el-icon-time"></i>
                    <span>70小节</span>
                  </div>
                  <div class="afshq">
                    <div class="kecha">
                      <i class="el-icon-star-on"></i>
                      <span class="yang">课程视频和学习记录永久可看可查</span>
                    </div>
                    <div class="jiaol">
                      <i class="el-icon-star-on"></i>
                      <span class="yang">作业可批改与讨论交流</span>
                    </div>
                    <div class="zshu">
                      <i class="el-icon-star-on"></i>
                      <span class="yang">颁发权威防伪认证证书</span>
                    </div>
                  </div>
                  <div class="anniu">
                    <el-button type="warning" class="ming"
                      >立 即 加 入 学 习</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- 课程服务介绍 -->
            <div class="kjie">
              <h2 class="fu" id="6">权威认证证书</h2>
              <div>
                <img src="@/assets/jshtu.png" alt class="mimg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Commonbottom></Commonbottom>
      <router-view></router-view>
    </div>
  </div>
</template>
<style  scoped>
a {
  color: black;
}
a,
a:hover,
a:active,
a:visited,
a:link,
a:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  outline: none;
  background: none;
  text-decoration: none;
}

.combined {
  width: 100%;
  position: relative;
  background-color: #f2f2f2;
}
.jion {
  padding: 0.8rem 5rem;
  border-radius: 0.8rem;
  background-color: #ff9552;
  color: #fff;
  position: absolute;
  left: 23%;
  top: 55%;
  font-size: 14px;
}
.Psychology {
  font-size: 1.6rem;
  letter-spacing: 0.1rem;
  position: absolute;
  left: 63%;
  top: 24%;
}
.abnormal {
  position: absolute;
  left: 63%;
  top: 44%;
}
.general {
  font-size: 0.8rem;
  position: absolute;
  left: 63%;
  top: 56%;
  font-weight: bold;
}
.Cambridge {
  font-size: 0.8rem;
  position: absolute;
  left: 63%;
  top: 66%;
  font-weight: bold;
}
.column {
  background-color: #fff;
  padding: 1.5rem 3rem;
  width: 65%;
  display: inline-block;
  border-radius: 1rem;
}
.column ul {
  list-style: none;
}
.column ul li {
  padding: 1.2rem;
  border-top: 0.07rem solid #999;
  font-weight: 550;
  letter-spacing: 0.1rem;
  font-size: 15px;
}
.relevant {
  background-color: #fff;
  padding: 1.5rem 2rem;
  width: 77%;
  display: inline-block;
  border-radius: 1rem;
  margin-top: 1rem;
}
.relevant h2 {
  padding: 0 1rem 1rem 1rem;
  font-size: 1.2rem;
  font-weight: 550;
}
.dependence {
  width: 42%;
  display: inline-block;
  float: left;
}
.dependence img {
  width: 100%;
}
.correlation {
  font-size: 14px;
  margin-left: 6.9rem;
  text-align: left;
}
.righter {
  background-color: #fff;
  padding: 1%;
  width: 70%;
  display: inline-block;
  margin-left: 1%;
  float: right;
}
.wenzi {
  width: 31%;
  display: inline-block;
  float: left;
  margin-left: 1rem;
  margin-top: 3.5rem;
}
.wenzi span {
  font-size: 1rem;
  font-weight: 600;
}
.ship {
  width: 56%;
  display: inline-block;
  margin-left: 6.5rem;
}
.shi {
  background-color: #f1f9fe;
}
.rili {
  display: inline-block;
  padding: 3rem;
}
.shijian {
  display: inline-block;
  padding: 3rem;
}
.tushu {
  display: inline-block;
  padding: 3rem;
}
.gong {
  color: #3487fa;
}
.kai {
  background-color: #fff;
}
.gb {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.imc {
  padding-bottom: 1rem;
}
.tu img {
  width: 13%;
  border-radius: 50%;
}
.inf {
  background-color: #efefef;
}
.inf span {
  font-size: 1.1rem;
  font-weight: 600;
}
.grf {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.jibie {
  border-bottom: 0.1rem solid #e7e7e7;
  width: 70%;
  margin: 0 auto;
}
.jibie span {
  font-size: 0.4rem;
  font-weight: 300;
}
.jiea {
  width: 60%;
  margin: 0 auto;
  text-align: left;
  padding-bottom: 2.2rem;
}
.jiea span {
  font-size: 0.3rem;
}
.zhang {
  background-color: #fff;
}
.zf {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.youhui {
  width: 80%;
  margin: 0 auto;
}
.mianfei {
  width: 40%;
  background: #ffff;
  box-shadow: #efefef 0px 0px 10px;
  border-radius: 2rem;
  display: inline-block;
  float: left;
}
.mianfei h3 {
  font-weight: 600;
  text-align: left;
  margin-left: 3.5rem;
  padding-top: 1rem;
}
.ren h3 {
  font-weight: 600;
  text-align: left;
  margin-left: 3.5rem;
  padding-top: 1rem;
}
.mianfei span {
  text-align: right;
}
.rq {
  color: #9d9e9b;
  margin: 1rem 0rem;
}
.xisc {
  text-align: left;
  margin-left: 3.4rem;
  margin-bottom: 1.2rem;
}
.xiaojie {
  text-align: left;
  margin-left: 3.4rem;
  padding-bottom: 5rem;
}
.ming {
  width: 65%;
}
.citiao {
  text-align: right;
  margin-right: 1rem;
}
.anniu {
  padding-bottom: 1.2rem;
}
.xiaojie1 {
  text-align: left;
  margin-left: 3.4rem;
  padding-bottom: 0.5rem;
}
.ren {
  width: 40%;
  background: #ffff;
  box-shadow: #efefef 0px 0px 10px;
  border-radius: 2rem;
  display: inline-block;
}
.yang {
  font-size: 0.5rem;
  color: #697cb1;
}
.el-icon-star-on {
  color: #697cb1;
}
.afshq {
  text-align: left;
  margin-left: 3.5rem;
}
.kecha {
  margin-bottom: 0.5rem;
}
.jiaol {
  margin-bottom: 0.5rem;
}
.zshu {
  margin-bottom: 0.5rem;
}
.kjie {
  margin-top: 2rem;
}
.fu {
  margin-bottom: 0.5rem;
}
/* 300px~767px分辨率 （手机端） */
@media screen and (min-width: 300px) and (max-width: 767px) {
  .jion {
    padding: 0.1rem 2rem !important;
    border-radius: 0.8rem !important;
    left: 8.5% !important;
    top: 52% !important;
  }
  .Psychology {
    font-size: 0.6rem !important;
  }
  .ainf {
    width: 14% !important;
    left: 18% !important;
  }
  .ks {
    height: 10rem !important;
  }
  .abnormal {
    /* position: absolute; */
    left: 61% !important;
    top: 37% !important;
  }
  .general {
    left: 61% !important;
    top: 63% !important;
  }
  .Cambridge {
    left: 68% !important;
    top: 83% !important;
  }
  .cent {
    width: 100% !important;
  }
  .column {
    padding: 1rem 0.5rem !important;
    width: 100% !important;
  }
  .correlation[data-v-4df49d0e] {
    margin-left: 0rem !important;
    float: none !important;
    text-align: center !important;
  }
  .mimg {
    width: 100% !important;
  }
  .relevant {
    padding: 0rem !important;
    width: 122% !important;
  }
  .dependence {
    width: 95% !important;
    margin: 0 0.1rem !important;
  }
  .wenzi {
    width: 90% !important;
  }
  .ship {
    width: 98% !important;
    margin-left: 0rem !important;
  }
  .ob {
    float: left !important;
  }
  .column ul li {
    padding: 0.8rem !important;
  }
  .mianfei {
    width: 100% !important;
    float: none !important;
  }
  .ren {
    width: 100% !important;
    margin-top: 1.2rem !important;
  }
  .xisc {
    text-align: left;
    margin-left: 1.4rem;
    margin-bottom: 1.2rem;
  }
  .xiaojie1 {
    text-align: left;
    margin-left: 1.4rem;
    padding-bottom: 0.5rem;
  }
  .afshq {
    text-align: left;
    margin-left: 0.5rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .ob {
    width: 82% !important;
  }
  .mimg {
    width: 100% !important;
  }
  .column {
    padding: 0.5rem 1rem !important;
    width: 100% !important;
  }
  .righter {
    padding: 0% !important;
    width: 100% !important;
  }
  .cent {
    width: 100% !important;
  }
  .ship {
    margin-left: 0rem !important;
  }
  .wenzi {
    margin-left: 0rem !important;
  }
  .relevant {
    width: 95% !important;
    margin-bottom: 0.5rem !important;
  }
  .dependence {
    width: 20% !important;
  }
  .ren {
    width: 46% !important;
  }
  .jion {
    left: 20% !important;
    top: 59% !important;
  }
  .general {
    top: 73% !important;
  }
  .Cambridge {
    top: 84% !important;
  }
}
</style>
<script>
import withoutBanner from "@/components/withoutBanner";
import Commonbottom from "@/components/Commonbottom";
export default {
  components: { withoutBanner, Commonbottom },
  computed: {},
  data() {
    return {
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: 5,
      activeNames: [""],
    };
  },
  methods: {
    handleChange(val) {
    },
  },
};
</script>