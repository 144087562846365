<template>
  <!---导航头-->
  <div class="indexCenters">
    <div class="titler">
      <h2 style="margin-bottom:10%;">
        <!-- <span>栏目列表</span> -->
      </h2>
      <ul style="background-color:#fff;border: 0.8rem solid #82a295;border-radius: 8%;">
        <div class="similarities">
          <p class="hoster">诚招资讯</p>
        </div>
        <li>
          <router-link class="nowers" to="/">
            <i class="el-icon-caret-right"></i>
            返回首页
          </router-link>
        </li>
        <li>
          <router-link class="nowers" to="/Hotnews">
            <i class="el-icon-caret-right"></i>
            热点新闻
          </router-link>
        </li>
        <li>
          <router-link class="nowers" to="/Sincereconsultation">
            <i class="el-icon-caret-right"></i>
            诚招资讯
          </router-link>
        </li>
        <!-- /Hotnews -->
        <li>
          <router-link class="nowers" to="/Policies">
            <i class="el-icon-caret-right"></i>
            政策法规
          </router-link>
        </li>
        <li>
          <router-link class="nowers" to="/Noticeannouncement">
            <i class="el-icon-caret-right"></i>
            通知公告
          </router-link>
        </li>
        <li>
          <router-link class="nowers" to="/Academicexchange">
            <i class="el-icon-caret-right"></i>
            学术交流
          </router-link>
        </li>
        <li>
          <router-link class="nowers" to="/Recruit">
            <i class="el-icon-caret-right"></i>
            招生专栏
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {}
};
</script>
<style scoped>
.header {
  background-color: #006834;
  padding: 0.2rem 0.5rem;
  color: #fff;
  font-size: 0.18rem;
  text-align: left;
}
.loginer {
  text-align: right;
}
.top {
  width: 89%;
  margin: 0 auto;
  margin-top: 2%;
}
.top img {
  width: 100%;
  margin: 0 auto;
  height: 100%;
}
#nav {
  margin: 0 auto;
  padding: 0;
}
#nav ul,
#nav li {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
#nav ul {
  display: flex;
  flex-direction: row;
  background-color: #006834;
}
#nav li {
  color: #fff;
  font-size: 0.15rem;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 12px;
  flex: auto; /*这是关键*/
  cursor: pointer;
}
#nav li a {
  color: #fff;
  text-decoration: none;
}
#nav a.router-link-exact-active {
  color: #fff;
  text-decoration: none;
}
.similarities {
  border-bottom: 3px solid #72abab;
  text-align: left;
  padding-top: 1rem;
}
.hoster {
  font-size: 0.6rem;
  /* text-align: left; */
  /* float: left; */
  margin-left: 3%;
  background-color: #006005;
  display: inline-block;
  padding: 0.6rem;
  /* background-image: url(../assets/soubiao.png);
  background-repeat: no-repeat; */
  color: #fff;
  border-top-right-radius: 0.4rem;
  border-top-left-radius: 0.4rem;
  /* background-size:auto */
}
@media only screen and (max-width: 540px) {
  .block {
    width: 100% !important;
  }
  #nav li {
    font-size: 0.1rem !important;
  }
  .indexCenter {
    width: 100% !important;
    margin-bottom: 3% !important;
  }
  .indexCenters {
    width: 100% !important;
  }
  .titler h2 span {
    padding-left: 0.6rem !important;
  }
  .footer {
    font-size: 0.1rem;
  }
  .el-pagination {
    white-space: normal !important;
  }
}
/* @media only screen and (max-width: 2000px) {
  .top{
    width: 70% !important;
  }
} */
.titler {
  /* padding-top: 4rem; */
  padding: 4rem 2rem 2rem 2rem;
}
.titler h2 {
  border-bottom: 1px solid #006834;
  color: #505050;
  font-size: 0.1rem;
  line-height: 39px;
  text-align: left;
  background: url(../assets/titbor.png) no-repeat 0px 37px;
  padding-left: 0.1rem;
}
.titler ul {
  list-style: none;
}
.titler h2 span {
  /* color: #505050; */
  font-size: 0.1rem;
  /* background: url(../assets/icontit.png) no-repeat 0px 9px; */
  text-align: left;
  padding-left: 0.3rem;
  display: inline-block;
}
.titler .more {
  text-decoration: none;
  font-size: 0.1rem;
  color: #006834;
}
.titler ul li {
  font-size: 0.12rem;
}
.titler li {
  /* background: url(../assets/l_li.jpg) no-repeat; */
  /* background-color: #eee; */
  border-radius: 30px;
}
.titler li a {
  border-bottom: 2px solid #eee;
}
.titler .dater {
  float: right;
  color: #909090;
}
.indexCenters {
  width: 23%;
  display: inline-block;
  /* float: left; */
  background-color: #e5e6d3;
  box-shadow: 2px 2px 2px 2px #c3c6a5;
  border-radius: 0.3rem;
  margin-top: 2%;
}
.indexCenters ul {
  list-style: none;
}
.indexCenters ul li {
  padding: 0.8rem;
}
.indexCenters ul li .nowers {
  font-size: 0.9rem;
  text-align: center;
  text-decoration: none;
  color: #666;
  font-weight: bold;
}
.indexCenters ul li .nowers:click {
  font-size: 1rem;
  background-color: #fffdcc;
}
.footer {
  margin-top: 1%;
  font-size: 0.15rem;
}
.bottomlookcount {
  border-top: 1px solid #016834;
}
</style>
