<template>
  <div>
    <!-- 引入头部 -->
    <div>
      <ClassHead></ClassHead>
      <router-view></router-view>
    </div>
    <!-- 导航部分 -->
    <div class="dalunbo">
      <!-- 课程导航部分 -->
      <div class="liebiao">
        <div class="nue">
          <div class="wne">
            <span>首页</span>
          </div>
          <router-link to="/Alliance/supermarket">
            <div class="wne">
              <span>课程超市</span>
            </div>
          </router-link>
          <router-link to="/Alliance/school">
            <div class="wne">
              <span>学校</span>
            </div>
          </router-link>
          <router-link to="/Alliance/SchoolCloud">
            <div class="wne">
              <span>学校云</span>
            </div>
          </router-link>
          <div class="wne">
            <span>公共服务平台</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 展示背景图、个人头像 -->
    <div class="contas">
      <img src="@/assets/grn.png" alt style="width:100%;height: 22rem;" />
      <div>
        <img
          src="@/assets/qqq.jpeg"
          alt
          style="position: absolute;left: 49%;top: 5%;"
          class="touxiang"
        />
        <div class="mingcheng">昵称123</div>
        <div class="ida">ID:11223344</div>
        <div class="an">
          <el-button type="danger" round>去认证</el-button>
        </div>
        <div class="zz">
          <div>2</div>
          <div>正在学习课程</div>
        </div>
        <div class="lj">
          <div>2</div>
          <div>累计学习课程</div>
        </div>
        <div class="fb">
          <div>0</div>
          <div>发布的讨论</div>
        </div>
      </div>
    </div>

    <!-- 课程内容 -->
    <div class="mas">
      <div class="minzdf" style="padding:1rem;width:75%;margin:0 auto;display: inline-block;">
        <!-- 左侧展示 -->
        <div class="kcleft">
          <ul>
            <li>
              <i class="el-icon-collection" style="color:skyblue;margin-right:0.3rem"></i>我的课程
            </li>
            <li>
              <i class="el-icon-office-building" style="color:skyblue;margin-right:0.3rem"></i>我的直播
            </li>
            <li>
              <i class="el-icon-document-copy" style="color:skyblue;margin-right:0.3rem"></i>我的讨论
            </li>
            <li>
              <i class="el-icon-pie-chart" style="color:skyblue;margin-right:0.3rem"></i>购买记录
            </li>
            <router-link to="/Alliance/coupon">
              <li>
                <i class="el-icon-paperclip" style="color:skyblue;margin-right:0.3rem"></i>优惠券
              </li>
            </router-link>
            <li>
              <i class="el-icon-collection" style="color:skyblue;margin-right:0.3rem"></i>我的证书
            </li>
            <li class="tubi">
              <i class="el-icon-collection" style="color:skyblue;margin-right:0.3rem"></i>信息设置
            </li>
          </ul>
        </div>
        <!-- 右侧展示 -->
        <div class="xqright">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <!-- 基本信息 -->
            <el-tab-pane label="基本信息" name="first">
              <div class="dahe">
                <!-- 昵称 -->
                <div class="nicheng">
                  <div class="xing">*</div>昵称：
                  <el-input v-model="input" placeholder="请输入昵称"></el-input>
                </div>
                <!-- 昵称 结束 -->
                <!-- 姓名 -->
                <div class="xingming">
                  <div class="xing">*</div>姓名：
                  <el-input v-model="input" placeholder="请输入姓名"></el-input>
                </div>
                <!-- 姓名 结束 -->
                <!-- 性别 -->
                <div class="xingbie">
                  <div class="xing">*</div>性别：
                  <el-radio-group v-model="radio">
                    <el-radio :label="3">男</el-radio>
                    <el-radio :label="6">女</el-radio>
                    <el-radio :label="9">不公开</el-radio>
                  </el-radio-group>
                </div>
                <!-- 性别 结束 -->
                <!-- 邮箱 -->
                <div class="youxiang">
                  <div class="xing1">邮箱：</div>
                  <div class="aq">
                    <div class="el" v-if="min">836088274@qq.com</div>
                    <div class="shuru" v-if="isShow">
                      <el-input v-model="input" placeholder="请输入邮箱"></el-input>
                    </div>
                    <el-button type="text" @click="rng()">确认修改</el-button>
                  </div>
                </div>
                <!-- 邮箱 结束 -->
                <!-- 手机 -->
                <div class="shouji">
                  <div class="xing1">手机：</div>
                  <div class="aq">
                    <div class="el">未设置</div>
                    <el-button type="text" @click="rng()">绑定手机</el-button>
                  </div>
                  <div class="shuru">
                    <el-input v-model="input" v-text="message" placeholder="请输入邮箱"></el-input>
                  </div>
                </div>
                <!-- 手机 结束 -->
                <!-- 生日 -->
                <div class="shengri">
                  <div class="xing">*</div>生日：
                  <el-date-picker v-model="value1" type="date" placeholder="选择日期"></el-date-picker>
                </div>
                <!-- 生日   结束 -->
                <!-- 地区 -->
                <div class="diqu">
                  <div class="xing">*</div>地区：
                  <el-select v-model="value5" placeholder="请选择">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
                <!-- 地区 结束 -->
                <!-- 兴趣 -->
                <div class="xingqu">
                  <div class="xing">*</div>兴趣：
                  <div class="fuxuan">
                    <el-checkbox-group v-model="checkList">
                      <el-checkbox label="运动"></el-checkbox>
                      <el-checkbox label="音乐"></el-checkbox>
                      <el-checkbox label="阅读"></el-checkbox>
                      <el-checkbox label="游戏"></el-checkbox>
                      <el-checkbox label="电影"></el-checkbox>
                      <el-checkbox label="其他"></el-checkbox>
                    </el-checkbox-group>
                  </div>
                </div>
                <!-- 兴趣  结束 -->
              </div>
            </el-tab-pane>
            <!-- 身份验证 -->
            <el-tab-pane label="身份验证" name="second">
              <el-tabs v-model="activeName1" @tab-click="handleClick">
                <!-- 学生 -->
                <el-tab-pane label="学生" name="n1">
                  <!-- 姓名 -->
                  <div class="xingming1">
                    <div class="xing">*</div>姓名：
                    <el-input v-model="input" placeholder="请输入您的真实姓名"></el-input>
                  </div>
                  <!-- 姓名 结束 -->
                  <!-- 机构 -->
                  <div class="xingming1">
                    <div class="xing">*</div>机构：
                    <el-input v-model="input" placeholder="请选择您所在学校或机构"></el-input>
                  </div>
                  <!-- 机构 结束 -->
                  <!-- 学号 -->
                  <div class="xingming1">
                    <div class="xing">*</div>学号：
                    <el-input v-model="input" placeholder="请输入学号"></el-input>
                  </div>
                  <!-- 学号  结束 -->
                  <!-- 智能验证 -->
                  <div class="xingming1">
                    <div class="xing">*</div>智能验证：
                    <div style=" display: inline-block;">
                      <drag-verify
                        :width="width"
                        :height="height"
                        :text="text"
                        :success-text="successText"
                        :background="background"
                        :progress-bar-bg="progressBarBg"
                        :completed-bg="completedBg"
                        :handler-bg="handlerBg"
                        :handler-icon="handlerIcon"
                        :text-size="textSize"
                        :success-icon="successIcon"
                        ref="Verify"
                      ></drag-verify>
                    </div>
                  </div>
                  <!-- 智能验证  结束 -->
                  <!-- 手机号 -->
                  <div class="xingming1">
                    <div class="xing">*</div>手机号：
                    <el-input v-model="input" placeholder="请输入手机号"></el-input>
                    <div class="din">
                      <span class="yanz" v-show="show" @click="getCode">获取验证码</span>
                      <span class="count" v-show="!show">{{count}} s</span>
                    </div>
                  </div>
                  <!-- 手机号 结束 -->
                  <!-- 验证码 -->
                  <div class="xingming1">
                    <div class="xing">*</div>验证码：
                    <el-input v-model="input" placeholder="请输入验证码"></el-input>
                  </div>
                  <!-- 验证码 结束 -->
                  <div class="xingming2">
                    <el-button type="success">验证</el-button>
                  </div>
                </el-tab-pane>
                <!-- 学生 结束 -->
                <el-tab-pane label="老师" name="n2">老师</el-tab-pane>
              </el-tabs>
            </el-tab-pane>
            <!-- 修改密码 -->
            <el-tab-pane label="修改密码" name="third">
              <div class="dahe">
                <!-- 旧密码 -->
                <div class="xingming1">
                  <div class="xing4">*</div>旧密码：
                  <el-input v-model="input" placeholder="请输入旧密码"></el-input>
                </div>
                <div class="xingming1">
                  <el-form
                    :model="ruleForm"
                    status-icon
                    :rules="rules"
                    ref="ruleForm"
                    label-width="100px"
                    class="demo-ruleForm"
                  >
                    <el-form-item label="密码" prop="pass">
                      <el-input type="password" v-model="ruleForm.pass" placeholder="请输入新密码" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="checkPass">
                      <el-input type="password" v-model="ruleForm.checkPass" placeholder="请确认密码" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
                      <el-button @click="resetForm('ruleForm')">重置</el-button>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </el-tab-pane>
            <!-- 修改密码  结束 -->
            <!-- 社交账号 -->
            <el-tab-pane label="社交账号" name="fourth">
              <div class="biao">
                绑定第三方账号，可以直接登录
              </div>
            </el-tab-pane>
            <!-- 社交账号 结束 -->
          </el-tabs>
        </div>
      </div>
    </div>
    <div>
      <Commonbottom></Commonbottom>
      <router-view></router-view>
    </div>
  </div>
</template>
<style  scoped>
a {
  color: black;
}
a,
a:hover,
a:active,
a:visited,
a:link,
a:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  outline: none;
  background: none;
  text-decoration: none;
}
.contas {
  position: relative;
}
.liebiao {
  background-color: skyblue;
  width: 100%;
}
.nue {
  margin: 0 auto;
  padding: 0.8rem;
}
.wne {
  display: inline-block;
  margin-right: 8rem;
  font-size: 0.9rem;
  color: #fff;
  font-size: 1rem;
  text-align: left;
  cursor: pointer;
}
.touxiang {
  width: 6%;
  border-radius: 5rem;
}
.mingcheng {
  position: absolute;
  top: 40%;
  left: 50.5%;
  color: #fff;
}
.ida {
  position: absolute;
  top: 49.5%;
  left: 49.5%;
  color: #fff;
}
.an {
  position: absolute;
  top: 60%;
  left: 49.6%;
}
.zz {
  position: absolute;
  top: 82%;
  left: 34%;
  color: #fff;
}
.lj {
  position: absolute;
  top: 82%;
  left: 49.5%;
  color: #fff;
}
.fb {
  position: absolute;
  top: 82%;
  left: 66%;
  color: #fff;
}
.mas {
  background-color: #efefef;
}
.kcleft {
  background-color: #fff;
  padding: 1.5rem 3rem;
  width: 15%;
  display: inline-block;
  border-radius: 1rem;
  float: left;
}
.kcleft ul {
  list-style: none;
}
.kcleft ul li {
  padding: 1.2rem;
  border-top: 0.07rem solid #999;
  font-weight: 550;
  letter-spacing: 0.1rem;
  font-size: 15px;
}
.tubi {
  padding: 1.2rem;
  border-top: 0.07rem solid #999;
  font-weight: 550;
  letter-spacing: 0.1rem;
  font-size: 15px;
  border-bottom: 0.07rem solid #999;
}
.xqright {
  width: 72%;
  display: inline-block;
  background-color: #fff;
  border-radius: 1rem;
  padding: 2.5rem 2rem;
}
.vouchers {
  display: inline-block;
  margin-bottom: 0.5rem;
  width: 100%;
}
.glorious {
  width: 28%;
  display: inline-block;
  margin-top: 1.5rem;
  border: 0.1rem solid;
  border-radius: 12%;
  text-align: left;
  margin-right: 1rem;
  float: left;
  margin-left: 1.5rem;
}
.glorious img {
  width: 100%;
  border-top-left-radius: 19%;
  border-top-right-radius: 19%;
}
.om {
  font-size: 1rem;
  font-weight: 900;
  margin-left: 1.5rem;
}
.ming {
  margin-left: 1.5rem;
  font-size: 1rem;
  font-weight: 500;
}
.ren {
  margin-left: 1.4rem;
  display: inline-block;
}
.jin {
  margin-left: 0.8rem;
  display: inline-block;
}
.fge {
  margin-bottom: 0.8rem;
}
.cs {
  width: 100%;
  background: #f09;
  opacity: 0;
  display: block;
  font-size: 12px;
  transition: 0.3s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
}
.glorious:hover .cs {
  color: #656e73;
  opacity: 1;
}
.tb {
  text-align: left;
  margin-left: 3.2rem;
  width: 100%;
  margin-top: 0.5rem;
}
.yh {
  display: inline-block;
  padding: 0.1rem;
  border-bottom: 0.1rem orange solid;
}
.di {
  border-bottom: 0.1rem solid;
  width: 70%;
}
.coupon {
  background-image: url("../../assets/zu6.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1.1rem;
  font-size: 1.5rem;
  color: #e55044;
  text-align: left;
  margin-top: 1.2rem;
  width: 27%;
  display: inline-block;
}
.cxk {
  padding: 1.5rem;
  width: 92%;
  border-bottom: 0.1rem solid;
  margin-left: 1rem;
}
.xiabu {
  text-align: left;
  margin-left: 3.2rem;
  margin-bottom: 1.2rem;
  margin-top: 1.2rem;
}
.shiyong {
  font-weight: 700;
}
.zitid {
  font-size: 0.2rem;
  color: #999;
}
.xing {
  display: inline-block;
  color: red;
  margin-right: 0.3rem;
}
.xing1 {
  display: inline-block;
  margin-left: 0.6rem;
}
.xing4 {
  display: inline-block;
  color: red;
  margin-right: 0.3rem;
  margin-left: 1.5rem;
}
.el-input {
  width: 53%;
}
.dahe {
  text-align: left;
}
.nicheng {
  /* text-align: left; */
  margin-bottom: 0.8rem;
}
.xingming {
  /* text-align: left; */
  margin-bottom: 0.8rem;
}
.xingming1 {
  text-align: left;
  margin-bottom: 0.8rem;
}
.xingming2 {
  text-align: left;
  margin-bottom: 0.8rem;
  margin-left: 5.5rem;
}
.xingbie {
  margin-bottom: 0.8rem;
}
.shuru {
  display: inline-block;
  width: 90%;
}
.aq {
  display: inline-block;
}
.el {
  display: inline-block;
  margin-right: 0.5rem;
}
.shouji {
  margin-bottom: 0.8rem;
}
.shengri {
  margin-bottom: 0.8rem;
}
.diqu {
  margin-bottom: 0.8rem;
}
.xingqu {
  margin-bottom: 0.8rem;
}
.fuxuan {
  display: inline-block;
}
.yanz {
  border: 0.1rem solid;
  padding: 0.5rem;
}
.din {
  display: inline-block;
}
.count {
  border: 0.1rem solid;
  padding: 0.5rem;
}
.biao {
  text-align: left;
}
/* 手机端自适应  300px-767px格式的 开始 */
@media only screen and (max-width: 1100px) {
  .wne {
    margin-right: 1rem !important;
  }
  .touxiang {
    width: 22% !important;
    left: 40% !important;
  }
  .mingcheng {
    top: 31% !important;
    left: 44% !important;
  }
  .ida {
    top: 39.5% !important;
    left: 40.5% !important;
  }
  .an {
    top: 53% !important;
    left: 41.6% !important;
  }
  .zz {
    top: 79% !important;
    left: 15% !important;
  }
  .lj {
    top: 79% !important;
    left: 41.5% !important;
  }
  .fb {
    left: 72% !important;
  }
  .minzdf {
    width: 100% !important;
    padding: 0rem !important;
  }
  .kcleft {
    padding: 0rem !important;
    width: 95% !important;
    float: none !important;
    margin-bottom: 1.2rem !important;
    margin-top: 1.2rem !important;
  }
  .xqright {
    width: 95% !important;
    margin-bottom: 1.5rem !important;
    padding: 0rem;
  }
  .glorious {
    width: 95% !important;
    margin-left: 0.5rem !important;
  }
  .jin {
    margin-left: 5.8rem !important;
  }
  .cxk {
    padding: 0rem !important;
    width: 100% !important;
    border-bottom: 0.1rem solid !important;
    margin-left: 0rem !important;
    padding-bottom: 0.5rem !important;
  }
  .wne {
    margin-right: 4rem !important;
  }

  .fb {
    top: 79% !important;
    left: 70% !important;
  }
  .minzdf {
    width: 100% !important;
  }
  q .kcleft {
    padding: 1.5rem 2rem !important;
  }
  .glorious {
    width: 40% !important;
  }
  .coupon {
    width: 69% !important;
  }
  .xiabu {
    margin-left: 0.2rem !important;
  }
  .dahe {
    padding: 0.4rem !important;
  }
}
/* 结束 */
</style>
<script>
import ClassHead from "@/components/ClassHead";
import Commonbottom from "@/components/Commonbottom";
import "font-awesome/css/font-awesome.min.css";
import dragVerify from "vue-drag-verify";
export default {
  components: { ClassHead, Commonbottom, dragVerify },
  computed: {},
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    return {
      value: 5,
      activeName: "first",
      activeName1: "n1",
      input: "",
      radio: 9,
      isShow: false,
      min: true,
      value1: "",
      message: "修改邮箱",
      options: [
        {
          value: "选项1",
          label: "黄金糕"
        },
        {
          value: "选项2",
          label: "双皮奶"
        },
        {
          value: "选项3",
          label: "蚵仔煎"
        },
        {
          value: "选项4",
          label: "龙须面"
        },
        {
          value: "选项5",
          label: "北京烤鸭"
        }
      ],
      value5: "",
      checkList: ["音乐", "阅读", "游戏", "电影"],
      handlerIcon: "fa fa-angle-double-right",
      successIcon: "fa fa-check",
      background: "#cccccc",
      progressBarBg: "#4b0",
      completedBg: "#66cc66",
      handlerBg: "#fff",
      text: "请将滑块拖动到右侧",
      successText: "验证成功",
      width: 320,
      height: 30,
      textSize: "18px",
      isCircle: "true",
      show: true,
      count: "",
      timer: null,
      ruleForm: {
        pass: "",
        checkPass: ""
      },
      rules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }]
      }
    };
  },
  methods: {
    handleClick(tab, event) {
    },
    rng() {
      this.isShow = true;
      this.min = false;
    },
    getCode() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          alert("submit!");
        } else {
          return false;
        }
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>
