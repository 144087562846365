<template>
        <div class="main-left">
          <el-col>
            <el-menu
              :default-active="onRoutes"
              class="el-menu-vertical-demo"
              @open="handleOpen"
              @close="handleClose1"
              background-color="#e5e6d3"
              text-color="#83847e"
              active-text-color="#659b7c"
              router
            >
              <el-menu-item index="news">
                <i class="el-icon-menu"></i>
                <span slot="title">板块管理</span>
              </el-menu-item>
              <el-menu-item index="Articlemanagement">
                <i class="el-icon-setting"></i>
                <span slot="title">文章管理</span>
              </el-menu-item>
            </el-menu>
          </el-col>
        </div>
</template>
<style  scoped>
.big {
  font-size: 0.1rem;
}
.top {
  background-color: #e5e6d3;
  width: calc(100% - 0.4rem);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.2rem;
  box-shadow: 2px 2px 2px 2px #c3c6a5;
  border-radius: 0.3rem;
}
.helper {
  font-size: 0.9rem;
  display: inline-block;
  color: #83847e;
  margin-right: 1rem;
}
.main {
  width: 70%;
  margin: 0 auto;
  margin-top: 2rem;
}
[class*="el-col-"] {
  float: unset;
  box-sizing: border-box;
}
@media screen and (min-width: 300px) and (max-width: 767px) {
  .main {
    width: 90%;
    margin: 0 auto;
    margin-top: 0.2rem;
  }
 .main-left {
    width: 80% !important;
    float: none !important;
    display: inline-block !important;
  }
  .main-right {
    width: 97% !important;
  }
  .el-menu-item {
    font-size: 0.3rem !important;
  }
  .header {
    font-size: 0.3rem !important;
    padding: 0.4rem;
  }
  .dp {
    display: inline-block;
    font-size: 0.9rem;
  }
  .sou {
    width: 100%;
    /* display: inline-block; */
  }
  .neirong {
    width: 100% !important;
    display: inline-block !important;
  }
  .fenye {
    margin-left: 0rem !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px){
  .main-left {
    width: 27% !important;
  }
}
.main-left {
  width: 20%;
  /* display: inline-block; */
  float: left;
  box-shadow: 2px 2px 2px 2px #c3c6a5;
  border-radius: 0.3rem;
}
.main-right {
  width: 70%;
  /* background-color: skyblue; */
  /* padding: 1rem; */
  /* float: right; */
  display: inline-block;
  padding: 0.8rem;
  background-color: #e5e6d3;
  margin-left: 2rem;
  /* background-color:#fff; */
  box-shadow: 2px 2px 2px 2px #c3c6a5;
  border-radius: 0.3rem;
  margin-bottom: 4%;
}
.dp {
  display: inline-block;
  font-size: 0.9rem;
  margin-top: 0.4rem;
}
.ico {
  /* float: right; */
  font-size: 0.9rem;
  text-align: left;
}
.sou {
  width: 73%;
  display: inline-block;
}
.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 25%;
  margin-right: 0.1rem;
}
.nei {
  float: left;
}
.neirong {
  width: 70%;
  display: inline-block;
}
.fenye {
  margin-left: 1.9rem;
}
</style>
<script>
export default {
    computed: {
    onRoutes() {
      return this.$route.path.replace("/", "");
    }
  },
  data() {
    return {
    };
  },
  methods: {
    handleOpen(key, keyPath) {
    },
    handleClose1(key, keyPath) {
    },
  }
};
</script>