<template>
  <div class="big">
    <div>
      <Header></Header>
      <router-view></router-view>
    </div>

    <div class="main">
      <Left></Left>
      <router-view></router-view>
      <div class="mainright">
        <div style="border-color: #ddd;">
          <div class="clearfix">
            <h3 class="ampanel">
              <i class="el-icon-s-fold"></i>
              文章管理模块
              <div class="amtext">
                <button @click="creater()">+ 添加</button>
                <button>x 删除</button>
              </div>
            </h3>
          </div>
          <el-dialog
            :visible.sync="dialogFormVisible"
            center
            :append-to-body="true"
            :lock-scroll="false"
            :width="dialogWidth"
          >
            <!--用户名登录表单-->
            <div>
                <h2 style="padding:0.1rem;text-align:center;font-weight:500;">新增文章</h2>
               <div class="namer">
                文章名称：
                <el-input v-model="input" style="width:54%" placeholder="请输入内容"></el-input>
              </div>
                <div class="namer">
                文章状态：
                <el-select v-model="value" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <div class="namer">
                创建人：
                <el-input v-model="input" style="width:54%" placeholder="请输入内容"></el-input>
              </div>
              <div  class="namer">
                文章信息：
                <ue></ue>
                <router-view></router-view>
              </div>
            </div>
          </el-dialog>
          <div class="amform">
            <h3>
              文章名称：
              <el-input v-model="input" placeholder="请输入内容"></el-input>
              <el-button type="success">查询</el-button>
            </h3>
          </div>
          <div>
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column prop="date" label="文章名称" width="120"></el-table-column>
              <el-table-column prop="name" label="文章状态" width="120"></el-table-column>
              <el-table-column prop="province" label="创建时间" width="120"></el-table-column>
              <el-table-column prop="city" label="创建人" width="120"></el-table-column>
              <el-table-column prop="address" label="发布时间" width="300"></el-table-column>
              <el-table-column prop="zip" label="发布人" width="120"></el-table-column>
              <el-table-column fixed="right" label="操作" width="130">
                <template slot-scope="scope">
                  <el-button @click="handleClick(scope.row)" type="text" size="small">编辑</el-button>
                  <el-button type="text" size="small">查看</el-button>
                  <el-button type="text" size="small">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
             <el-dialog
            :visible.sync="dialogFormVisible"
            center
            :append-to-body="true"
            :lock-scroll="false"
            :width="dialogWidth"
          >
            <!--用户名登录表单-->
            <div>
                <h2 style="padding:0.1rem;text-align:center;font-weight:500;">新增文章</h2>
               <div class="namer">
                文章名称：
                <el-input v-model="input" style="width:54%" placeholder="请输入内容"></el-input>
              </div>
                <div class="namer">
                文章状态：
                <el-select v-model="value" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <div class="namer">
                创建人：
                <el-input v-model="input" style="width:54%" placeholder="请输入内容"></el-input>
              </div>
              <div  class="namer">
                文章信息：
                <!-- <ue></ue> -->
                <!-- <router-view></router-view> -->
              </div>
            </div>
          </el-dialog>
            <div class="block" style="padding:0.1rem;">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage1"
                :page-size="100"
                layout="total, prev, pager, next"
                :total="1000"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style  scoped>
.big {
  font-size: 0.1rem;
}
.namer {
  margin-top: 0.2rem;
  text-align: center;
}
.top {
  background-color: #153c75;
  width: calc(100% - 0.4rem);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.2rem;
}
.helper {
  font-size: 0.15rem;
  display: inline-block;
  color: #fff;
  margin-right: 0.1rem;
}
.main {
  width: 70%;
  margin: 0 auto;
  margin-top: 0.2rem;
}
.mainright {
  width: 70%;
  display: inline-block;
  border: 1px solid transparent;
  border-color: #ddd;
}
.clearfix {
  color: #444;
  background-color: #f5f5f5;
  border-color: #ddd;
  padding: 0.15rem;
}
.ampanel {
  font-size: 0.15rem;
  text-align: left;
}
.amtext {
  display: inline-block;
  float: right;
}
.amtext button {
  padding-left: 0.1rem;
  padding-top: 0.05rem;
  padding-bottom: 0.05rem;
  padding-right: 0.1rem;
  background-color: #77b723;
  border: #77b723;
  color: #fff;
  border-radius: 0.05rem;
  margin-left: 0.1rem;
}
.amform {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  font-size: 0.12rem;
  text-align: left;
}
.el-button--success {
  background-color: #77b723;
  border-color: #77b723;
}
.el-button--text {
  color: #77b723;
}
.el-pagination {
  white-space: normal;
}
[class*="el-col-"] {
  float: unset;
  box-sizing: border-box;
}
@media only screen and (max-width: 540px) {
  .main {
    width: 90%;
    margin: 0 auto;
    margin-top: 0.2rem;
  }
  .main-left {
    width: 100% !important;
    margin-bottom: 0.2rem !important;
  }
  .mainright {
    width: 100% !important;
  }
  .el-input {
    width: 50% !important;
  }
  .dp[data-v-1aa8c6e6] {
    display: inline-block;
    font-size: 0.3rem;
    float: left;
    margin-top: 0.09rem;
  }
  .sou[data-v-1aa8c6e6] {
    width: 100%;
    /* display: inline-block; */
  }
  .neirong[data-v-1aa8c6e6] {
    width: 100% !important;
    display: inline-block !important;
  }
  .fenye {
    margin-left: 0rem !important;
  }
}
.dp {
  display: inline-block;
  font-size: 0.15rem;
  float: left;
  margin-top: 0.04rem;
}
.ico {
  float: right;
}

.sou {
  width: 73%;
  display: inline-block;
}
.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 25%;
  margin-right: 0.1rem;
}
.nei {
  float: left;
}
.neirong {
  width: 70%;
  display: inline-block;
}
.fenye {
  margin-left: 1.9rem;
}
</style>
<script>
import Header from "@/components/BackHead";
import Left from "@/components/BackLeft";
// import ue from "@/components/ue";
export default {
  components: {
    Header,
    Left,
  },
  data() {
    return {
      input: "",
      dialogFormVisible: false,
      dialogFormVisible1: false,
      currentPage1: 4,
      currentPage3: 2,
      dialogWidth: 0,
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: ""
      },
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1518 弄",
          zip: 200333
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1517 弄",
          zip: 200333
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1519 弄",
          zip: 200333
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1516 弄",
          zip: 200333
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1516 弄",
          zip: 200333
        }
      ],
       options: [
        {
          value: "选项1",
          label: "男"
        },
        {
          value: "选项2",
          label: "女"
        }
      ],
      value: ""
    };
  },
  created() {
    this.setDialogWidth();
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.setDialogWidth();
      })();
    };
  },
  methods: {
    //新增
    creater() {
      this.dialogFormVisible = true;
    },
    setDialogWidth() {
      var val = document.body.clientWidth;
      const def = 450; // 默认宽度
      if (val < def) {
        this.dialogWidth = "80%";
      } else {
        this.dialogWidth = def + "px";
      }
    },
    handleOpen(key, keyPath) {
    },
    handleClose1(key, keyPath) {
    },
    onSubmit() {
      this.dialogVisible = false;
    },
    handleClick() {
      this.dialogFormVisible1 = true;
    },
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
    }
  }
};
</script>


